import React from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  name: string
  image: string
  clickTarget: string
}

const FeaturedChallenge = ({ name, image, clickTarget }: Props) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(clickTarget)
  }

  return (
    <div className="w-full border-teal border-solid border-4 rounded-xl shadow-xl  bg-teal  cursor-pointer hover:shadow-coral hover:shadow-lg  mb-2  " onClick={handleClick}>
      <img
        className="w-full
       rounded-md  "
        src={`${image}?w=555&h=312&q=90`}
        alt={name}
      ></img>
    </div>
  )
}

export { FeaturedChallenge }
