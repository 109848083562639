import { MealType, Recipe, RecipeCategory, RecipesFiltersQuery } from 'types'
import { get } from './helpers'
import { MealTypesResponse, RecipeCategoriesResponse, RecipeResponse } from './types'

export const AllCategory: RecipeCategory = {
  id: 'all',
  title: 'All',
  description: 'Welcome to Lean Nutrition, here you will find all the meals you need to fuel yourself the Lean way!',
}

export const getRecipeCategories = async (): Promise<RecipeCategory[]> => {
  const response = await get<RecipeCategoriesResponse>(`/recipes/categories`)
  response.unshift(AllCategory)
  return response
}

export const getRecipes = async (filters?: RecipesFiltersQuery): Promise<Recipe[]> => {
  console.log('getRecipes filters:', filters)
  if (filters && filters.type === AllCategory.id) {
    delete filters.type
  }
  const filtersAsString = filters ? new URLSearchParams(filters as Record<string, string>).toString() : ''
  const response = await get<RecipeResponse>(`/recipes/filters?${filtersAsString}`)
  console.log('getRecipes response:', response)

  return response
}

export const getRecipeById = async (id: string): Promise<Recipe> => {
  const response = await get<Recipe>(`/recipes/${id}`)

  return response
}

export const getRecipeMealTypes = async (): Promise<MealType[]> => {
  const response = await get<MealTypesResponse>(`/recipes/meal-types`)

  return response
}
