import React, { Fragment, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Challenge } from '../../../types/index'
import { ReactComponent as PhilIllustration } from 'assets/images/ondemand-filter-header.svg'
import dayjs from 'shared/functions/dayjs'

type Props = {
  handleStartChallenge: (x: boolean) => void
  isOpen: boolean
  setIsOpen: (x: boolean) => void
  challenge: Challenge
  commitment: number
  setCommitment: (n: number) => void
  withMusic: boolean | null
  setWithMusic: (b: boolean) => void
  isQueueable: boolean
  isReleased: boolean
}

export default function FlexChallengeModal({
  handleStartChallenge,
  isOpen,
  setIsOpen,
  challenge,
  commitment,
  setCommitment,
  withMusic,
  setWithMusic,
  isQueueable,
  isReleased,
}: Props) {
  // const [open, setOpen] = useState(true);
  const [showError, setShowError] = useState(false)

  const validateMusic = () => {
    if (withMusic === null) {
      return false
    }
    return true
  }

  const handleSubmission = (startNow = false) => {
    const musicChoiceSelected = validateMusic()
    if (musicChoiceSelected === false) {
      setShowError(true)
      return
    }
    setIsOpen(false)
    handleStartChallenge(startNow)
  }

  const maxCommitment = challenge.workoutsPerWeek || 5

  const renderOptions = useMemo(() => {
    const labelOptions = []
    const stepValue = 100 / (maxCommitment - 1)
    labelOptions.push(<option key="firstOption" className="" value="0" label="1"></option>)
    for (let i = 2; i < maxCommitment; i++) {
      labelOptions.push(<option key={i+" "+"Option"} className="" data-mark="loop" value={stepValue * (i - 1)} label={i.toString()}></option>)
    }
    labelOptions.push(<option key={maxCommitment+" "+"Option"} className="" value="100" label={maxCommitment.toString()}></option>)
    return labelOptions
  }, [])

  const startNowAdditionalText = dayjs().get('day') === 1 ? 'Next Monday' : 'on Monday'

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-200  " onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  z-99 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-200 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:ml-[300px] relative transform overflow-hidden rounded-lg transition-transform   bg-white px-1 pt-5 pb-4 text-left shadow-xl  sm:my-8 sm:w-full sm:max-w-sm sm:p-4">
                <div className=" text-center ">
                  <h2 className="text-2xl font-extrabold">{challenge.title}</h2>
                  <h4 className="tent-xl font-bold">Select Your Number Of Workouts!</h4>
                  <p>Adjust according to your goals and schedule!</p>

                  <div className="my-4 grid grid-cols-4">
                    <PhilIllustration />
                    <div className="col-span-3">
                      <h3 className="font-bold flex justify-start"> Recommendation: </h3>
                      <p className="text-sm mt-4">
                        {challenge.recommendation
                          ? challenge.recommendation
                          : 'I recommend 2 - 3 workouts per week to get amazing lean results!'}
                      </p>
                    </div>
                  </div>

                  <div className="bg-slate-50 rounded-md p-3 mb-2">
                    <h3 className="text-xl font-bold">Workouts Per Week</h3>

                    <div className="  grid grid-cols-1   ">
                      <input
                        type="range"
                        className="col-span-5 m-0 mb-1 mt-2 w-full h-2 bg-gray-200 rounded-lg  cursor-pointer range-lg dark:bg-gray-700 slider-thumb"
                        min="1"
                        max={maxCommitment}
                        step="1"
                        value={commitment}
                        onChange={(e) => {
                          setCommitment(parseInt(e.target.value))
                        }}
                      />
                    </div>
                    <datalist className="flex  justify-between " id="tickmarks">
                      {renderOptions}
                    </datalist>
                  </div>
                </div>
                <div className="bg-slate-50 rounded-md p-3 mb-2 text-center">
                  <label className=""> Would you like to workout with Music?</label>
                  <div className="grid grid-cols-2 gap-2 my-4 ">
                    <button
                      type="button"
                      className={`h-16 transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2 ${
                        withMusic === true
                          ? 'bg-coral hover:bg-coral-dark hover:ring-slate-500 hover:ring-2 text-slate-50 '
                          : 'bg-slate-100 text-slate-800 border border-slate-500  '
                      }  px-4 py-2   shadow-sm focus:outline-none  focus:ring-white `}
                      onClick={() => {
                        setWithMusic(true)
                      }}
                    >
                      Music
                    </button>
                    <button
                      type="button"
                      className={`h-16 transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2 ${
                        withMusic === false
                          ? 'bg-coral hover:bg-coral-dark hover:ring-slate-500 hover:ring-2 text-slate-50 '
                          : 'bg-slate-100 text-slate-800 border border-slate-500  '
                      }  px-4 py-2   shadow-sm focus:outline-none  focus:ring-white `}
                      onClick={() => {
                        setWithMusic(false)
                      }}
                    >
                      No Music
                    </button>
                  </div>
                  {showError && (
                    <div className={`text-red-500 text-center  mt-0 transition-opacity ${showError ? 'opacity-100' : 'opacity-0'}`}>
                      Please select a Music Option
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className=" h-12 w-full justify-center rounded-2xl transition-all border border-transparent bg-coral px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-coral-dark focus:outline-none focus:ring-2 focus:ring-coral-dark focus:ring-offset-2 sm:text-sm"
                  onClick={async () => {
                    try {
                      handleSubmission()
                    } catch (e) {
                      console.log(e)
                    }
                  }}
                >
                  {`Let's get Started ${startNowAdditionalText}!`}
                </button>
                <div className="mt-1 text-center">
                  {isReleased && (
                    <button
                      className="text-slate-500 hover:text-slate-700"
                      onClick={async () => {
                        try {
                          handleSubmission(true)
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                    >
                      or start challenge now
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
