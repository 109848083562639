import React, { useEffect, useState } from 'react'
import * as AdminAPI from '../../../services/api/admin'
import { UserAchievementForm } from './UserAchievementForm'
import { UserAchievementWithRelatedData } from 'types'
import { toInputDateTimeValue } from '../../../shared/functions/utils'

type Props = {
  userId: string
}

const AdminUserAchievements = ({ userId }: Props) => {
  const [userAchievements, setUserAchievements] = useState<UserAchievementWithRelatedData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await AdminAPI.fetchUserAchievements(userId)
      const formattedData = data.userAchievements.map((ua: UserAchievementWithRelatedData) => ({
        ...ua,
        completedDate: ua.completedDate ?? '',
        startDate: ua.startDate ?? '',
        endDate: ua.endDate ?? '',
      }))
      console.log('formattedData :>> ', formattedData)
      setUserAchievements(formattedData)
    }

    fetchData()
  }, [userId])

  return (
    <div>
      {userAchievements.map((userAchievement, index) => {
        return <UserAchievementForm key={userAchievement.id} userAchievement={userAchievement} index={index} userId={userId} />
      })}
    </div>
  )
}

export { AdminUserAchievements }
