import React from 'react';
import styles from '../SignUp.module.scss';
import { RegistrationForm } from './RegistrationForm';
import FormWrapper from './FormWrapper';
import { useSignUpContext } from '../SignUpContext';

const UserDetails: React.FC<{ name: string }> = ({ name }) => {
  const { goToPrevAccessForm, onPressLogin } = useSignUpContext();

  return (
    <FormWrapper onPressPrev={goToPrevAccessForm} step={2} title="LET'S GET LEAN">
      <p className={styles.accessFormSection__subtitle}>
        Already have an account? <a onClick={onPressLogin}>Log in</a>
      </p>
      <div className={styles.accessFormSection__form}>
        <RegistrationForm />
      </div>
    </FormWrapper>
  );
};

export default UserDetails;
