import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { AuthContainer, Box, Button, LoadingIndicator, SelectOption } from 'shared/components'
import styles from './Questionnaire.module.scss'
import { Challenge, Question, QuestionnaireAnswer } from 'types'
import { StepIndicators } from './StepsIndicator'
import { AuthStateContext, UserChallengeContext } from 'context'
import { ChallengeList } from 'features'
import { ReactComponent as Together } from 'assets/icons/together.svg'
import { ChallengeAPI, QuestionnaireAPI } from 'services/api'
import { dayjs } from 'shared/functions'
import { CreateUserChallengeOptions } from 'services/api/types'

export const Questionnaire: React.FC = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { state: authState } = useContext(AuthStateContext)
  const { state: challengeState } = useContext(UserChallengeContext)
  const questionnaireQuery = useQuery('onboarding', () => QuestionnaireAPI.getQuestionnaireByTitle('onboarding'), {
    enabled: !!authState.user,
  })

  const { data: questionnaireData, status } = questionnaireQuery
  const { questions = [] } = questionnaireData || {}

  const [activeStep, setActiveStep] = useState(0)
  const [selected, setSelected] = useState<number>()
  const [foundAnswer, setFoundAnswer] = useState<QuestionnaireAnswer>()
  const [stepLoading, setStepLoading] = useState(false)

  const [showRecommendations, setShowRecommendations] = useState(false)
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge>()

  const currentStep: Question = questions[activeStep] || {}

  // Check if question has already been answered.
  useEffect(() => {
    const { user } = authState
    if (user) {
      const { preferences } = user
      if (selected === undefined && preferences && currentStep.options) {
        console.log('currentStep :>> ', currentStep)
        const foundAnswerForCurrentQuestion = preferences.find((item) => item.question_id === currentStep.id)
        console.log('hasAnswerAlready :>> ', foundAnswerForCurrentQuestion)
        if (foundAnswerForCurrentQuestion) {
          const optionIndex = currentStep.options.findIndex((opt) => opt.id === foundAnswerForCurrentQuestion.option_id)
          if (optionIndex > -1) {
            setSelected(optionIndex)
            setFoundAnswer(foundAnswerForCurrentQuestion)
          }
        }
      }
    }
  }, [selected, authState.user, currentStep])

  const onClickNext = async () => {
    console.log('onClickNext: selected :>> ', selected)
    if (selected !== undefined) {
      try {
        setStepLoading(true)

        if (foundAnswer) {
          await QuestionnaireAPI.updateUserAnswer(foundAnswer.id, {
            option_id: currentStep.options[selected].id,
            record: {
              question: currentStep.question,
              answer: currentStep.options[selected].title,
            },
            preference_type: currentStep.preference_type,
          })
        } else {
          await QuestionnaireAPI.createUserAnswer({
            questionnaire_title: 'onboarding',
            question_id: currentStep.id,
            option_id: currentStep.options[selected].id,
            record: {
              question: currentStep.question,
              answer: currentStep.options[selected].title,
            },
            preference_type: currentStep.preference_type,
          })
        }
        setFoundAnswer(undefined)
        if (questions.length - 1 !== activeStep) {
          // Go to next step
          setActiveStep((prev) => prev + 1)
          setSelected(undefined)
        } else {
          history.replace('/challenges')
          // // Submit
          // if (challengeState.activeChallenge) {
          // } else {
          //   setShowRecommendations(true);
          // }
        }
        setStepLoading(false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('error :>> ', error)
        setStepLoading(false)
      }
    }
  }

  const onClickPlan = (challenge: Challenge) => {
    setSelectedChallenge(challenge)
  }

  const onClickStart = async () => {
    if (!selectedChallenge) {
      return
    }
    console.log('selectedChallenge :>> ', selectedChallenge)
    try {
      setStepLoading(true)
      // Default to start current day
      let start_date = dayjs().startOf('day').utc().toISOString()
      // Already after Monday, start challenge next Monday
      if (dayjs().get('day') > 1) {
        start_date = dayjs().weekday(8).startOf('day').utc().toISOString()
      }
      // Before Monday, start challenge this Monday
      if (dayjs().get('day') < 1) {
        start_date = dayjs().weekday(1).startOf('day').utc().toISOString()
      }

      const createOptions: CreateUserChallengeOptions = {
        challenge_id: selectedChallenge.id,
        start_date,
        is_active: true,
      }
      // console.log('createOptions :>> ', createOptions);
      await ChallengeAPI.startUserChallenge(createOptions)
      setStepLoading(false)
      queryClient.refetchQueries('user')
      queryClient.refetchQueries('activeChallenge')
      history.replace('/today')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setStepLoading(false)
      console.log('error :>> ', error)
    }
  }

  const onClickSkip = () => {
    queryClient.refetchQueries('user')
  }

  if (authState.isFetching) {
    return <LoadingIndicator />
  }
  return (
    <AuthContainer>
      {showRecommendations ? (
        <>
          <Link className={styles.skipLink} to="/today" onClick={onClickSkip}>
            Skip
          </Link>
          <p className={styles.headline}>Recommended for you</p>
          <p className={styles.subHeading}>{`${authState.user?.firstName} pick the workout that suits you most, there is something for everyone.`}</p>
          <Box className={styles.banner}>
            <Together />
            <p>Let’s get after it today! Quick & Lean</p>
          </Box>
          {` <ChallengeList onClickChallenge={onClickPlan} completedChallenges={null} selected={selectedChallenge} />`}
          <Button onClick={onClickStart} containerStyle={styles.startPlanBtn} label="Start Challenge" disabled={selectedChallenge === undefined} loading={stepLoading} />
        </>
      ) : (
        <>
          {status === 'loading' ? (
            <LoadingIndicator />
          ) : (
            <>
              <StepIndicators activeIndex={activeStep} totalSteps={questions.length} />
              <p className={styles.headline}>{currentStep.question}</p>
              <p className={styles.subHeading}>{currentStep.description}</p>
              <Box className={styles.optionsContainer}>
                {(currentStep.options || []).map((option, index) => {
                  const isSelected = selected === index
                  return (
                    <SelectOption
                      key={option.title}
                      title={option.title}
                      isSelected={isSelected}
                      icon={option.icon}
                      description={option.description}
                      onClick={() => setSelected(index)}
                    />
                  )
                })}
              </Box>
              <Button onClick={onClickNext} containerStyle={styles.nextBtn} label="Next" disabled={selected === undefined || stepLoading} loading={stepLoading} />
            </>
          )}
        </>
      )}
    </AuthContainer>
  )
}
