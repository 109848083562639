import { FetchingUserAction, FetchingUserFailAction, FetchingUserSuccessAction, ResetUserStateAction, User } from 'types';

export const fetchingUserAction = (): FetchingUserAction => {
  return {
    type: 'FETCHING_USER',
  };
};

export const fetchingUserSuccessAction = (user: User): FetchingUserSuccessAction => {
  return {
    type: 'FETCHING_USER_SUCCESS',
    user,
    error: undefined,
  };
};

export const fetchingUserFailAction = (error: Error): FetchingUserFailAction => {
  return {
    type: 'FETCHING_USER_FAIL',
    error,
    user: undefined,
  };
};

export const resetUserStateAction = (): ResetUserStateAction => {
  return {
    type: 'RESET_STATE',
  };
};
