import React from 'react'
import styles from './Box.module.scss'

type BoxProps = {
  className?: string
  id?: string
  onClick?: () => void
  onScroll?: React.UIEventHandler<HTMLDivElement>
  children?: React.ReactNode
}

export const Box: React.FC<BoxProps> = React.forwardRef<HTMLDivElement, BoxProps>(({ children, className, id, onClick, onScroll }: BoxProps, ref) => {
  return (
    <div ref={ref} onScroll={onScroll} onClick={onClick} id={id} className={`${styles.container} ${className || ''}`}>
      {children}
    </div>
  )
})

Box.displayName = 'Box'
