import { UserAchievementCriteria, Achievement } from '../types/'
import calculateAchievementCriteriaProgress from './calculateAchievementCriteriaProgress'
import calculateStarRating from './calculateStarRating'

/**
 * Calculates the progress of an achievement based on user achievement criteria.
 *
 * @param {Achievement} achievement - The achievement for which to calculate the progress.
 * @param {UserAchievementCriteria[]} userAchievementCriteria - The user achievement criteria associated with the achievement.
 * @returns {{ achievementId: string, progress: number, starRating:number, breakdown: { uacId: string, uacProgress: number }[] }} - The achievement progress information.
 * @throws {Error} If userAchievementCriteria or achievement.achievementCriteria is empty, or if any required properties are undefined.
 */
const calculateAchievementProgress = (
  achievement: Achievement,
  userAchievementCriteria: UserAchievementCriteria[],
): {
  achievementId: string
  progress: number
  starRating: number
  breakdown: { uacId: string; uacProgress: number }[]
} => {
  if (userAchievementCriteria.length === 0 || !achievement.achievementCriteria || achievement.achievementCriteria.length === 0) {
    const errorMessage = 'calculateAchievementProgress: userAchievementCriteria or achievement.achievementCriteria is empty'
    console.error(errorMessage)
    throw new Error(errorMessage)
  }

  const breakdown: { uacId: string; uacProgress: number }[] = []

  const criteriaCount = userAchievementCriteria.length
  const aggregateProgress = userAchievementCriteria.reduce((accumulator, userAchievement) => {
    if (!achievement.achievementCriteria) {
      const errorMessage = 'calculateAchievementProgress: achievement.achievementCriteria is undefined'
      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    const matchedAchievementCriteria = achievement.achievementCriteria.find((criteria) => criteria.id === userAchievement.achievementCriteriaId)
    if (!matchedAchievementCriteria) {
      const errorMessage = 'calculateAchievementProgress: matchedAchievementCriteria is undefined'
      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    const criteriaProgress = calculateAchievementCriteriaProgress(userAchievement.value, matchedAchievementCriteria.target)
    if (!userAchievement.id) {
      const errorMessage = 'calculateAchievementProgress: userAchievement.id is undefined'
      console.error(errorMessage)
      throw new Error(errorMessage)
    }
    breakdown.push({ uacId: userAchievement.id, uacProgress: criteriaProgress })

    return accumulator + criteriaProgress
  }, 0)

  let progress = Math.round((aggregateProgress * 100) / criteriaCount) / 100
  if (!achievement.id) {
    const errorMessage = 'calculateAchievementProgress: achievement.id is undefined'
    console.error(errorMessage)
    throw new Error(errorMessage)
  }

  // cap at 100

  if (progress > 100) {
    progress = 100
  }

  const starRating = calculateStarRating(progress, achievement.oneStarMinPercent, achievement.twoStarMinPercent, achievement.threeStarMinPercent)

  console.log(`calculateAchievementProgress: achievementId: ${achievement.id}, progress: ${progress}, starRating: ${starRating}`)

  return { achievementId: achievement.id, progress, starRating, breakdown }
}

export default calculateAchievementProgress
