import { useContext } from 'react';
import { NotyfContext } from 'context/NotyfContext';
import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import { AddToWorkoutFavoritesBody, RemoveFromWorkoutFavoritesBody } from 'services/api/types';
import { addToFavorites, getWorkoutFavorites, removeFromFavorites } from 'services/api/workouts';
import { WorkoutFavorites } from 'types';

type WorkoutFavoritesHookTypes = {
  mutateAddFavorite: UseMutateAsyncFunction<WorkoutFavorites[], unknown, AddToWorkoutFavoritesBody, WorkoutFavorites[] | undefined>;
  mutateRemoveFavorite: UseMutateAsyncFunction<WorkoutFavorites[], unknown, RemoveFromWorkoutFavoritesBody, WorkoutFavorites[] | undefined>;
  workoutFavorites?: WorkoutFavorites[];
};

export const useWorkoutFavorites = (): WorkoutFavoritesHookTypes => {
  const workoutFavoritesQuery = useQuery<WorkoutFavorites[]>('workoutFavorites', getWorkoutFavorites, {
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  const { data: workoutFavorites } = workoutFavoritesQuery;
  const queryClient = useQueryClient();
  const notyf = useContext(NotyfContext);

  const { mutateAsync: mutateAddFavorite } = useMutation((body: AddToWorkoutFavoritesBody) => addToFavorites(body), {
    onMutate: async ({ workout_id }: AddToWorkoutFavoritesBody) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('workoutFavorites');
      const previousItems = queryClient.getQueryData<WorkoutFavorites[]>('workoutFavorites');
      // Optimistically update to the new value
      if (previousItems) {
        previousItems.push({ workout_id });
        queryClient.setQueryData<WorkoutFavorites[]>('workoutFavorites', previousItems);
      }

      return previousItems;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err: Error, variables, previousItems) => {
      notyf.error(err.message);
      if (previousItems) {
        queryClient.setQueryData<WorkoutFavorites[]>('workoutFavorites', previousItems);
      }
    },
    onSuccess: () => {
      notyf.success('Added to favorites!');
    },
    // After success or failure, refetch the query
    onSettled: async () => {
      console.log('On Settled Mutate Add');
      try {
        queryClient.invalidateQueries('workoutFavorites');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('InvalidateQueries Error', error);
      }
    },
  });

  const { mutateAsync: mutateRemoveFavorite } = useMutation((body: RemoveFromWorkoutFavoritesBody) => removeFromFavorites(body), {
    onMutate: async ({ workout_id }: RemoveFromWorkoutFavoritesBody) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('workoutFavorites');
      const previousItems = queryClient.getQueryData<WorkoutFavorites[]>('workoutFavorites');
      // Optimistically update to the new value
      if (previousItems) {
        const foundItemIndex = previousItems.findIndex((item) => item.workout_id === workout_id);
        if (foundItemIndex > -1) {
          previousItems.splice(foundItemIndex, 1);
          queryClient.setQueryData<WorkoutFavorites[]>('workoutFavorites', previousItems);
        }
      }

      return previousItems;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err: Error, variables, previousItems) => {
      notyf.error(err.message);
      if (previousItems) {
        queryClient.setQueryData<WorkoutFavorites[]>('workoutFavorites', previousItems);
      }
    },
    onSuccess: () => {
      notyf.success('Removed from favorites!');
    },
    // After success or failure, refetch the query
    onSettled: async () => {
      console.log('On Settled Mutate Remove');
      try {
        queryClient.invalidateQueries('workoutFavorites');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('InvalidateQueries Error', error);
      }
    },
  });

  return { mutateAddFavorite, mutateRemoveFavorite, workoutFavorites };
};
