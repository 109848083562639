import React from 'react'
import { ChallengeList } from '..//features/Challenge/ViewChallenges/ChallengeList'
import { Series, Challenge } from '../types'

type SeriesChallengeListProps = {
  challenges: Challenge[]
  series: Series
  handleClick: (item: Challenge) => void
  completedChallenges: { [key: string]: number } | null
}

const noOp = () => {
  console.log('no op')
}

//TODO refactor to compositional component
const SeriesChallengeList: React.FC<SeriesChallengeListProps> = ({ series, challenges, handleClick, completedChallenges }) => {
  return (
    <>
      <p className="w-full tm-4 text-center">{series.description}</p>
      <ChallengeList
        challenges={challenges}
        handleClickChallenge={handleClick}
        handleClickSeries={noOp}
        completedChallenges={completedChallenges}
        showSeries={false}
        setShowSeries={noOp}
        setSelectedSeries={noOp}
        selectedSeries={null}
      />
    </>
  )
}

export { SeriesChallengeList }
