import React from 'react'
import styles from './ListOnDemand.module.scss'
import { PageHeader, Button } from 'antd'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { Box, WorkoutItem } from 'shared/components'
import { Workout, WorkoutFavorites } from 'types'
import { FlexWorkout } from 'components/FlexWorkout'

interface ListOnDemandViewProps {
  workoutList: any[]
  title: string
  goBack: () => void
  showDrawer: () => void
  workoutFavorites: WorkoutFavorites[]
}

export const ListOnDemandView: React.FC<ListOnDemandViewProps> = ({ workoutList, title, goBack, showDrawer, workoutFavorites }) => {
  const RenderWorkoutList = () => {
    if (!workoutList.length) {
      return (
        <Box className={styles.workoutsContainer}>
          <Box>
            <h4>No results found</h4>
          </Box>
        </Box>
      )
    }

    return (
      <div className={`flex   flex-wrap `}>
        {workoutList.map((item) => {
          const isFavorite = workoutFavorites?.find((wf) => item.id === wf.workout_id)
          return <FlexWorkout key={item.id} workout={item} locked={false} showCompletedIcon={false} classNames={'mt-12 mb-0 m-8 '} />
        })}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <PageHeader
        className={styles.header}
        onBack={goBack}
        title={title}
        extra={[
          <Button key="1" onClick={showDrawer}>
            <FilterIcon />
          </Button>,
        ]}
      />
      {RenderWorkoutList()}
    </div>
  )
}
