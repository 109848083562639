import React, { useCallback } from 'react'
import styles from './Workout.module.scss'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import { Workout } from 'types'
import { ReactComponent as FavoriteStar } from 'assets/icons/favorite-star.svg'
import { ReactComponent as Check } from 'assets/icons/check.svg'
import { Box } from '../Box/Box'
import { dayjs } from 'shared/functions'

type Props = {
  title: string
  description: string
  item?: Workout
  isFavorite: boolean
  onClickFavorite: (item: Workout, isFavorite: boolean) => void
  onClickPlay: (item: Workout, challengeWorkoutId?: string) => void
  locked: boolean
}

export const WorkoutItem: React.FC<Props> = ({ title, description, item, isFavorite, onClickFavorite, onClickPlay, locked }) => {
  const { previewImageUrl } = item || {}

  const onClickFavoriteCallback = useCallback(() => {
    if (item) {
      onClickFavorite(item, isFavorite)
    }
  }, [onClickFavorite, item, isFavorite])

  const onClickPlayCallback = useCallback(() => {
    if (item) {
      onClickPlay(item)
    }
  }, [onClickPlay, item])

  return (
    <Box className={styles.workoutItem}>
      <Box className={locked ? styles.lockedOverlay : ''}>
        <p className={styles.lockedText}>Unlocks on {dayjs(item?.challenge_workout?.scheduled_date).format('MMM DD')}</p>
      </Box>
      {item?.completed_date && <Check className={styles.completedCheck} height={20} width={24} />}
      <button onClick={onClickFavoriteCallback} className={`${styles.favoriteStar}`}>
        <FavoriteStar className={isFavorite ? styles.isFavorite : ''} />
      </button>
      <Box className="overlay" />
      {previewImageUrl && <img src={previewImageUrl} className={styles.workoutImagePreview} />}

      <Box className={styles.workoutInfo}>
        <p className={styles.workoutTitle}>{title}</p>
        <p className={styles.workoutDescription}>{description}</p>
      </Box>
      <button onClick={onClickPlayCallback} className={`${styles.playBtn} bg-coral flex `}>
        <Play />
      </button>
    </Box>
  )
}
