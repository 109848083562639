import React from 'react'
import Benefit from './Benefit'
// const imagePathPrefix = 'sign-up/phil-phones'
const imagePathPrefix = 'LeanBuildChallenge'

const Marketing = () => {
  return (
    <>
      <div className="flex  justify-center space-x-3 font-extrabold  font-BebasNeueRegular ">
        {/* <span className="text-4xl lg:text-7xl mt-12  uppercase text-transparent bg-clip-text bg-gradient-to-r from-[#FFDE8E] to-[#E9AA34]  ">BLACK</span> */}
        {/* <div className="flex  justify-center space-x-3 font-extrabold  font-BebasNeueRegular text-4xl lg:text-7xl mt-8  text-center ">
          <p className="  text-slate-50 uppercase    ">
            NEW <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#d6e2ef] to-[#E9AA34]">YEAR&apos;S LEAN SALE!</span>
          </p>
        </div> */}
        {/* <p className="text-4xl lg:text-7xl mt-12 text-slate-50 uppercase  ">FRIDAY SALE!</p> */}
      </div>

      <div className="flex  justify-center space-x-3 font-extrabold  font-BebasNeueRegular text-4xl lg:text-7xl mt-8  text-center ">
        <p className="  text-slate-50 uppercase    ">
          LEAN BUILD <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#d6e2ef] to-[#FF5A5F]">& BURN CHALLENGE</span>
        </p>
      </div>
      <p className="text-2xl  text-center font-medium text-slate-50">Start transforming your body and life right now!</p>
      <div className="rounded-3xl mx-auto  mt-8 bg-gradient-to-br p-[6px]  from-[#FFDE8E] via-[#E9AA34] to-[#50504f]">
        <div className="flex justify-center   ">
          <div className="flex flex-col rounded-3xl bg-white shadow-xl shadow-slate-800 ring-1 ring-black/10  ">
            {/* /home/wright/work/LeanSquad-Web/public/images/sign-up/phil-phones-1x.jpg */}
            {/* <img
              srcSet={`images/${imagePathPrefix}-4x.jpg 3019w, images/${imagePathPrefix}-2x.jpg 2013w, images/${imagePathPrefix}-1x.jpg 1006w`}
              src={`images/${imagePathPrefix}-4x.jpg`}
              alt="preview"
              className="rounded-t-3xl"
            /> */}
            <img
              srcSet={`images/${imagePathPrefix}-2x.jpg 2013w, images/${imagePathPrefix}-1x.jpg 1006w`}
              src={`images/${imagePathPrefix}-2x.jpg`}
              alt="preview"
              className="rounded-t-3xl"
            />
            {/* <img src='images/xmas/placeholder.webp' alt="welcome to leansquad" className="rounded-t-3xl " /> */}
            <div className="flex flex-col justify-center items-center p-4 ">
              <div className="flex flex-col space-y-1 ">
                {/* <Benefit>
                  <p>Get access to ALL of my Lean Challenges & Programs!</p>
                  <p> - Over 700 workouts for all different levels, goals and transformations!</p>
                </Benefit>
                <Benefit>
                  <p>Workout 2-4 times per week and still get results with my NEW Flex Challenges! </p>
                </Benefit>
                <Benefit>
                  <p>Get all of my tasty recipes tailored to YOU!</p>
                </Benefit>
                <Benefit>
                  <p>Access all of my On Demand workouts including prenatal, guided run/walks and more!</p>
                </Benefit> */}

                {/* <Benefit>
                  <p>4 weeks long with just 5 workouts/week ranging from 25-35 mins</p>
                </Benefit>
                <Benefit>
                  <p>Combines strength training, sprinting, and HIIT for maximum fat loss, optimizing hormones, and transforming body composition</p>
                </Benefit>
                <Benefit>
                  <p>Every workout is follow along so you just press play at a time that works for you and I will guide you through every single rep and exercise</p>
                </Benefit>
                <Benefit>
                  <p>Get access to all my recipes to support your journey</p>
                </Benefit>
                <Benefit>
                  <p>
                    This challenge is for anyone and everyone, no matter how long it&apos;s been since you&apos;ve last exercised. You just need to commit and we&apos;ll do it
                    together
                  </p>
                </Benefit> */}

                <Benefit>
                  <p>4-week program with 5 workouts a week, each 25-35 minutes</p>
                </Benefit>
                <Benefit>
                  <p>Mix of strength, sprints, and HIIT for fat loss, hormone balance, and body transformation</p>
                </Benefit>
                <Benefit>
                  <p>Follow-along workouts—just hit play, and I&apos;ll guide you through every move</p>
                </Benefit>
                <Benefit>
                  <p>Get access to all my recipes to support your journey</p>
                </Benefit>
                <Benefit>
                  <p>Designed for all fitness levels. Commit, and we&apos;ll do it together!</p>
                </Benefit>
              </div>
              <button
                className={` lg:hidden  bg-gradient-to-r  disabled:bg-slate-300 shadow-md hover:ring ring-coral-darkest disabled:ring-0 from-coral to-coral-darker text-slate-50  shadow-coral-darkest         w-full  font-extrabold text-lg my-4  h-12 rounded-md`}
                onClick={() => {
                  //scroll to #sign-up
                  const element = document.getElementById('sign-up')
                  element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  // Delay the offset adjustment
                  const offset = 50 // Adjust this value to your desired offset
                  setTimeout(() => {
                    window.scrollBy({ top: -50, left: 0, behavior: 'smooth' })
                  }, 750) // Adjust delay as needed for smoothness
                  //   window.scrollBy(0, -50)
                  //   window.scrollTo({ top: 0, behavior: 'smooth' })

                  //    const element = document.getElementById('sign-up')
                  //    element?.scrollIntoView({ behavior: 'smooth', block: 'start' })

                  //    // Adjust for offset after scrollIntoView
                  //    const offset = 50 // Adjust this value to your desired offset
                  //    window.scrollBy(0, -offset)
                }}
              >
                {' '}
                Join Now!!!{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { Marketing }
