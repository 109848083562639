import React from 'react'

import IDidItButton from './IDidItButton'
import calculateAchievementCriteriaProgress from 'services/calculateAchievementCriteriaProgress'
import { useReportableUACs } from '../../hooks/useReportableUACs'

type Props = {
  acId: string
  uacId: string
  color: string
  displayText: string
  congratsText: string
  userAchievementCriteriaValue: number
  isDerived: boolean
  target: number
  inProgress: boolean
}

const CriteriaProgress = ({ acId, uacId, displayText, congratsText, userAchievementCriteriaValue, target, color, inProgress, isDerived }: Props) => {
  const { reportableUACs, isLoading, refetchReportableUACs, isReportable } = useReportableUACs()

  if (isLoading) return null

  const incomplete = userAchievementCriteriaValue < target
  const isReportableToday = isReportable(uacId, true) && incomplete

  const percentage = calculateAchievementCriteriaProgress(userAchievementCriteriaValue, target)

  console.log('CriteriaProgress', { acId, uacId, displayText, userAchievementCriteriaValue, target, color, inProgress, percentage })
  return (
    <div key={`criteria-${acId}`} className="flex flex-col items-center space-x-4 w-full">
      <div className="flex items-center w-full justify-between ">
        <div className="flex items-center space-x-4 w-4/6">
          <div className="w-1 h-1 bg-teal rounded-full"></div>
          <div className="text-xxs md:text-sm flex-1">{` ${userAchievementCriteriaValue}/${target} - ${displayText}`}</div>
        </div>
        {inProgress && !isDerived && (
          <IDidItButton acId={acId} uacId={uacId} userAchievementCriteriaValue={userAchievementCriteriaValue} congratsText={congratsText} isReportable={isReportableToday} />
        )}
      </div>
      {/* progress bar */}
      <div className="my-1 w-full  pr-3">
        <div className="h-2 bg-gray-300  rounded-md   border border-slate-400    shadow-inner   shadow-slate-300  w-full flex">
          <div className={`h-2 bg-[${color}]`} style={{ width: `${percentage}%` }}></div>
        </div>
      </div>
    </div>
  )
}

export default CriteriaProgress
