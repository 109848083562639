import React from 'react';
import { Box, FinisherItem, WorkoutItem } from 'shared/components';
import styles from './WorkoutSection.module.scss';
import { Workout } from 'types';
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites';

type WorkoutSectionProps = {
  workoutTitle?: string;
  finisherTitle?: string;
  workout: Workout;
  handlePlayClick: (item: Workout) => void;
};

const isFuture = (string?: string): boolean => {
  if (string) {
    const date = new Date(string);
    return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
  }
  return false;
};

export const WorkoutSection: React.FC<WorkoutSectionProps> = ({ workoutTitle, finisherTitle, workout, handlePlayClick }) => {
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites();
  if (!workout) {
    return null;
  }
  const isFavorite = workoutFavorites?.find((wf) => workout.id === wf.workout_id);

  const handleFavoriteClick = async (item: Workout, isFavorite: boolean) => {
    console.log('handleFavoriteClick :>> ', item, isFavorite);

    try {
      if (isFavorite) {
        await mutateRemoveFavorite({ workout_id: item.id });
      } else {
        await mutateAddFavorite({ workout_id: item.id });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Mutate:error :>> ', error);
    }
  };

  return (
    <Box className={styles.todaysWorkout}>
      {workoutTitle && <p className={styles.contentHeaderTitle}>{workoutTitle}</p>}
      <WorkoutItem
        title={workout.title}
        description={workout.duration?.title}
        item={workout}
        isFavorite={!!isFavorite}
        onClickFavorite={handleFavoriteClick}
        onClickPlay={handlePlayClick}
        locked={isFuture(workout.challenge_workout?.scheduled_date)}
      />
      {workout.finisher && (
        <>
          {finisherTitle && <p className={styles.contentHeaderTitle}>{finisherTitle}</p>}
          <FinisherItem
            title={workout.finisher.title}
            description={workout.finisher.subtitle}
            item={workout.finisher.workout}
            duration={workout.finisher.workout?.duration?.title}
            onClickPlay={handlePlayClick}
          />
        </>
      )}
    </Box>
  );
};
