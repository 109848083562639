import firebase from './config';
const { storage } = firebase;

export const uploadPicture = async (userId: string, location: string, fileName: string, file: File): Promise<string> => {
  const imageRef = storage().ref().child(`/users/${userId}/${location}/`);
  const metadata = { contentType: file.type };

  const uploadTask = await imageRef.child(fileName).put(file, metadata);
  console.log(uploadTask);
  const downloadUrl = await imageRef.child(fileName).getDownloadURL();

  return downloadUrl;
};
