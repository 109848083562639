import React from 'react'
import { RecipeItem } from '../../shared/components'
import { Recipe } from '../../types'
import styles from './Today.module.scss'

export type RecipesSectionProps = {
  title: string
  items: Array<Recipe | undefined>
  onClickRecipeItem: (item: Recipe) => void
}
export const RecipeRecommendations: React.FC<RecipesSectionProps> = ({ title, items, onClickRecipeItem }) => {
  return (
    <div className="">
      <p className={styles.contentHeaderTitle}>{title}</p>
      <div className=" grid justify-center md:grid-cols-3 xl:gap-4">
        {(items as Array<Recipe | undefined>).map((item, index) => {
          if (!item) {
            return null
          }

          return <RecipeItem key={`${index}-${item.id}`} item={item} onClick={onClickRecipeItem} />
        })}
      </div>
    </div>
  )
}
