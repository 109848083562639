import React, { useState, useEffect } from 'react'
import { Combobox } from '@headlessui/react'
import { getChallengeMap } from '../../../services/api/challenges'
import { Challenge, ChallengeMap } from '../../../types'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { setFeaturedChallenge } from 'services/api/settings'

type Props = {
  featureNumber: number
  originalChallenge?: Challenge
}

const AdminFeaturedChallengeInput = ({ featureNumber, originalChallenge }: Props) => {
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(originalChallenge ?? null)
  const [query, setQuery] = useState('')

  const [challenges, setChallenges] = useState<Challenge[]>([])

  useEffect(() => {
    const fetchChallenges = async () => {
      const challengeMap: ChallengeMap = await getChallengeMap()
      setChallenges(Object.values(challengeMap)) // Convert map to array
    }

    fetchChallenges()
  }, [])

  const handleSelectedChallenge = (challenge: Challenge) => {
    // post to api
    setFeaturedChallenge(challenge.id, featureNumber)
    setSelectedChallenge(challenge)
  }

  const filteredChallenges = query === '' ? challenges : challenges.filter((challenge) => challenge.title.toLowerCase().includes(query.toLowerCase()))

  if (challenges.length === 0) {
    return <div>Loading...</div>
  }

  console.log(selectedChallenge)
  return (
    <div key={`featuredInput${featureNumber}`} className="">
      <h2 className="text-lg font-bold">Featured Challenge {featureNumber}</h2>
      <Combobox as="div" value={selectedChallenge} onChange={handleSelectedChallenge}>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(challenge: Challenge) => (challenge ? challenge.title : '')}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredChallenges.map((challenge) => (
              <Combobox.Option className={`text-gray-900`} key={challenge.id} value={challenge}>
                {challenge.title}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

export { AdminFeaturedChallengeInput }
