import React from 'react'
import { Box } from '../Box/Box'
import styles from './AuthContainer.module.scss'

interface Props {
  children: React.ReactNode
}

export const AuthContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>{children}</Box>
    </Box>
  )
}
