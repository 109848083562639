import React from 'react';
import { Box } from 'shared/components';
import styles from './Questionnaire.module.scss';

interface StepProps {
  activeIndex: number;
  totalSteps: number;
}
export const StepIndicators: React.FC<StepProps> = ({ activeIndex = 0, totalSteps = 1 }) => {
  return (
    <Box className={styles.stepsContainer}>
      <Box className={styles.stepIndicatorsContainer}>
        {Array.from(Array(totalSteps).keys()).map((item, index) => {
          const isActive = activeIndex === index;
          return <Box key={`step-${index}`} className={`${styles.indicator} ${isActive ? styles.activeIndicator : ''}`} />;
        })}
      </Box>
    </Box>
  );
};
