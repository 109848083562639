import React from 'react'

type ChallengeDataTableProps = {
  challengeData: any
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ChallengeDataTable({ challengeData }: ChallengeDataTableProps) {
  if (!challengeData) return null

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users In The challenge</h1>
          <p className="mt-2 text-xs text-gray-700">A list of all the users in the challenge.</p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Cancelled
                    </th>

                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Start
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      End
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Commitment
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      With Music
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      SubType
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      NextBillingEndDate
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {challengeData.map((userChallenge: any, index: number) => (
                    <tr key={userChallenge.email + index}>
                      <td
                        className={classNames(
                          index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8',
                        )}
                      >
                        {userChallenge.email}
                      </td>
                      <td className={classNames(index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-xs text-gray-500')}>
                        {userChallenge.cancelled && 'Yes'}
                        {!userChallenge.cancelled && 'No'}
                      </td>
                      <td
                        className={classNames(
                          index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-4 text-xs text-gray-500 hidden sm:table-cell',
                        )}
                      >
                        {userChallenge.start_date}
                      </td>
                      <td
                        className={classNames(
                          index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-4 text-xs text-gray-500 hidden lg:table-cell',
                        )}
                      >
                        {userChallenge.end_date}
                      </td>
                      <td className={classNames(index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-xs text-gray-500')}>
                        {userChallenge.weekly_commitment}
                      </td>
                      <td className={classNames(index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-xs text-gray-500')}>
                        {userChallenge.with_music && 'Yes'}
                        {!userChallenge.with_music && 'No'}
                      </td>
                      <td className={classNames(index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-xs text-gray-500')}>
                        {userChallenge.type}
                      </td>
                      <td className={classNames(index !== userChallenge.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-xs text-gray-500')}>
                        {new Date(userChallenge.current_period_end).toLocaleDateString('en-CA')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
