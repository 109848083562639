const calculateStarRating = (progress: number, requirementForOne: number, requirementForTwo: number, requirementForThree: number) => {
  console.log(
    `calculateStarRating: progress: ${progress}, requirementForOne: ${requirementForOne}, requirementForTwo: ${requirementForTwo}, requirementForThree: ${requirementForThree}`,
  )
  if (progress >= requirementForThree) {
    return 3
  } else if (progress >= requirementForTwo) {
    return 2
  } else if (progress >= requirementForOne) {
    return 1
  } else {
    return 0
  }
}

export default calculateStarRating
