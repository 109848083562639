import { get, post, patch, del } from './helpers'
import { UserAchievement } from '../../types'

export const create = async (userAchievementData: UserAchievement) => {
  try {
    // get user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //add it to the userAchievementData

    userAchievementData = { ...userAchievementData, userTimeZone }
    const createdUserAchievement = await post<UserAchievement>('/userAchievements', userAchievementData)
    return createdUserAchievement
  } catch (error) {
    console.error('Error creating user achievement:', error)
    throw error
  }
}

// Get all UserAchievements
export const getAll = async () => {
  try {
    const userAchievements = await get<UserAchievement[]>('/userAchievements')
    return userAchievements
  } catch (error) {
    console.error('Error getting user achievements:', error)
    throw error
  }
}

// Patch a UserAchievement
export const update = async (userAchievementData: UserAchievement) => {
  console.log({ userAchievementData })
  try {
    const patchedUserAchievement = await patch<UserAchievement>(`/userAchievements/${userAchievementData.id}`, userAchievementData)
    return patchedUserAchievement
  } catch (error) {
    console.error('Error patching user achievement:', error)
    throw error
  }
}
// Delete a UserAchievement
export const remove = async (id: string) => {
  try {
    await del(`/userAchievements/${id}`)
  } catch (error) {
    console.error('Error deleting user achievement:', error)
    throw error
  }
}

export default {
  create,
  getAll,
  update,
  remove,
}
