import { RecipeRecommendations } from './RecipeRecommendations'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Box, FinisherItem, LoadingIndicator, ProgressBar, RecipeItem } from 'shared/components'
import styles from './Today.module.scss'
import { AuthStateContext } from 'context'
import { Recipe, RecommendedRecipes, Workout } from 'types'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { dayjs, DocumentUtils } from 'shared/functions'
import { RecommendationsAPI } from 'services/api'
import { WorkoutSection } from 'features/Workout/WorkoutSection/WorkoutSection'
import useScrollPosition from 'hooks/useScrollPosition'
import { countCompletedWorkouts, isFlexChallenge } from '../../shared/functions/utils'
import { FlexWeekWorkouts } from 'components/FlexWeekWorkouts'
import { FlexWorkout } from 'components/FlexWorkout'
import { FlexGoal } from 'components/FlexGoal'
import FlexFinisher from 'components/FlexFinisher'
import { Link, useParams } from 'react-router-dom'
import { DailyContentReponse, UserChallengeWithRelatedData } from 'services/api/types'
import Countdown from 'components/CountDown'
import TodayAchievements from 'components/Achievements/TodayAchievements'
// import { PauseUserChallengeButton } from 'components/PauseUserChallengeButton'
// import { PauseIcon } from '@heroicons/react/24/outline'
import { FeaturedChallengeList } from 'components/FeaturedChallengeList'
import timezone from 'dayjs/plugin/timezone'
import { useQuery } from 'react-query'
import * as UCServices from 'services/api/userChallenges'
import { AdvanceWeek } from 'components/AdvanceWeek'

type todayParams = {
  queryDate?: string
}

export const Today: React.FC = () => {
  const params = useParams<todayParams>()
  const [queryDate, setQueryDate] = React.useState(params.queryDate ? params.queryDate : dayjs().startOf('day').toISOString())

  const history = useHistory()
  const { state: authState } = useContext(AuthStateContext)
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge, isFetching } = userChallengeContextState
  const [flexWeekCommitmentGoal, setFlexWeekCommitmentGoal] = React.useState(0)
  const [completedCommitment, setCompletedCommitment] = React.useState(0)

  const [todaysContentLoading, setTodaysContentLoading] = React.useState(true)
  const [todaysContent, setTodayContent] = React.useState<DailyContentReponse | null>(null)
  const queryParams = new URLSearchParams(useLocation().search)
  // const [isPaused, setPaused] = React.useState(false)
  const [showAdvanceWeek, setShowAdvanceWeek] = React.useState(false)
  const [disableScrubber, setDisableScrubber] = React.useState(false)

  const {
    data: currentUserChallenge,
    isLoading: currentUserChallengeLoading,
    error: currentUserChallengeError,
  } = useQuery<UserChallengeWithRelatedData | null>('currentUserChallenge', UCServices.getCurrentUserChallenge, {
    staleTime: Infinity,
  })

  const fetchContent = () => {
    const viewAsId = queryParams.get('viewAsId')
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (viewAsId) {
      return RecommendationsAPI.getTodaysContent(undefined, queryDate, undefined, viewAsId, timezone)
    }
    return RecommendationsAPI.getTodaysContent(undefined, queryDate, undefined, undefined, timezone)
  }

  useEffect(() => {
    console.log('queryDate', queryDate)
    if (!queryDate) return
    setTodaysContentLoading(true)
    fetchContent().then((data) => {
      console.log('data', data, 'setting content and setting loading to false')
      setTodayContent(data)
      setTodaysContentLoading(false)
    })
  }, [queryDate, currentUserChallenge])

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, todaysContentLoading ? false : true)

  const { user } = authState

  useEffect(() => {
    // activeChallenge && user && user.timezone
    if (activeChallenge && user && user.timezone) {
      setShowAdvanceWeek(true)
    } else {
      setShowAdvanceWeek(false)
    }
    return () => {
      setShowAdvanceWeek(false)
    }
  }, [activeChallenge, user])

  const weekFinishers =
    todaysContent &&
    todaysContent.weeklyWorkouts?.map((workout) => {
      if (workout.finisher) return workout.finisher
    })
  useEffect(() => {
    const hasWorkouts = todaysContent && todaysContent.weeklyWorkouts && todaysContent.weeklyWorkouts.length > 0
    const hasExpressWorkouts = todaysContent && todaysContent.expressWorkouts && todaysContent.expressWorkouts.length > 0

    if (hasWorkouts || hasExpressWorkouts) {
      //calculate commitment completion
      // presently, this is all todaysContent.weeklyWorkouts and the express workouts
      const eligibleWorkouts = (todaysContent.weeklyWorkouts ?? []).concat(todaysContent.expressWorkouts ?? [])
      const completedWorkouts = countCompletedWorkouts(eligibleWorkouts)
      setCompletedCommitment(completedWorkouts)
    }
  }, [todaysContent, history])

  useEffect(() => {
    if (!!activeChallenge && !isFlexChallenge(activeChallenge.challenge)) return
    if (todaysContent && todaysContent.weeklyCommitment) {
      // set the commitment goal
      setFlexWeekCommitmentGoal(todaysContent.weeklyCommitment)
    }
  }, [todaysContent, activeChallenge])

  const onClickRecipeItem = useCallback(
    (item: Recipe) => {
      setScrollYStorage(DocumentUtils.getContentDivScroll())
      console.log('onClickRecipeItem :>> ', item)
      history.push(`/recipes/${item.id}/overview`, {
        recipe: item,
      })
    },
    [todaysContent],
  )

  const handlePlayClick = useCallback(
    (item: Workout) => {
      setScrollYStorage(DocumentUtils.getContentDivScroll())
      if (item.challenge_workout) {
        history.push(`/workouts/${item.id}/overview?challengeWorkout=${item.challenge_workout.id}`, {
          workout: item,
        })
      } else {
        history.push(`/workouts/${item.id}/overview`, {
          workout: item,
        })
      }
    },
    [todaysContent],
  )

  const renderRegularWorkout = useCallback(() => {
    if (todaysContent && todaysContent.workout) {
      return (
        <div className="mt-2">
          <WorkoutSection
            workoutTitle={todaysContent.workout.challenge_workout ? "TODAY'S WORKOUT" : "TODAY'S RECOMMENDED WORKOUT"}
            finisherTitle={todaysContent.workout.challenge_workout ? "TODAY'S FINISHER" : "TODAY'S RECOMMENDED FINISHER"}
            workout={todaysContent.workout}
            handlePlayClick={handlePlayClick}
          />
        </div>
      )
    }
  }, [todaysContent])

  const inActiveFlexChallenge = () => {
    const isChalllengeInTheFuture = activeChallenge && dayjs(dayjs(activeChallenge.start_date).startOf('day')).isAfter(queryDate)
    const inThePast = activeChallenge && dayjs(dayjs(activeChallenge.end_date).startOf('day')).isBefore(queryDate)
    return activeChallenge && isFlexChallenge(activeChallenge.challenge) && !isChalllengeInTheFuture && !inThePast
  }

  const renderFlexWorkout = () => {
    if (
      todaysContent &&
      todaysContent.weeklyWorkouts &&
      todaysContent.weekNumber &&
      todaysContent.weeklyCommitment &&
      todaysContent.optionalStretches &&
      todaysContent.expressWorkouts
    ) {
      if (!todaysContent.weeklyWorkouts || todaysContent.weeklyWorkouts.length === 0) return null
      const locked = flexContentLocked()

      const startDate = activeChallenge?.start_date || ''
      console.log({ todaysContent })

      const calculate7DaysFromStartTime = () => {
        const start = dayjs(startDate)

        const week = todaysContent.weekNumber || 1

        const currentDate = start.add(1 * week - 1, 'week')
        // const currentDate = start.add(7*week-1, 'day')
        const endDate = currentDate.add(1, 'week')
        return endDate.toDate()
      }
      const maxWeeks = activeChallenge!.challenge.numberOfWeeks || 0

      // if (isPaused) {
      //   return (
      //     <div className="flex flex-col items-center justify-center bg-gray-100 p-6 rounded-lg shadow space-y-3">
      //       <h2 className="text-xl font-bold text-gray-700">Paused</h2>
      //       <p className="text-gray-500 text-center">Your challenge is currently paused. Click above when you`&apos;`re ready to resume.</p>
      //     </div>
      //   )
      // }
      console.log('user tz:>> ', user, user?.timezone)
      return (
        <>
          <div className="flex flex-col">
            <FlexGoal commitment={flexWeekCommitmentGoal} completedCount={completedCommitment} startDate={startDate} />
            <div className="mt-4">
              {currentUserChallenge && user && user.timezone && currentUserChallenge.skipHours === 0 && (
                <Countdown
                  startDate={currentUserChallenge.startDate.toLocaleString()}
                  tz={user.timezone}
                  date={calculate7DaysFromStartTime()}
                  weekNumber={todaysContent.weekNumber}
                  maxWeeks={maxWeeks}
                />
              )}
              {showAdvanceWeek && (
                <AdvanceWeek />
                // <AdvanceWeek />
              )}
            </div>
            <FlexWeekWorkouts key="workouts" className="mt-0">
              {todaysContent.weeklyWorkouts.concat(todaysContent.expressWorkouts).map((workout) => {
                return <FlexWorkout key={workout.id} workout={workout} locked={locked} />
              })}
            </FlexWeekWorkouts>
            <FlexWeekWorkouts key="finisher" title="Finishers">
              {weekFinishers &&
                weekFinishers.map((finisher, index) => {
                  if (finisher && finisher.workout) return <FlexFinisher key={`finisher-${finisher.workout.id}-${index}`} finisher={finisher.workout} useContext={false} />
                })}
            </FlexWeekWorkouts>
            <FlexWeekWorkouts key="stretches" title="Optional Stretches & Leancoveries">
              {todaysContent.optionalStretches.map((workout) => {
                return <FlexWorkout key={workout.id} workout={workout} locked={locked} optional />
              })}
            </FlexWeekWorkouts>
          </div>
        </>
      )
    }
  }

  const flexContentLocked = () => {
    //if challenge week is in the future, content is locked
    // today is before queryDate
    const isQueryDateInTheFuture = activeChallenge && dayjs(dayjs()).startOf('day').isBefore(queryDate)
    return isQueryDateInTheFuture === true
  }

  const renderWorkout = () => {
    const inThePast = activeChallenge && dayjs(dayjs(activeChallenge.end_date).startOf('day')).isBefore(queryDate)

    if (inActiveFlexChallenge()) {
      return renderFlexWorkout()
    } else if (activeChallenge && inThePast) {
      return (
        <div className="mt-6">
          <h1 className="text-2xl font-bold text-center">Your Current Challenge Ended On:</h1>
          <h1 className="text-2xl font-bold text-center">{dayjs(activeChallenge.end_date).format('ddd, MMM DD')}</h1>

          <h1 className="text-lg font-bold text-center mt-8">Think about your &apos;Why&apos; and what you want to achieve Next!</h1>
        </div>
      )
    } else {
      return renderRegularWorkout()
    }
  }

  const renderAchievementBrowser = () => {
    return <TodayAchievements />
  }

  const renderRecipes = useCallback(() => {
    if (!todaysContent || !todaysContent.recipes) {
      return null
    }
    const recipes = todaysContent.recipes
    if (todaysContent && Object.keys(recipes).length > 0) {
      let isRecommended = false
      const recipesMapped = (Object.keys(recipes) as Array<keyof RecommendedRecipes>).map((key) => {
        const recipe = recipes[key]
        if (!recipe.scheduled_recipe) {
          isRecommended = true
        }
        return recipe
      })

      return <RecipeRecommendations title={isRecommended ? "TODAY'S RECOMMENDED RECIPES" : "TODAY'S RECIPES"} items={recipesMapped} onClickRecipeItem={onClickRecipeItem} />
    }

    return null
  }, [todaysContent, onClickRecipeItem])

  let currentDay = ''
  let progress = 0
  let totalDays = 0
  if (activeChallenge && activeChallenge.start_date) {
    // const daysSinceStart = dayjs().startOf('day').utc().diff(activeChallenge.start_date, 'days')
    console.log(activeChallenge.start_date)
    const startDate = dayjs(activeChallenge.start_date).utc()
    const today = dayjs().tz('Pacific/Auckland').startOf('day') // Today's date at the start of the day in Auckland

    const daysSinceStart = today.diff(startDate, 'days')
    console.log('daysSinceStart', daysSinceStart)

    const daysCount = daysSinceStart + 1 // Adding 1 because we count the start day as Day 1
    console.log('daysCount', daysCount)

    // If they are in a flex challenge, we need to calculate the current day based on the week number

    if (inActiveFlexChallenge() && activeChallenge.challenge.numberOfWeeks) {
      totalDays = activeChallenge.challenge.numberOfWeeks * 7
      progress = ((daysCount >= 0 ? daysCount : 0) / totalDays) * 100
    } else {
      progress = ((daysCount >= 0 ? daysCount : 0) / activeChallenge.challenge.totalWorkouts) * 100
    }
    currentDay = `Day ${daysCount >= 0 ? daysCount : 0}`
  }

  const handleScrubRight = async () => {
    if (!todaysContent) return
    const challengeStartDate = dayjs(activeChallenge?.start_date)
    if (inActiveFlexChallenge() && todaysContent.weekNumber) {
      // current week number
      const currentWeekNumber = todaysContent?.weekNumber

      await setQueryDate(() => challengeStartDate.add(currentWeekNumber, 'week').toISOString())
    } else {
      await setQueryDate((currentDate) => dayjs(currentDate).add(1, 'day').toISOString())
    }
    history.push(`/today/${queryDate}`)
  }
  const handleScrubLeft = async () => {
    if (!todaysContent) return
    const challengeStartDate = dayjs(activeChallenge?.start_date)

    // queryDate is the start of the challenge
    const isStartOfChallenge = dayjs(queryDate).isSame(challengeStartDate, 'day')

    console.log('start of challenge', dayjs(queryDate).isSame(challengeStartDate, 'day'))
    if (inActiveFlexChallenge() && todaysContent.weekNumber && activeChallenge && !isStartOfChallenge) {
      // current week number
      const currentWeekNumber = todaysContent?.weekNumber

      await setQueryDate(() =>
        dayjs(challengeStartDate)
          .add(currentWeekNumber - 2, 'week')
          .toISOString(),
      )
    } else {
      await setQueryDate((currentDate) => dayjs(currentDate).subtract(1, 'day').toISOString())
    }
    history.push(`/today/${queryDate}`)
  }

  const datePill = useMemo(() => {
    if (inActiveFlexChallenge() && !todaysContentLoading) {
      return <div className="w-24  ">Week {todaysContent?.weekNumber}</div>
    } else {
      if (!todaysContentLoading) {
        return <div className="w-24 text-center">{dayjs(queryDate).format('ddd, MMM DD')}</div>
      }
    }
    return <div className="w-24 animate-spin">|</div>
  }, [queryDate, todaysContent, todaysContentLoading])

  useEffect(() => {
    if (!currentUserChallenge) return
    if (currentUserChallenge && currentUserChallenge.skipHours > 0) {
      setDisableScrubber(true)
    }

    return () => {
      setDisableScrubber(false)
    }
  }, [currentUserChallenge])

  return (
    <Box className={styles.container}>
      <p className={styles.welcomeText}>{`Welcome back, ${user?.firstName}`}</p>
      <Box className={styles.header}>
        <Box className={`${styles.myProgramContainer}`}>
          <Box>
            {activeChallenge && (
              <>
                <p className={styles.programHeader}>My Program</p>
                <p className={styles.programTitle}>{activeChallenge.challenge.title}</p>
              </>
            )}
            {!activeChallenge && (
              <>
                <p className={styles.programHeader}>My Program</p>
                <Link to="/challenges" className={styles.forgotPasswordLink}>
                  <button
                    className={`h-8 items-center transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2 bg-coral hover:bg-coral-dark hover:ring-coral-darker hover:ring-2 text-slate-50  px-2   shadow-sm focus:outline-none  focus:ring-white `}
                  >
                    Find A Challenge To Begin!
                  </button>
                </Link>
              </>
            )}
          </Box>
          {currentUserChallenge && (
            <Box className={styles.progressContainer}>
              <ProgressBar currentUserChallenge={currentUserChallenge} size={50} strokeWidth={6} tz={user?.timezone || ''} />
            </Box>
          )}
        </Box>
        <div className="">
          <div className="flex justify-center mb-2">
            {!disableScrubber && (
              <button className="  px-2 rounded-lg stroke-coral	" onClick={handleScrubLeft}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-8 h-8 text-coral">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </button>
            )}

            <div className="flex flex-row ">
              <div className="rounded-full w-24 mx-2 pt-1.5 h-8 bg-coral text-center text-slate-50 font-semibold">{datePill}</div>
              {/* {activeChallenge && <PauseUserChallengeButton userChallengeId={activeChallenge.id} setPaused={setPaused} isPaused={isPaused} />} */}
            </div>
            {!disableScrubber && (
              <button className=" px-2 rounded-lg stroke-coral	" onClick={handleScrubRight}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-8 h-8  text-coral">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </Box>
      {isFetching || todaysContentLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="   ">
          <div className="grid grid-cols-7 gap-y-2 lg:gap-x-8 xl:gap-x-16 3xl:gap-x-32">
            <div className="col-span-7 xl:col-span-4">{renderAchievementBrowser()}</div>
            <div className="col-span-7 xl:col-span-4">{renderWorkout()}</div>
            <div className="col-span-7 mt-2 xl:col-span-3">
              <FeaturedChallengeList />
              {renderRecipes()}
            </div>
          </div>
        </div>
      )}
    </Box>
  )
}
