import React from 'react'
import Popup from 'reactjs-popup'
import styles from './Modal.module.scss'
import { ReactComponent as Close } from 'assets/icons/close-x.svg'
// import 'reactjs-popup/dist/index.css';

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}
export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <Popup open={isOpen} modal onClose={onClose} lockScroll>
      <div className={styles.modal}>
        <button onClick={onClose} className={styles.modalCloseBtn}>
          <Close height={24} width={24} />
        </button>
        {children}
      </div>
    </Popup>
  )
}
