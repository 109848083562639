import React, { useContext, useEffect, useState } from 'react'
import UserAdmin from './components/AdminUser'
import AdminSettings from './components/AdminSettings'
import AdminChallenge from './components/AdminChallenge'
import AdminAchievement from './components/AdminAchievements'
import { useHistory } from 'react-router-dom'
import { AuthStateContext } from 'context'
import { downloadActiveSubEmails } from '../../services/api/admin'

const tabs = ['User Admin', 'Challenge Details', 'Achievements', 'Settings']

export default function AdminIndex() {
  const { state: authState } = useContext(AuthStateContext)
  const [currentTab, setCurrentTab] = useState('User Admin')
  const history = useHistory()
  const { user } = authState
  // if user is not admin, redirect to home
  useEffect(() => {
    if (!user || !user.email || !user.email.includes('@lean-squad.com')) {
      history.push('/')
    } else {
      console.log('Admin Action, user :>> ', user)
    }
  }, [user, history])

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select id="tabs" name="tabs" className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" defaultValue={currentTab}>
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <p
                key={tab}
                onClick={() => setCurrentTab(tab)}
                className={`${
                  tab === currentTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm`}
                aria-current={tab === currentTab ? 'page' : undefined}
              >
                {tab}
              </p>
            ))}
          </nav>
        </div>
      </div>

      {currentTab === 'User Admin' && (
        <div className="flex flex-col   justify-start">
          <UserAdmin />
          <h1 className="mt-12   text-lg font-bold">Download Active Subs Emails</h1>
          <button
            type="button"
            className=" py-2  w-32 mt-4 items-center rounded-md border border-transparent bg-indigo-100   text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            // When clicked it will fetch  from /1/admin/subscribers/active
            onClick={(e) => {
              console.log('clicked')
              downloadActiveSubEmails()
            }}
          >
            Download CSV
          </button>
        </div>
      )}
      {currentTab === 'Challenge Details' && <AdminChallenge />}
      {currentTab === 'Settings' && <AdminSettings />}
      {currentTab === 'Achievements' && <AdminAchievement />}
    </div>
  )
}
