import React, { FC, ButtonHTMLAttributes } from 'react'

type ButtonProps = {
  label: string
  className?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

const AdminButton: FC<ButtonProps> = ({ label, className = '', ...props }) => (
  <button
    className={`items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${className}`}
    {...props}
  >
    {label}
  </button>
)

export default AdminButton
