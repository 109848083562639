import React from 'react'
import { default as ReactCalendar } from 'react-calendar'
import { Dayjs } from 'dayjs'
import styles from './Calendar.module.scss'
import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg'
import { dayjs } from '../../functions'
import { Box } from '../Box/Box'

type Props = {
  selectedDate: Dayjs
  setSelectedDate: (date: Dayjs) => void
  activeDate: Dayjs
  setActiveDate: (date: Dayjs) => void
  completed?: Array<string>
  scheduled?: Array<string>
  flexDays?: Array<string>
}

export const Calendar: React.FC<Props> = ({ selectedDate, setSelectedDate, activeDate, setActiveDate, completed, scheduled, flexDays }) => {
  const onChangeDate = (date: Date | Date[]) => {
    console.log('value :>> ', date)
    setSelectedDate(dayjs(date as Date).startOf('day'))
    setActiveDate(dayjs(date as Date).startOf('day'))
  }

  const onClickNextMonth = () => {
    setActiveDate(dayjs(activeDate).add(1, 'month'))
    setSelectedDate(dayjs(activeDate).add(1, 'month').startOf('month'))
  }
  const onClickPrevMonth = () => {
    setActiveDate(dayjs(activeDate).subtract(1, 'month'))
    setSelectedDate(dayjs(activeDate).subtract(1, 'month').startOf('month'))
  }

  return (
    <Box className={styles.calendarContainer}>
      <Box className={styles.calendarHeader}>
        <p>{dayjs(activeDate).format('MMMM YYYY')}</p>
        <Box className={styles.calendarControls}>
          <button onClick={onClickPrevMonth}>
            <Chevron className={styles.chevronLeft} />
          </button>
          <button onClick={onClickNextMonth}>
            <Chevron className={styles.chevronRight} />
          </button>
        </Box>
      </Box>
      <Box>
        <ReactCalendar
          className={styles.calendar}
          onChange={onChangeDate}
          defaultView="month"
          showNavigation={false}
          formatShortWeekday={(locale, date) => dayjs(date).format('ddd')[0]}
          activeStartDate={activeDate.toDate()}
          value={selectedDate.toDate()}
          calendarType="US"
          onActiveStartDateChange={(props) => console.log('onActiveStartDateChange :>> ', props)}
          tileClassName={({ date }) => {
            const classNames = []
            if (completed) {
              if (completed.find((i) => dayjs.utc(i).isSame(dayjs.utc(date), 'day'))) {
                classNames.push('complete')
              }
            }
            if (scheduled) {
              if (scheduled.find((i) => dayjs.utc(i).isSame(dayjs.utc(date), 'day'))) {
                classNames.push('scheduled')
              }
            }

            if (flexDays) {
              if (flexDays.find((i) => dayjs.utc(i).isSame(dayjs.utc(date), 'day'))) {
                classNames.push('flexDay')
              }
            }

            return classNames.join(' ')
          }}
        />
      </Box>
    </Box>
  )
}
