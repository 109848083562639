import React, { useCallback, useState } from 'react'
import { Achievement } from '../../../types/index'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useAchievements } from '../../../hooks/useAchievements'
import { useQuery } from 'react-query'
import CreateAchievement from './CreateAchievement'
import EditAchievement from './EditAchievement'
import AdminButton from './AdminButton'

const AdminAchievements: React.FC = () => {
  // react Query hook to get all achievements
  const { data: achievements, isLoading, remove } = useAchievements()
  const [selectedAchievementId, setSelectedAchievementId] = useState<string | null>(null)
  const [showCreateAchievement, setShowCreateAchievement] = useState(false)
  const [showEditAchievement, setShowEditAchievement] = useState(false)

  const handleEdit = useCallback((someAchievement: Achievement) => {
    setSelectedAchievementId(someAchievement.id ?? '')
    setShowEditAchievement(true)
  }, [])

  const deleteHandler = (someAchievement: Achievement) => {
    // remove via api
    if (someAchievement.id) {
      remove(someAchievement.id)
    }
  }

  if (showCreateAchievement) return <CreateAchievement setShowCreateAchievement={setShowCreateAchievement} />
  if (showEditAchievement && selectedAchievementId && achievements) return <EditAchievement achievementId={selectedAchievementId} setShowEditAchievement={setShowEditAchievement} />

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-4">Achievements</h1>
      <div className="inline-flex">
        <AdminButton label="Create Achievement" onClick={() => setShowCreateAchievement(true)} />
      </div>

      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 ">Id</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Image</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {achievements?.map((achievement) => (
            <tr key={achievement.id} className="text-center border-b">
              <td className="px-4 py-2  text-xxs">{achievement.id}</td>
              <td className="px-4 py-2">{achievement.name}</td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2">
                <div className="flex flex-row justify-around">
                  <AdminButton
                    label="Edit"
                    onClick={() => {
                      handleEdit(achievement)
                    }}
                  />
                  {/* Delete button garbage can icon */}
                  <button
                    className="font-bold py-2 px-4 rounded inline-flex items-center hover:text-red-600"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this item?')) {
                        deleteHandler(achievement)
                      }
                    }}
                  >
                    <TrashIcon className="w-6 h-6  text-gray-500  opacity-50 hover:opacity-100 " />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AdminAchievements
