import React, { useEffect, useState } from 'react'
import * as SettingsAPI from '../../../services/api/settings'
import { Challenge, ChallengeAchievementAssociation, ChallengeMap } from '../../../types'
import AdminButton from './AdminButton'
import { AssociatedAchievementEntry } from './AssociatedAchievementEntry'
import { useAchievements } from '../../../hooks/useAchievements'
import { getChallengeMap } from '../../../services/api/challenges'

const AssociatedAchievementsTable = () => {
  const AchievementContext = useAchievements()
  const [challenges, setChallenges] = useState<Challenge[]>([])

  const [associationsMap, setAssociationsMap] = useState<null | Map<string, ChallengeAchievementAssociation>>(null)
  // on mount fetch associations from service
  const fetchAssociations = async () => {
    const response = await SettingsAPI.getChallengeAchievementAssociations()
    setAssociationsMap(response)
  }
  useEffect(() => {
    fetchAssociations()
  }, [])

  useEffect(() => {
    const fetchChallenges = async () => {
      const challengeMap: ChallengeMap = await getChallengeMap()
      setChallenges(Object.values(challengeMap)) // Convert map to array
    }

    fetchChallenges()
  }, [])

  const findChallengeName = (challengeId: string) => {
    if (challenges) {
      const challenge = challenges.find((challenge) => challenge.id === challengeId)
      return challenge?.title
    }
    return ''
  }

  // on change, update associations with service
  const updateAssociations = async (associationsMap: Map<string, ChallengeAchievementAssociation> | undefined) => {
    if (associationsMap) {
      const response = await SettingsAPI.setChallengeAchievementAssociations(associationsMap)
      console.log(`updateAssociations response ${JSON.stringify(response)}`)
    }
  }

  const handleKeyRemoval = (key: string) => {
    if (associationsMap) {
      const newMap = new Map(associationsMap)
      newMap.delete(key)
      setAssociationsMap(newMap)
      updateAssociations(newMap)
    }
  }

  const handleKeyAddition = (key: string, value: string) => {
    console.log(`handleKeyAddition ${key} ${value}`)
    if (associationsMap) {
      const newMap = new Map(associationsMap)
      newMap.set(key, { challengeId: key, achievementId: value })
      setAssociationsMap(newMap)
      updateAssociations(newMap)
    }
  }
  if (AchievementContext.isLoading || !challenges) return <div>Loading...</div>

  return (
    <>
      <h1 className="  text-lg font-bold text-center mt-5 mb-2">AssociateAchievementsTable</h1>
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                challengeId
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Achievement
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                remove
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {associationsMap &&
              Array.from(associationsMap.values()).map((association) => {
                return (
                  <tr key={association.challengeId} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">{findChallengeName(association.challengeId)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">{AchievementContext.findById(association.achievementId)?.name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">
                      <AdminButton
                        label={'remove'}
                        onClick={() => {
                          if (window.confirm('Are you sure you want to remove this?')) {
                            handleKeyRemoval(association.challengeId)
                          }
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            <tr key="newEntry" className="even:bg-sky-100">
              <AssociatedAchievementEntry handleSubmit={handleKeyAddition} challenges={challenges} />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export { AssociatedAchievementsTable }
