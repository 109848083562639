import React from 'react'
import { Box } from '../Box/Box'
import styles from './SelectOption.module.scss'

type Props = {
  isSelected?: boolean
  title: string
  description: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | string | null
  onClick: (...args: unknown[]) => void
}

export const SelectOption: React.FC<Props> = ({ title, isSelected, icon: Icon, description, onClick }) => {
  return (
    <Box className={`${styles.optionContainer} ${isSelected ? styles.optionSelected : ''}`} onClick={onClick}>
      {Icon && <Box className={styles.iconContainer}>{typeof Icon === 'string' ? <img src={Icon} className={styles.image} /> : <Icon />}</Box>}
      <Box className={styles.optionContent}>
        <p>{title}</p>
        <p>{description}</p>
      </Box>
    </Box>
  )
}
