import React, { useEffect, useState } from 'react'
import { VirtualizedMessageList, MessageInput, Thread, Window, useChannelStateContext } from 'stream-chat-react'
import { DocumentUtils } from 'shared/functions'

const ChatArea = () => {
  const channelContext = useChannelStateContext()
  const [showMessages, setShowMessages] = useState(true)

  useEffect(() => {
    console.log('channel context thread', channelContext.thread)
    if (channelContext.thread !== null) {
      DocumentUtils.scrollToTopContent()
      return setShowMessages(false)
    }
    setShowMessages(true)
  }, [channelContext.thread])

  return (
    <>
      {showMessages && (
        <Window>
          <VirtualizedMessageList></VirtualizedMessageList>
          <MessageInput />
        </Window>
      )}
      <Thread fullWidth />
    </>
  )
}

export default ChatArea
