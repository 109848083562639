import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import * as queryString from 'qs'
import { getRecipeCategories, getRecipeMealTypes, getRecipes } from 'services/api/recipes'
import { MealType, Recipe, RecipeCategory } from 'types'
import { AuthStateContext } from 'context'

type RecipeData = {
  recipeCategories?: RecipeCategory[]
  mealTypes?: MealType[]
  recipes?: Recipe[]
  recipesLoading: boolean
  filterTypeId?: string
}

export const useRecipeData = (): RecipeData => {
  const location = useLocation()
  const history = useHistory()
  const { state: authState } = useContext(AuthStateContext)
  // Use qs.parse to correctly parse the query string
  const qsParsed = queryString.parse(location.search, { ignoreQueryPrefix: true })
  const { type, date } = qsParsed as { type?: string; date?: string } // Type assertion to use specific structure

  let userMealPreference: string | null = null
  if (authState.user?.preferences) {
    const mealTypePref = authState.user.preferences.find((i) => i.preference_type === 'meal-type')
    if (mealTypePref) {
      userMealPreference = mealTypePref.record.answer
    }
  }

  const { data: recipeCategories } = useQuery('recipeCategories', getRecipeCategories, {
    staleTime: Infinity,
    onSuccess: (data) => {
      if (!type && data?.length) {
        const newType = data[0].title
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('type', newType)
        if (date) {
          searchParams.set('date', date as string)
        }
        history.replace({ search: searchParams.toString() })
      }
    },
  })

  let filterTypeId = ''
  if (recipeCategories && type) {
    const foundCategory = recipeCategories.find((item) => item.title === type)
    if (foundCategory) {
      filterTypeId = foundCategory.id
    }
  }

  const { data: mealTypes } = useQuery('mealTypes', getRecipeMealTypes, {
    staleTime: Infinity,
  })

  console.log('useRecipeData filterTypeId:', filterTypeId)
  const recipesQuery = useQuery(['recipes', { type: filterTypeId }], () => getRecipes({ type: filterTypeId, ...(userMealPreference && { tags: userMealPreference }) }), {
    enabled: !!filterTypeId,
    staleTime: 60 * 60 * 1000, // 60 minutes
  })

  const { data: recipes, isLoading: recipesLoading } = recipesQuery
  console.log('useRecipeData recipesLoading:', recipesLoading)

  useEffect(() => {
    if (recipesLoading) return

    if (!type && recipeCategories?.length) {
      const newType = recipeCategories[0].title
      if (newType !== type) {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('type', newType)
        if (date) {
          searchParams.set('date', date as string)
        }
        history.replace({ search: searchParams.toString() })
      }
    }
  }, [recipesLoading, type, recipeCategories, history, date, location.search])

  return {
    recipeCategories,
    mealTypes,
    recipes,
    recipesLoading,
    filterTypeId,
  }
}
