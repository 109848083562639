import React from 'react';
import { SignUpContextProvider } from './SignUpContext';

import { SignUpView } from './SignUpView';

export const SignUp: React.FC = () => {
  return (
    <SignUpContextProvider>
      <SignUpView />
    </SignUpContextProvider>
  );
};
