import React, { useState } from 'react'
import { User } from 'types'
import styles from './components.module.scss'
import { PaymentMethod } from '@stripe/stripe-js'
import { useStripe, useElements, CardElement, CardNumberElement } from '@stripe/react-stripe-js'
import { Box, Button, Input } from 'shared/components'
import { SubscriptionOptionType } from 'types'
import { SubscriptionOption } from './SubscriptionOption'
import { CardPaymentForm } from 'features/SignUp/components/CardPaymentForm'
import * as StripeUtils from 'shared/functions/stripe'

type CardFormProps = {
  selectedOption: SubscriptionOptionType | null
  onCreateCard: (paymentMethod: PaymentMethod, name: string) => void
  user?: User
  loading: boolean
  showPromo?: boolean
  currentView?: string
}
export const CardForm: React.FC<CardFormProps> = ({ selectedOption, onCreateCard, user, loading, showPromo, currentView }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [name, setName] = useState(user ? `${user.firstName} ${user.lastName}` : '')
  const [error, setError] = useState<string>()
  const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setName(value)
  }

  const onSubmitPaymentDetails = async (postalCode: any, country: any) => {
    if (creatingPaymentMethod) {
      return
    }

    try {
      if (stripe && elements) {
        setCreatingPaymentMethod(true)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement)!,
          billing_details: {
            name,
            email: user?.email,
            address: {
              postal_code: postalCode,
              country: country,
            },
          },
        })

        if (error) {
          setError(error.message)
          setCreatingPaymentMethod(false)
        }

        if (paymentMethod) {
          onCreateCard(paymentMethod, name)
          // delaying loading so payment success loading has a chance to start
          setTimeout(() => {
            setCreatingPaymentMethod(false)
          }, 1000)
        }
      }
    } catch (e: any) {
      setError(e.message)
      setCreatingPaymentMethod(false)
    }
  }

  const isStaging = window.location.href.includes('staging') || window.location.href.includes('localhost')
  let details = null
  if (selectedOption) {
    details = StripeUtils.getDetails(selectedOption.type)
  }

  return (
    <Box className={styles.cardFormContainer}>
      {selectedOption && details && (
        <SubscriptionOption
          option={selectedOption}
          details={details}
          isActive={true}
          onClick={() => {
            //
          }}
          showPromo={showPromo}
        />
      )}
      <Input value={name} onChange={onChange} name="name" placeholder="Full Name" containerStyle={styles.input} />

      {isStaging && (
        <p className={styles.testMode}>
          TEST MODE-card-form: use a test card from the following{' '}
          <a target="_blank" rel="noreferrer" href="https://www.paypalobjects.com/en_AU/vhelp/paypalmanager_help/credit_card_numbers.htm">
            list
          </a>{' '}
          and any valid expiration, cvv and zipcode
        </p>
      )}

      {currentView === 'updatePayment' && (
        <CardPaymentForm onSubmitPaymentDetails={onSubmitPaymentDetails} creatingAccount={loading || creatingPaymentMethod} buttonLabel="Add Card" />
      )}
      {currentView === 'paymentForm' && <CardPaymentForm onSubmitPaymentDetails={onSubmitPaymentDetails} creatingAccount={loading || creatingPaymentMethod} />}
    </Box>
  )
}
