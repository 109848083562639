import React, { useEffect, useState, useRef, memo, useCallback, useMemo } from 'react'
import VideoJS from './VideoJS'
import videojs from 'video.js'
import { Workout } from 'types'
import { createCompletedWorkout } from 'services/api/completedWorkouts'
import { useQueryClient } from 'react-query'

interface Props {
  workout: Workout
  handleComplete?: () => void
  withMusic?: boolean
}

const submitCompletedWorkout = async (workout: Workout, handleComplete: () => void) => {
  const payload = {
    workout_id: workout.id,
    completed_date: new Date().toISOString(),
    challenge_workout_id: workout.challenge_workout?.id,
    user_challenge_id: workout.challenge_workout?.user_challenge_id,
    score: 0,
  }
  const response = await createCompletedWorkout(payload)
  console.log('response :>> ', response)
  if (response) {
    handleComplete()
  }
}

const noOp = () => {
  console.log('no op')
}

const VideoPlayer = memo(({ workout, withMusic = false, handleComplete = noOp }: Props) => {
  const queryClient = useQueryClient()
  const showWorkoutCompleteRef = useRef(false)
  const playerRef = useRef<videojs.Player | null>(null)

  if (!workout) return null

  const videoUrl = withMusic && workout?.signedVideoWithMusicUrl ? workout?.signedVideoWithMusicUrl : workout?.signedVideoUrl
  const videoJsOptions = {
    sources: [
      {
        src: videoUrl ?? '',
        type: 'application/x-mpegURL',
      },
    ],
  }

  const handlePlayerReady = useCallback(
    (player: videojs.Player) => {
      videojs.log('player HandlePlayerReady :>> ', player)
      playerRef.current = player

      player.on('waiting', () => {
        videojs.log('player is waiting :>> ', player.currentTime())
      })

      player.on('dispose', () => {
        videojs.log('player will dispose')
      })

      const checkCompletion = () => {
        const currentTime = player.currentTime()
        const duration = player.duration()
        if (currentTime && duration) {
          const percentageProgress = (currentTime / duration) * 100
          if (percentageProgress >= 50 && !showWorkoutCompleteRef.current) {
            showWorkoutCompleteRef.current = true
            videojs.log('setting workout as completed')
            // invalidate canAdvanceWeek query
            queryClient.invalidateQueries(['canAdvanceWeek', workout.challenge_workout?.user_challenge_id])

            submitCompletedWorkout(workout, handleComplete).then(() => {
              // showWorkoutCompleteRef.current = false // Reset to prevent multiple submissions
            })
          }
        }
      }

      player.on('timeupdate', checkCompletion)
      player.on('progress', checkCompletion)
    },
    [workout, handleComplete],
  )

  console.log(`%c videoUrl :>> `, 'color: red', videoUrl)

  const videoElement = useMemo(() => {
    return (
      <div className="relative w-full">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} title={workout.title} />
      </div>
    )
  }, [workout, videoUrl])

  return <>{videoElement}</>
})

VideoPlayer.displayName = 'VideoPlayer'
export { VideoPlayer }
