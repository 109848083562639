import React, { useCallback } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg'

export const Carousel: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
  })

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const childrenCount = React.Children.count(children)

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">{children}</div>
      </div>
      {childrenCount > 1 && (
        <button className="embla__button embla__button--prev" onClick={scrollPrev}>
          <ChevronLeft />
        </button>
      )}
      {childrenCount > 1 && (
        <button className="embla__button embla__button--next" onClick={scrollNext}>
          <ChevronLeft />
        </button>
      )}
    </div>
  )
}
