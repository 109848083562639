import { PaymentMethod } from '@stripe/stripe-js'
import { CarouselRef } from 'antd/lib/carousel'
import { AuthStateContext } from 'context'
import { NotyfContext } from 'context/NotyfContext'
import { buildSubscriptionsFromPrices } from 'features/Subscription/utils'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { PropsWithChildren, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { SubscriptionsAPI, PromoCodesAPI } from 'services/api'
import { CreateCustomerOptions, CreateSubscriptionOptions } from 'services/api/types'
import { FirebaseAuth } from 'services/firebase'
import { Validations } from 'shared/functions'

import ReactPixel from 'react-facebook-pixel'
import { firebase } from 'services/firebase'
import * as Stripe from '../../shared/functions/stripe'
import { usePromoCodes } from 'hooks/usePromoCodes'

type SubscriptionParams = {
  type?: 'update' | string
}

type CreateAccount = {
  email: string
  password: string
  fullName: string
}

const SignUp = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { type } = useParams<SubscriptionParams>()
  const signUpFormCarouselRef = React.useRef<CarouselRef>(null)
  const [isMobile, setIsMobile] = React.useState(false)
  const [selectedPlan, setSelectedPlan] = React.useState<string>('')
  const [userDetails, setUserDetails] = React.useState<CreateAccount>({} as CreateAccount)
  const [userDetailsError, setUserDetailsError] = React.useState<any>()
  const { state: authState } = useContext(AuthStateContext)
  const { setValue } = useLocalStorage('createAccount', '')
  const notyf = useContext(NotyfContext)
  const [creatingAccount, setCreatingAccount] = React.useState(false)
  const isStaging = window.location.href.includes('staging') || window.location.href.includes('localhost')
  const [promoCode, setPromoCode] = React.useState<string>('')

  const onSelectPlan = useCallback((plan: string) => {
    console.log('Plan Selected: ', plan)
    setSelectedPlan(plan)
    setPromoCodeOnPlanSelection(plan)
  }, [])

  const { data: prices, isLoading: pricesLoading } = useQuery('prices', SubscriptionsAPI.getSubscriptionPrices, {
    staleTime: Infinity, // prices wont change frequently
  })

  const subscriptionOptions = React.useMemo(() => buildSubscriptionsFromPrices(prices ?? undefined), [prices])
  console.log('subscriptionOptions', subscriptionOptions)
  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()

  const setNormalizedPromoCode = (code: SetStateAction<string>) => {
    let codeString = code.toString()
    codeString = codeString.trim()
    setPromoCode(codeString.toUpperCase())
  }

  const options = useMemo(() => {
    return subscriptionOptions.map((option) => {
      if (option.type === 'yearly') {
        return {
          ...option,
          ...Stripe.yearlyDetails,
        }
      }
      if (option.type === 'quarterly') {
        return {
          ...option,
          ...Stripe.quarterlyDetails,
        }
      }

      if (option.type === 'monthly') {
        return {
          ...option,
          ...Stripe.monthlyDetails,
        }
      }

      return {
        ...option,
        price: '0.00',
        weeklyBreakdown: '0.00',
        hasPromo: false,
        promo: undefined,
        promoPrice: '888.88',
        cycle: undefined,
      }
    })
  }, [subscriptionOptions])

  const onPressLogin = useCallback(() => {
    history.push('/login')
  }, [history])

  const setPromoCodeOnPlanSelection = (plan: string) => {
    // Disabled as there are no default codes at this time.
    // console.log(`selected Plan: ${plan}`)
    // const promoCodePlan = new Map()
    // promoCodePlan.set('monthly', 'NYL15')
    // promoCodePlan.set('quarterly', 'NYL45')
    // const promoCode = promoCodePlan.get(plan)
    // console.log(`found promo code: ${promoCode}`)
    // if (!promoCode) return
    // console.log(`setPromoCodeOnPlanSelection setting promo: ${promoCode}`)
    // setPromoCode(promoCode)
  }

  const goToNextAccessForm = useCallback(() => signUpFormCarouselRef.current?.next(), [])
  const goToPrevAccessForm = useCallback(() => signUpFormCarouselRef.current?.prev(), [])

  const onSubmitPlan = useCallback(() => {
    goToNextAccessForm()
  }, [])

  const onClickCreateAccount = async ({ email, password, fullName }: any) => {
    console.log('onClickCreateAccount', email, password, fullName, promoCode)
    const required = { email, password, fullName }
    const newErrors = Validations.validateForm(required)
    if (Object.keys(newErrors).length > 0) {
      console.log('newErrors', newErrors)
      setUserDetailsError(newErrors)
      return false
    }

    try {
      await FirebaseAuth.createFirebaseUserWithEmail({ email, password })
      // Update User with First and Last Name
      setValue({ fullName })
      await FirebaseAuth.updateProfile({ displayName: `${fullName}` })

      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('error', error.message)
      notyf.error(error.message)
      if (error.message.includes('The email address is already in use by another account')) {
        history.push('/subscription')
      }
      return false
    }
  }

  const validatePassword = (password = '') => {
    const isValid = Validations.validPassword(password, false)

    return isValid === ''
  }

  const onSubmitUserDetails = useCallback(async (userDetails: { email: string; terms: boolean; password: string; firstName: string; lastName: string }) => {
    setUserDetails({
      email: userDetails.email,
      password: userDetails.password,
      fullName: userDetails.firstName + ' ' + userDetails.lastName,
    })

    if (userDetails.terms && validatePassword(userDetails.password)) {
      goToNextAccessForm()
    }
  }, [])

  const onChangeCheckbox = (e: { target: { checked: any } }) => {
    console.log(`checked = ${e.target.checked}`)
  }

  const { mutateAsync: createCustomer } = useMutation((createOptions: CreateCustomerOptions) => {
    console.log('*** createOptions', createOptions)

    return SubscriptionsAPI.createStripeCustomer(createOptions)
  })

  const { mutate: createSubscription, isLoading: creatingSubscription } = useMutation(
    (createOptions: CreateSubscriptionOptions) => {
      console.log('*** createOptions', createOptions)
      createOptions.promoCode = promoCode
      return SubscriptionsAPI.createCustomerSubscription(createOptions)
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user')
        if (type && type === 'update') {
          history.goBack()
        } else {
          // Auth Context navigates to get-started based on auth state
          const { pathname } = location
          ReactPixel.trackCustom('WebSignupConversion')
          firebase.analytics().logEvent('web_sign_up', {
            content_type: selectedOption?.type,
          })
          if (pathname.includes('app-redirect')) {
            history.replace('/welcome-email')
          } else {
            history.replace('/get-started')
          }
        }
      },
      onError: (err: Error) => {
        notyf.error(err.message)
      },
    },
  )

  const selectedOption = options.find((option) => option.type === selectedPlan)

  const createCustomerSubscription = async (paymentMethod: PaymentMethod, name: string, promoCode?: string) => {
    const createCustomerResponse = await createCustomer({
      name: name,
      email: userDetails.email,
      metadata: {
        ...(authState.user && {
          userId: authState.user.id || '',
          firstName: authState.user.firstName || '',
          lastName: authState.user.lastName || '',
        }),
      },
    })

    const customerId = createCustomerResponse.customer_id

    if (selectedOption) {
      createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethod.id,
        priceId: selectedOption.id || '',
        metadata: {},
        promoCode: promoCode,
      })
    }
  }

  const onPaymentSuccess = async (paymentMethod: PaymentMethod, name: string, promoCode?: string) => {
    try {
      if (authState.user?.id) {
        createCustomerSubscription(paymentMethod, name, promoCode)
      } else {
        const accountCreated = await onClickCreateAccount(userDetails)

        if (accountCreated) {
          await createCustomerSubscription(paymentMethod, name, promoCode)
        }
      }
    } catch (e: unknown) {
      setCreatingAccount(false)
      console.log(e)
    }
  }

  return {
    creatingSubscription,
    options,
    selectedOption,
    onPressLogin,
    setIsMobile,
    isMobile,
    subscriptionOptions,
    selectedPlan,
    onSelectPlan,
    signUpFormCarouselRef,
    goToNextAccessForm,
    goToPrevAccessForm,
    onSubmitPlan,
    onSubmitUserDetails,
    prices,
    pricesLoading,
    onChangeCheckbox,
    isStaging,
    userDetails,
    onPaymentSuccess,
    creatingAccount,
    setCreatingAccount,
    promoCode,
    setPromoCode: setNormalizedPromoCode,
  }
}

type SignUpContextType = ReturnType<typeof SignUp>

const SignUpContext = React.createContext<SignUpContextType>({} as SignUpContextType)
SignUpContext.displayName = 'SignUpContext'

export const SignUpContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const value = SignUp()
  return <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>
}

export const useSignUpContext = () => React.useContext(SignUpContext)
