import React, { useCallback, useEffect, useState } from 'react'
import { useReportableUACs } from '../../hooks/useReportableUACs'
import { UserAchievementCriteriaWithRelatedData } from 'types'
import { ReportForYesterday } from './ReportForYesterday'

type Props = {
  yesterdayReportables: UserAchievementCriteriaWithRelatedData[]
}

const ReportList = ({ yesterdayReportables }: Props) => {
  console.log('Rending ReportList')
  const { refetchReportableUACs, setDoneReportingForYesterday } = useReportableUACs()
  const [indexToShow, setIndexToShow] = useState(0)

  const handleReportSubmission = useCallback(() => {
    setIndexToShow((prevIndex) => prevIndex + 1)
  }, [])

  const numberOfReportables = yesterdayReportables.length

  const uac = yesterdayReportables[indexToShow]

  useEffect(() => {
    if (indexToShow >= numberOfReportables) {
      console.log("marking yesterday's reportables as done")
      setDoneReportingForYesterday(true)
      refetchReportableUACs()
    }
  }, [indexToShow])

  if (indexToShow >= numberOfReportables) {
    return null
  }
  return (
    <div className="flex flex-col items-center justify-center w-full mb-4">
      <div className="space-x-2">
        <p className="font-bold text-slate-400">
          Track yesterday&apos;s achievements: {indexToShow + 1}/{numberOfReportables}
        </p>
      </div>
      <ReportForYesterday
        key={uac.id}
        achievement={uac.userAchievement.achievement}
        userAchievementCriteria={uac}
        achievementCriteria={uac.achievementCriteria}
        handleReportSubmission={handleReportSubmission}
      />
    </div>
  )
}

export { ReportList }
