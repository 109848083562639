import AdminInputField from './AdminInputField'

import React, { useState, useContext } from 'react'
import { AchievementCriteriaAPI } from '../../../services/api'
import { NotyfContext } from '../../../context/NotyfContext'
import AdminButton from './AdminButton'
import { AchievementCriteria } from '../../../types'
import { useQueryClient } from 'react-query'
import { useAchievements } from 'hooks/useAchievements'

type Props = {
  achievementCriteria: AchievementCriteria
  setEditAchievementCriteria: React.Dispatch<React.SetStateAction<boolean>>
}

function EditAchievementCriteria({ setEditAchievementCriteria, achievementCriteria }: Props) {
  const [target, setTarget] = useState<number>(achievementCriteria.target)
  const [displayText, setDisplayText] = useState(achievementCriteria.displayText)
  const [promptText, setPromptText] = useState(achievementCriteria.promptText)
  const [congratsText, setCongratsText] = useState(achievementCriteria.congratsText)
  const [derived, setDerived] = useState(achievementCriteria.derived)
  const [ruleId, setRuleId] = useState(achievementCriteria?.ruleId ?? '')
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const notyf = useContext(NotyfContext)
  const queryClient = useQueryClient()
  const { refreshAchievements } = useAchievements()

  const handleSubmit = async (event: React.FormEvent) => {
    setErrorMsg(null)
    event.preventDefault()

    //  confirm required fields are filled out
    if (!target || !promptText || !congratsText || (derived && !ruleId)) {
      if (derived && !ruleId) {
        setErrorMsg('Rule ID is required for derived achievements')
        return
      }

      setErrorMsg('Please fill out all required fields')

      return
    }

    // Prepare the data to be sent to the API
    const achievementCriteriaData = {
      id: achievementCriteria.id,
      achievementId: achievementCriteria.achievementId,
      target: Number(target),
      displayText,
      promptText,
      congratsText,
      derived,
      ruleId,
    }
    // Call the API service to create the new achievement
    try {
      console.log({ achievementCriteriaData })

      const result = await AchievementCriteriaAPI.update(achievementCriteriaData)
      if (result) {
        notyf.success('Achievement Criteria updated successfully')
        setEditAchievementCriteria(false)

        refreshAchievements()
        // queryClient.invalidateQueries('achievements')
      }
    } catch (error: unknown) {
      // the unhelpful helper is not passing the error message... hence...
      setErrorMsg('Error updating Achievement Criteria')
    }
  }

  return (
    <div className="">
      <h2 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900 flex  justify-center">Edit</h2>
      {/* Back Button */}
      <AdminButton
        type="button"
        label="Cancel Editing Criteria"
        onClick={() => {
          setEditAchievementCriteria(false)
        }}
      />
      <form onSubmit={handleSubmit} className="w-full max-w-lg mt-3 mx-auto">
        <AdminInputField label="Target" value={target} setter={setTarget} type="number" />
        <AdminInputField label="Display Text" value={displayText} setter={setDisplayText} type="text" />
        <AdminInputField label="Prompt Text" value={promptText} setter={setPromptText} type="text" />
        <AdminInputField label="Congrats Text" value={congratsText} setter={setCongratsText} type="text" />
        <div className="w-full px-3 mt-6 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-derived">
            Derived
          </label>
          <input className="mr-2 leading-tight" type="checkbox" id="grid-derived" checked={derived} onChange={(e) => setDerived(e.target.checked)} />
        </div>

        <AdminInputField label="Rule ID" value={ruleId} setter={setRuleId} type="text" />
        <div className="flex justify-end">
          <AdminButton type="submit" label="Submit" />
        </div>
        {errorMsg && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold mx-2">Oh snap!</strong>
            <span className="block sm:inline">Something went wrong. </span>
            <div className="mx-2">{errorMsg}</div>
          </div>
        )}
      </form>
    </div>
  )
}

export default EditAchievementCriteria
