import React, { useEffect, useState } from 'react';
import styles from './Resource.module.scss';
import { Box, LoadingIndicator } from 'shared/components';
import { Resource } from 'types';
import { useLocation, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { getResourceById } from 'services/api/resources';
import { ReactComponent as Together } from 'assets/icons/together.svg';
import ReactMarkdown from 'react-markdown';

type ResourceParams = {
  resourceId: string;
};

type ResourceState = {
  resource?: Resource;
};

export const ResourceOverview: React.FC = () => {
  const { state: locationState = {} } = useLocation<ResourceState>();
  const { resourceId = '' } = useParams<ResourceParams>();
  const [resource, setResource] = useState<Resource | undefined>(locationState.resource);
  const resourceQuery = useQuery(['resourceById', resourceId], () => getResourceById(resourceId), {
    enabled: resource === undefined,
  });
  const { data: resourceData, isLoading: resourceLoading } = resourceQuery;

  useEffect(() => {
    if (!resource && resourceData) {
      console.log('No Resource Data');
      setResource(resourceData);
    }
  }, [resource, resourceData]);

  if (!resource || resourceLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <p className={styles.title}>{resource.title}</p>
        <Box className={styles.banner}>
          <Together />
          <p>{resource.description}</p>
        </Box>
        {resource.sections.map((section) => {
          return (
            <Box key={section.id}>
              <p className={styles.subtitle}>{section.title}</p>
              <ReactMarkdown className={styles.body}>{section.body}</ReactMarkdown>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
