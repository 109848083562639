import { QuestionnaireAnswer } from 'types'

const requiredAnswer = 'meal-type'

export const hasRequiredQuestionnaire = (preferences?: QuestionnaireAnswer[]): boolean => {
  if (!preferences) {
    return false
  }

  if (!preferences.find((item) => item.preference_type === requiredAnswer)) {
    return false
  }

  return true
}
