import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Router } from 'router'
import { UserAchievementProvider, AuthStateProvider, UserChallengeProvider, UserAchievementCriteriaProvider, ChallengeProvider } from 'context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'notyf/notyf.min.css'
import ReactPixel from 'react-facebook-pixel'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

// Hide console logs in production. Does not strip logs from code.
if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_ENVIRONMENT !== 'development') {
  console.log = () => {
    //
  }
}

if (process.env.REACT_APP_FACEBOOK_PIXEL_ID && process.env.REACT_APP_ENVIRONMENT === 'production') {
  const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID
  ReactPixel.init(facebookPixelId)
  ReactPixel.pageView()
}

const SentrySetup: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_ENVIRONMENT,
        tracesSampleRate: 0.33,
      })
    }
  }, [])
  return null
}

interface ProvidersProps {
  children: React.ReactNode
}

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ChallengeProvider>
      <AuthStateProvider>
        <UserChallengeProvider>
          <UserAchievementCriteriaProvider>
            <UserAchievementProvider>{children}</UserAchievementProvider>
          </UserAchievementCriteriaProvider>
        </UserChallengeProvider>
      </AuthStateProvider>
    </ChallengeProvider>
  </QueryClientProvider>
)

const App: React.FC = () => {
  return (
    <>
      <SentrySetup />
      <Providers>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </Providers>
    </>
  )
}

export default App
