import { get, post } from './helpers';
import { CompletedWorkoutsResponse, CreateCompletedWorkoutOptions, CreateCompletedWorkoutResponse } from './types';

export const getCompletedWorkoutsByDate = async (start?: string, end?: string): Promise<CompletedWorkoutsResponse> => {
  const response = await get<CompletedWorkoutsResponse>(`workouts/completed?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}`);

  return response;
};

export const createCompletedWorkout = async (options: CreateCompletedWorkoutOptions): Promise<CreateCompletedWorkoutResponse> => {
  const response = await post<CreateCompletedWorkoutResponse>(`/workouts/completed`, options);
  return response;
};
