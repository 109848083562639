import React from 'react'

type Props = {
  isOpen: boolean
  text: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal: React.FC<Props> = ({ isOpen, text, onClose, onConfirm }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-4 rounded-md">
        <h3 className="text-lg font-medium">Are you sure?</h3>
        <p className="mt-2">{text}</p>
        <div className="flex justify-end mt-4 space-x-2">
          <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300" onClick={onClose}>
            Cancel
          </button>
          <button className="px-4 py-2 text-sm font-medium text-red-600 bg-red-100 rounded-md hover:bg-red-200" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
