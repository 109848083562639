import { useQuery } from 'react-query'
import AchievementsApi from '../services/api/achievements'

const useAchievements = () => {
  const { data, isLoading, error, refetch } = useQuery('achievements', AchievementsApi.getAll)

  const findById = (id: string) => {
    // console.log(` finding Achievement By Id ${id}  - data: ${JSON.stringify(data)} `)
    if (!data) return null
    const match = data.find((achievement) => achievement.id === id)

    return match
  }

  const getAvailabledAchievements = () => {
    if (!data) return null
    // Available Achievements are active and publishedDate is in the past

    const availableAchievements = data.filter((achievement) => {
      const today = new Date()
      const publishedDate = new Date(achievement.publishDate)
      const isAvailable = achievement.available
      return achievement.active && publishedDate < today && isAvailable
    })

    return availableAchievements
  }

  const refreshAchievements = () => {
    refetch()
  }

  const remove = (id: string) => {
    // remove via api
    if (id) {
      AchievementsApi.remove(id).then(() => {
        refetch()
      })
    }
  }
  return { data, isLoading, error, findById, refreshAchievements, getAvailabledAchievements, remove }
}

export { useAchievements }
