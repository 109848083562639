import React from 'react'
import { Achievement, UserAchievementCriteria, AchievementCriteria } from '../../types/index'
import { useUserAchievementCriteriaActions } from '../../hooks/useUserAchievementCriteria'
type Props = {
  achievement: Achievement
  userAchievementCriteria: UserAchievementCriteria
  achievementCriteria: AchievementCriteria
  handleReportSubmission: () => void
}

export const ReportForYesterday = ({ achievement, userAchievementCriteria, achievementCriteria, handleReportSubmission }: Props) => {
  const { noIncrementProgress, incrementProgress } = useUserAchievementCriteriaActions()
  const handleNoButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    noIncrementProgress(userAchievementCriteria.id!, new Date().toISOString(), true)
    handleReportSubmission()
  }

  const handleYesButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    incrementProgress(userAchievementCriteria.id!, new Date().toISOString(), true)
    handleReportSubmission()
  }

  return (
    <div className="flex flex-col items-center space-y-2 w-full   max-w-md  mx-auto border border-teal rounded-md ">
      <div
        className={`flex  py-0.5 sm:py-1 px-2 sm:px-4 mt-0.5 md:mt-1  mx-auto  
        overflow-hidden md:max-w-3xl space-y-1 sm:space-y-4 cursor-pointer`}
      >
        <img src={achievement.badgeImageUrl} alt="Achievement Badge" className={`w-16 sm:w-18 h-16 sm:h-18 mr-1 sm:mr-2    border border-slate-800 rounded-full`} />

        <div className="text-lg font-bold">{achievement.name}</div>
      </div>
      <div className="text-md   p-1 md:p-2">{achievementCriteria.displayText}</div>
      <div className="text-lg p-1 md:p-2">{achievementCriteria.promptText}</div>
      {/* Yes No buttons that take up the full width */}
      <div className="flex flex-col md:flex-row items-center justify-center w-full space-y-2 p-1  md:space-y-0 md:space-x-2 md:p-2">
        <button className="w-full sm:w-1/2 bg-teal text-white font-bold py-2 px-4 rounded-md shadow-md hover:shadow-lg hover:bg-teal-600" onClick={handleYesButtonClick}>
          Yes
        </button>
        <button className="w-full sm:w-1/2 bg-teal text-white font-bold py-2 px-4 rounded-md shadow-md hover:shadow-lg hover:bg-teal-600" onClick={handleNoButtonClick}>
          No
        </button>
      </div>
    </div>
  )
}
