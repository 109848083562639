import React from 'react'
import styles from './SignUp.module.scss'
import './SignUp.css'
import { Layout } from 'antd'
import { AccessForm } from './components/AccessForm'
import { Transformations } from './components/Transformations'
import { Header } from './components/Header'
import { useSignUpContext } from './SignUpContext'
import { inlineStylesProps } from './types'
import { Marketing } from './components/Marketing'
const { Sider, Content } = Layout

const inlineStyles: inlineStylesProps = {
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  body: {
    backgroundColor: 'white',
    marginTop: 64,
  },
}

export const SignUpView: React.FC = () => {
  const { onPressLogin, setIsMobile, isMobile } = useSignUpContext()
  return (
    <Layout className="min-h-screen  	  ">
      <Header onPressLogin={onPressLogin} />
      <Layout style={inlineStyles.body} className="">
        <Content style={inlineStyles.content} className={`${styles.content}   bg-contain  bg-sign-up-black bg-blend-multiply shadow-xl  `}>
          {/* <Content style={inlineStyles.content} className={`${styles.content}   bg-contain  bg-sign-up bg-blend-multiply shadow-xl  `}> */}
          {isMobile && (
            <>
              <Marketing />
              <div className="tranformation-wrapper mt-8  lg:mt-16 mb-12">
                <Transformations />
              </div>
              <div id="sign-up" className={styles.content__mobile}>
                <AccessForm name="access_form_mobile" />
              </div>
            </>
          )}
          <div className="flex flex-col ">
            {!isMobile && (
              <>
                <Marketing />
                <div className="tranformation-wrapper mt-8  lg:mt-16">
                  <Transformations />
                </div>
              </>
            )}
          </div>

          <p
            className={`${styles.disclaimer}  max-w-[475px] ${
              isMobile
                ? `text-slate-50 mt-2`
                : `text-slate-50 mt-2
            `
            } `}
          >
            This subscription renews automatically each period at full price. You must cancel at least 24 hours before the end of your current subscription period for the change to
            take effect. Cancel anytime.
          </p>
        </Content>
        <Sider
          width={600}
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed) => {
            setIsMobile(collapsed)
          }}
          trigger={null}
          className={styles.sider}
        >
          {!isMobile && (
            <div id="sign-up" className={styles.sider__container}>
              <AccessForm name="access_form_desktop" />
            </div>
          )}
        </Sider>
      </Layout>
    </Layout>
  )
}
