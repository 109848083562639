import React from 'react'
import { RouteType } from '../../router/routes'
import styles from './SideNavigation.module.scss'

import { NavLink, useRouteMatch } from 'react-router-dom'
import { isFlexChallenge } from 'shared/functions/utils'

type SideNavLinkProps = {
  route: RouteType
  onClick: () => void
}

const SideNavLink: React.FC<SideNavLinkProps> = ({ route, onClick }) => {
  const { icon: Icon, name, path } = route
  const matches = useRouteMatch(path)
  const isActive = !!matches
  const badFill = path === '/profile' || path === '/challenges' || path === '/today' || path === '/on-demand'
  //   const badFill = false

  return (
    <div className="flex w-full">
      <NavLink
        key={path}
        to={path}
        onClick={onClick}
        activeClassName="font-bold w-full bg-[#6bbed1] hover:bg-[#3da3ba] text-white"
        isActive={() => !!matches}
        className="flex flex-row gap-2 mt-3 items-center flex-shrink-0 text-gray-500 p-2 text-sm rounded-lg hover:bg-[#d3ecf1] hover:text-gray-500 w-full"
      >
        {Icon ? (
          <Icon
            className={`ml-5 ${badFill && !isActive ? ' fill-gray-500' : ''} ${badFill && isActive ? ' fill-white' : ''} ${
              isActive ? 'stroke-white  text-white  ' : 'stroke-gray-500 '
            }`}
          />
        ) : null}
        {name}
      </NavLink>
    </div>
  )
}
export { SideNavLink }
