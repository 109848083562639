import axios, { AxiosError } from 'axios'
import { firebase } from 'services/firebase'
import { setupInterceptorsTo } from './interceptors'
import { API_URL } from './config'
import { ApiPatchOptions, ApiPostOptions } from './types'
const { auth } = firebase

const getBaseUrl = (): string => {
  return API_URL
}

setupInterceptorsTo(axios)

export class RequestError {
  status: number
  message: string
  constructor(status: number, message: string) {
    this.status = status
    this.message = message
  }
}

export const getToken = (): Promise<string> | string => {
  const currentUser = auth().currentUser
  if (currentUser) {
    return currentUser.getIdToken()
  }
  return ''
}

const handleRequestError = (error: AxiosError) => {
  if (error.response) {
    const { data } = error.response
    const { statusCode, message } = data as { statusCode: number; message: string }
    throw new RequestError(statusCode, message)
  } else if (error.request) {
    console.log('error.request :>> ', error.request)
    throw new RequestError(500, 'Request Failed!')
  } else {
    throw new RequestError(500, 'Something went wrong!')
  }
}

export const post = async <T>(url: string, data: ApiPostOptions): Promise<T> => {
  const token = await getToken()
  try {
    const response = await axios.post<T>(url, data, {
      baseURL: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return handleRequestError(error as AxiosError)
  }
}

// Updated get function to accept optional params
export const get = async <T>(url: string, params?: any): Promise<T> => {
  const token = await getToken()
  try {
    const response = await axios.get<T>(url, {
      baseURL: getBaseUrl(),
      headers: {
        Authorization: token,
      },
      params, // Pass the params to the axios config object
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return handleRequestError(error as AxiosError)
  }
}

export const del = async <T>(url: string, data?: ApiPostOptions): Promise<T> => {
  const token = await getToken()
  try {
    const response = await axios.delete<T>(url, {
      baseURL: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data,
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return handleRequestError(error as AxiosError)
  }
}

export const patch = async <T>(url: string, data: ApiPatchOptions): Promise<T> => {
  const token = await getToken()
  try {
    const response = await axios.patch<T>(url, data, {
      baseURL: getBaseUrl(),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return handleRequestError(error as AxiosError)
  }
}
