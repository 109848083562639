import { NotyfContext } from 'context/NotyfContext'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { PreferencesAPI, QuestionnaireAPI } from 'services/api'
import { Box, Button, LoadingIndicator, SelectOption } from 'shared/components'
import styles from './Preferences.module.scss'

type PreferencesParams = {
  preferenceType: string
}
export const Preferences: React.FC = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { preferenceType = '' } = useParams<PreferencesParams>()
  const notyf = useContext(NotyfContext)
  const { data: preferenceQuestion, isLoading } = useQuery(`preference-${preferenceType}`, () => QuestionnaireAPI.getQuestionByPreferenceType(preferenceType), {
    enabled: !!preferenceType,
  })
  const { data: preferenceAnswersData } = useQuery('preferenceAnswers', PreferencesAPI.getPreferenceAnswers)
  const questionAnswer = preferenceAnswersData ? preferenceAnswersData.find((i) => i.preference_type === preferenceType) : undefined

  const [selected, setSelected] = useState(questionAnswer ? preferenceQuestion?.options.findIndex((option) => option.id === questionAnswer.option_id) : undefined)

  useEffect(() => {
    if (preferenceQuestion && questionAnswer && selected === undefined) {
      setSelected(preferenceQuestion.options.findIndex((option) => option.id === questionAnswer.option_id))
    }
  }, [selected, questionAnswer, preferenceQuestion])

  const [saveLoading, setSaveLoading] = useState(false)

  const onClickSave = async () => {
    if (selected === undefined || !preferenceQuestion) {
      return
    }
    setSaveLoading(true)
    try {
      // Save
      if (questionAnswer) {
        await QuestionnaireAPI.updateUserAnswer(questionAnswer.id, {
          option_id: preferenceQuestion.options[selected].id,
          record: {
            question: preferenceQuestion.question,
            answer: preferenceQuestion.options[selected].title,
          },
          preference_type: preferenceQuestion.preference_type,
        })
      } else {
        await QuestionnaireAPI.createUserAnswer({
          questionnaire_title: 'onboarding',
          question_id: preferenceQuestion?.id,
          option_id: preferenceQuestion.options[selected].id,
          record: {
            question: preferenceQuestion.question,
            answer: preferenceQuestion.options[selected].title,
          },
          preference_type: preferenceQuestion.preference_type,
        })
      }
      setSaveLoading(false)
      notyf.success('Updated successfully!')
      history.goBack()
      queryClient.invalidateQueries('preferenceAnswers')
      queryClient.refetchQueries('user')
      if (preferenceQuestion.preference_type && preferenceQuestion.preference_type === 'meal-type') {
        queryClient.invalidateQueries('recipes')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setSaveLoading(false)
      console.log('error :>> ', error)
      notyf.error(error.message)
    }
  }

  if (isLoading) {
    return <LoadingIndicator />
  }
  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <p className={styles.headline}>{preferenceQuestion?.question}</p>
        <p className={styles.subHeading}>{preferenceQuestion?.description}</p>
        <Box className={styles.optionsContainer}>
          {(preferenceQuestion?.options || []).map((option, index) => {
            const isSelected = selected === index
            return (
              <SelectOption
                key={option.title}
                title={option.title}
                isSelected={isSelected}
                icon={option.icon}
                description={option.description}
                onClick={() => setSelected(index)}
              />
            )
          })}
        </Box>
        <Button onClick={onClickSave} containerStyle={styles.nextBtn} label="Save" disabled={selected === undefined} loading={saveLoading} />
      </Box>
    </Box>
  )
}
