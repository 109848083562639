import { Price, UserSubscription } from 'types'
import { get, post } from './helpers'
import {
  CreateCustomerOptions,
  CreateCustomerResponse,
  CreateAndUpdateSubscriptionResponse,
  CreateSubscriptionOptions,
  UpdateSubscriptionOptions,
  UpdateCustomerOptions,
  UpdateCustomerResponse,
} from './types'

export const getSubscriptionPrices = async (): Promise<Price[]> => {
  const response = await get<Price[]>(`/subscriptions-prices`)
  return response
}

export const createStripeCustomer = async (options: CreateCustomerOptions): Promise<CreateCustomerResponse> => {
  const response = await post<CreateCustomerResponse>('/subscriptions-customers', options)
  return response
}

export const updateStripeCustomer = async (options: UpdateCustomerOptions): Promise<UpdateCustomerResponse> => {
  const response = await post<UpdateCustomerResponse>('/update-customer', options)
  return response
}

export const createCustomerSubscription = async (options: CreateSubscriptionOptions): Promise<CreateAndUpdateSubscriptionResponse> => {
  console.log('**** createCustomerSubscription', { options })
  const response = await post<CreateAndUpdateSubscriptionResponse>('/subscriptions', options)
  return response
}

export const updateStripeSubscription = async (options: UpdateSubscriptionOptions): Promise<CreateAndUpdateSubscriptionResponse> => {
  const response = await post<CreateAndUpdateSubscriptionResponse>('/update-subscription', options)
  return response
}

export const cancelStripeSubscription = async (options: UpdateSubscriptionOptions): Promise<CreateAndUpdateSubscriptionResponse> => {
  const response = await post<CreateAndUpdateSubscriptionResponse>('/cancel-subscription', options)
  return response
}

// get subscription by user id
export const getSubscriptionByUserId = async (userId: string): Promise<UserSubscription> => {
  if (userId === undefined) return Promise.reject('userId is undefined')
  console.log('API SUBSCRIPTION REQUESTS', userId)

  const response = await get<UserSubscription>(`/user/${userId}/subscription`)
  console.log('API SUBSCRIPTION RESPONSES', { response })
  return response
}
