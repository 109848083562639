import React from 'react'

type Props = {
  children: React.ReactNode
  title?: string
  className?: string
}

export const FlexWeekWorkouts: React.FC<Props> = ({ title, children, className = '' }: Props) => {
  return (
    <div className={`mt-4 ${className}`}>
      {title && <h3 className={`font-TrimPoster text-2xl uppercase `}>{title}</h3>}
      <div className="mt-4 flex  gap-8 overflow-y-hidden scrollbar overflow-x-scroll snap-x snap-mandatory">{children}</div>
    </div>
  )
}
