import { UserAchievement } from '../types'
import { UserAchievementCriteria } from '../types/index'

const calculateAchievementCriteriaProgress = (userAchievementCriteriaValue: number, achievementCriteriaTarget: number): number => {
  // userAchievementCriteria.value / AchievementCriteria target
  let progress = Math.round((userAchievementCriteriaValue * 10000) / achievementCriteriaTarget) / 100

  // cap at 100

  if (progress > 100) {
    progress = 100
  }

  return progress
}

export default calculateAchievementCriteriaProgress
