export const getContentDivScroll = (): number => {
  if (document) {
    const contentElem = document.getElementById('layout-content')
    if (contentElem) {
      return contentElem.scrollTop
    }
  }
  return 0
}

export const scrollToTopContent = (): void => {
  const contentElem = document.getElementById('layout-content')
  if (contentElem) {
    contentElem.scrollTo(0, 0)
  }
}
