import React from 'react'

type Props = {
  children: React.ReactNode
  active: boolean
  className?: string
}

const BluePill = ({ active, className, children }: Props) => {
  return (
    <div
      className={`${
        active ? 'bg-white text-teal border-cyan-600 border-2' : 'bg-teal text-white border-cyan-700 border-2'
      } py-1 px-2 rounded-lg font-bold mb-2 italic shadow-md shadow-cyan-900 w-fit text-center ${className}`}
    >
      {children}
    </div>
  )
}

export default BluePill
