import React from 'react';
import styles from './components.module.scss';
import { Box, Button, Modal } from 'shared/components';

type ModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

export const CancelModal: React.FC<ModalProps> = ({ onClose, onConfirm, isLoading }) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <p className={styles.modalTitle}>Are you sure you would like to cancel your subscription?</p>
      <Box className={styles.modalActionBtns}>
        <Button label="Cancel" onClick={onClose} containerStyle={styles.cancelBtn} />
        <Button label="Cancel Subscription" onClick={onConfirm} containerStyle={styles.confirmCancelBtn} loading={isLoading} />
      </Box>
    </Modal>
  );
};

export const ConfirmUpdateModal: React.FC<ModalProps> = ({ onClose, onConfirm, isLoading }) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <p className={styles.modalTitle}>Are you sure you would like to update your subscription?</p>
      <Box className={styles.modalActionBtns}>
        <Button label="Cancel" onClick={onClose} containerStyle={styles.cancelBtn} />
        <Button label="Update Subscription" onClick={onConfirm} containerStyle={styles.confirmUpdateBtn} loading={isLoading} />
      </Box>
    </Modal>
  );
};
