import { useEffect, useRef } from 'react';
import { useLocalStorage } from './useLocalStorage';

// sets scrollY position of window based on a setting condition, i.e. when api calls are done
// also sets the scroll position when unmounting, i.e. a user navigates to a different page

type ScrollPositionHookType = {
  setScrollYStorage: (n: number) => void;
};

const useScrollPosition = (localStorageKey: string, setCondition: boolean): ScrollPositionHookType => {
  const { storedValue: scrollYStorage, setValue: setScrollYStorage } = useLocalStorage(localStorageKey, 0);

  const alreadyRan = useRef(false);

  useEffect(() => {
    // if the setcondition is true (AKA everything in the DOM is loaded: fire off the scrollTo()!)
    if (setCondition && !alreadyRan.current) {
      console.log('scrollingTo :>> ', scrollYStorage);
      const contentElem = document.getElementById('layout-content');
      if (contentElem) {
        setTimeout(() => {
          contentElem.scrollTo({ left: 0, top: Number(scrollYStorage), behavior: 'smooth' });
          setScrollYStorage(0);
          alreadyRan.current = true;
        }, 50);
      }
    }
  }, [setCondition, scrollYStorage]);

  return { setScrollYStorage };
};

export default useScrollPosition;
