import { Discount, SubscriptionOptionType } from 'types'

import React, { useContext, useEffect, useState, useMemo } from 'react'
import { SubscriptionOption } from './components/SubscriptionOption'

import * as StripeUtils from 'shared/functions/stripe'
import { AuthStateContext } from 'context'

type SubscriptionOptionListProps = {
  subscriptionOptions: SubscriptionOptionType[]
  selectedOption: SubscriptionOptionType | undefined
  showPromos: boolean
  onClickOption: (option: SubscriptionOptionType) => void
  discountToDisplay?: Discount
}

export function SubscriptionOptionList({ subscriptionOptions, selectedOption, showPromos, onClickOption, discountToDisplay }: SubscriptionOptionListProps) {
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState
  const [monthlyPromo, setMonthlyPromo] = useState<string | undefined>(undefined)
  const [quarterlyPromo, setQuarterlyPromo] = useState<string | undefined>(undefined)
  const [yearlyPromo, setYearlyPromo] = useState<string | undefined>(undefined)

  useEffect(() => {
    // determine the best monthly promo but not if a discount is already applied
    if (user && !discountToDisplay) {
      StripeUtils.calculateBestPromo(StripeUtils.monthlyPromoList, user.id).then((promo) => {
        if (promo) setMonthlyPromo(promo)
      })

      // determine the best quarterly promo
      StripeUtils.calculateBestPromo(StripeUtils.quarterlyPromoList, user.id).then((promo) => {
        if (promo) setQuarterlyPromo(promo)
      })

      // determine the best yearly promo
      StripeUtils.calculateBestPromo(StripeUtils.yearlyPromoList, user.id).then((promo) => {
        if (promo) setYearlyPromo(promo)
      })
    }
  }, [user, discountToDisplay])

  const monthlyOption = subscriptionOptions.find((option) => option.type === 'monthly')
  const quarterlyOption = subscriptionOptions.find((option) => option.type === 'quarterly')
  const yearlyOption = subscriptionOptions.find((option) => option.type === 'yearly')

  if (!monthlyOption || !quarterlyOption || !yearlyOption) {
    // log an error and return null
    console.error('SubscriptionOptionList: missing subscription option')
    return null
  }

  const hasAnEligiblePromo = useMemo(() => {
    return monthlyPromo || quarterlyPromo || yearlyPromo
  }, [monthlyPromo, quarterlyPromo, yearlyPromo])

  const monthlyDetails = StripeUtils.getDetails(monthlyPromo ?? 'monthly')
  const quarterlyDetails = StripeUtils.getDetails(quarterlyPromo ?? 'quarterly')
  const yearlyDetails = StripeUtils.getDetails(yearlyPromo ?? 'yearly')

  const isActive = (option: SubscriptionOptionType) => {
    return selectedOption?.type === option.type
  }

  return (
    <div className="space-y-4 mt-8">
      {hasAnEligiblePromo && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <div className="w-4 h-4 rounded-full bg-green-500" />
            <div className="text-sm text-gray-500">{`You're eligible for a discount!`}</div>
          </div>
        </div>
      )}
      <SubscriptionOption
        key={'subMonthlyOption'}
        showPromo={showPromos}
        isPromo={!!monthlyPromo}
        details={monthlyDetails}
        option={monthlyOption}
        isActive={isActive(monthlyOption)}
        onClick={onClickOption}
        isDiscounted={discountToDisplay?.targetPlan === 'monthly'}
        discountToDisplay={discountToDisplay?.targetPlan === 'monthly' ? discountToDisplay : undefined}
      />
      <SubscriptionOption
        key={'subQuarterlyOption'}
        showPromo={showPromos}
        isPromo={!!quarterlyPromo}
        details={quarterlyDetails}
        option={quarterlyOption}
        isActive={isActive(quarterlyOption)}
        onClick={onClickOption}
        isDiscounted={discountToDisplay?.targetPlan === 'quarterly'}
        discountToDisplay={discountToDisplay?.targetPlan === 'quarterly' ? discountToDisplay : undefined}
      />

      <SubscriptionOption
        key={'subYearlyOption'}
        showPromo={showPromos}
        isPromo={!!yearlyPromo}
        details={yearlyDetails}
        option={yearlyOption}
        isActive={isActive(yearlyOption)}
        onClick={onClickOption}
        isDiscounted={discountToDisplay?.targetPlan === 'yearly'}
        discountToDisplay={discountToDisplay?.targetPlan === 'yearly' ? discountToDisplay : undefined}
      />
    </div>
  )
}
