import React from 'react'
import { Switch } from '@headlessui/react'

type Props = {
  showPromoContactUs: boolean
  handleSetPromoContactUs: () => void
  classNames: (arg0: string, arg1: string) => string
}

export function ShowPromoCodeSetting({ showPromoContactUs, handleSetPromoContactUs, classNames }: Props) {
  return (
    <section id="showPromoContactUs">
      <Switch.Group as="div" className="flex items-center justify-between">
        <span className="flex flex-grow flex-col">
          <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
            Show Promo Contact Us
          </Switch.Label>
          <Switch.Description as="span" className="text-sm text-gray-500">
            Show the text blurb that informs Squadies to Contact us if they saw a Promo on Social Media.
          </Switch.Description>
        </span>
        <Switch
          checked={showPromoContactUs}
          onChange={handleSetPromoContactUs}
          className={classNames(
            showPromoContactUs ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              showPromoContactUs ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </Switch.Group>
    </section>
  )
}
