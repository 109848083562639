import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'

export const Transformations = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [userSelectedIndex, setUserSelectedIndex] = useState(false)
  const slides = [
    'images/transformations/transform6.jpg',
    'images/transformations/transform4.jpg',
    'images/transformations/transform2.jpg',
    'images/transformations/transform3.jpg',
    'images/transformations/transform5.jpg',
  ] // Make sure the number of slides matches the number of images

  let interval: string | number | NodeJS.Timeout | undefined
  useEffect(() => {
    interval = setInterval(() => {
      if (userSelectedIndex) {
        clearInterval(interval)
        return
      }
      setSelectedIndex((prevIndex) => (prevIndex + 1) % slides.length)
    }, 3000) // Change slide every 3 seconds

    return () => clearInterval(interval)
  }, [slides.length, userSelectedIndex]) // Only re-run the effect if slides.length changes

  const handleSlideChange = (index: number) => {
    setUserSelectedIndex(true)
    setSelectedIndex(index)
  }

  return (
    <div className="">
      <div className="relative h-128 lg:h-256 mt-24">
        <div className="overflow-hidden h-64 ">
          {slides.map((slide, index) => (
            <Transition
              show={index === selectedIndex}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={` absolute flex items-center justify-center w-full h-64 lg:h-128`}
              key={slide}
            >
              <div className="aspect-[1000/950] max-w-[90%] mx-auto lg:h-96 rounded-xl shadow-lg overflow-hidden">
                <img className="aspect-content object-cover" src={slides[index]} />
              </div>
            </Transition>
          ))}
        </div>
        <div className="flex justify-center space-x-2 mt-32">
          {slides.map((_, index) => (
            <img
              className={`w-12 h-12 lg:w-24 lg:h-24 cursor-pointer ${index === selectedIndex ? 'opacity-100  ring-2 ring-red-400' : 'opacity-50 hover:opacity-80'}`}
              src={slides[index]}
              alt={`Slide ${index}`}
              onClick={() => handleSlideChange(index)}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
