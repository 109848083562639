import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';

const environment = process.env.REACT_APP_ENVIRONMENT;

const prodConfig = {
  apiKey: 'AIzaSyByOU0n4EGGQwWr7VptLlGWxbvg3ZBq15c',
  authDomain: 'leansquad-app.firebaseapp.com',
  projectId: 'leansquad-app',
  storageBucket: 'leansquad-app.appspot.com',
  messagingSenderId: '547833918612',
  appId: '1:547833918612:web:2da20f2eab4f854a2b7081',
  measurementId: 'G-SPGXERF9CZ',
};

const stagingConfig = {
  apiKey: 'AIzaSyD_yd4H41Va4mHHf1S6tGhqKoXcRU9bWs8',
  authDomain: 'leansquad-staging.firebaseapp.com',
  projectId: 'leansquad-staging',
  storageBucket: 'leansquad-staging.appspot.com',
  messagingSenderId: '255736339060',
  appId: '1:255736339060:web:ed12ddda5cad3f58d20f21',
  measurementId: 'G-XBDKBDYVJ8',
};

firebase.initializeApp(environment === 'production' ? prodConfig : stagingConfig);
firebase.auth();
if (process.env.NODE_ENV === 'production') {
  firebase.analytics();
}

export default firebase;
