import React, { useContext, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { Box, LoadingIndicator } from 'shared/components';
import styles from './TransformationPhotos.module.scss';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TransformationPhotosAPI } from 'services/api';
import { CreateTransformationPhotoOptions } from 'services/api/types';
import { FirebaseStorage } from 'services/firebase';
import { AuthStateContext } from 'context';
import { NotyfContext } from 'context/NotyfContext';

export const TransformationPhotos: React.FC = () => {
  const queryClient = useQueryClient();
  const { state: authState } = useContext(AuthStateContext);
  const notyf = useContext(NotyfContext);
  const { data: transformationPhotosData, isLoading } = useQuery(
    'transformationPhotos',
    TransformationPhotosAPI.getUserTransformationPhotos,
  );
  const { mutate: createPhotoMutation, isLoading: createLoading } = useMutation(
    (options: CreateTransformationPhotoOptions) => TransformationPhotosAPI.createTransformationPhoto(options),
    {
      onSuccess: () => {
        notyf.success('Successfully added new photo!');
        queryClient.invalidateQueries('transformationPhotos');
      },
      onError: (err: Error) => {
        notyf.error(err.message);
      },
    },
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { openLightbox } = useLightbox();

  const onClickAddNewPhoto = () => {
    console.log('onClickAddNewPhoto :>> ', fileInputRef.current);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('res :>> ', e.target.files);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const downloadUrl = await FirebaseStorage.uploadPicture(
          authState.user?.id || '',
          'transformations',
          `${authState.user?.id || ''}-${new Date().toISOString()}`,
          file,
        );
        console.log('downloadUrl :>> ', downloadUrl);
        createPhotoMutation({ photo_url: downloadUrl });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('onFileSelected:error :>> ', error);
      }
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <p className={styles.pageTitle}>Transformation Photos</p>
        <input type="file" ref={fileInputRef} onChange={onFileSelected} hidden />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Box>
            <SRLWrapper elements={transformationPhotosData?.allPhotos}>
              <></>
            </SRLWrapper>
            {transformationPhotosData &&
              transformationPhotosData.photosByDate &&
              Object.keys(transformationPhotosData.photosByDate).map((key, index) => {
                const photosByDate = transformationPhotosData.photosByDate[key];

                return (
                  <Box key={key} className={styles.dayContainer}>
                    <p className={styles.dayTitle}>{key}</p>
                    <Box className={styles.photoRow}>
                      {photosByDate.map((item) => {
                        const indexOfPhoto = transformationPhotosData.allPhotos.findIndex((i) => item.photo_url === i.src);
                        return (
                          <img
                            onClick={() => openLightbox(indexOfPhoto)}
                            key={item.id}
                            className={styles.photoContainer}
                            src={item.photo_url}
                          />
                        );
                      })}
                      {index === 0 && (
                        <button onClick={onClickAddNewPhoto} className={styles.uploadContainer}>
                          {createLoading ? (
                            <LoadingIndicator />
                          ) : (
                            <>
                              <PlusIcon />
                              <p>Add new progress photo</p>
                            </>
                          )}
                        </button>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
