import { WorkoutType } from 'types';
import { ReactComponent as Favorites } from 'assets/icons/favorites.svg';

export const FavoritesTypeOption: WorkoutType = {
  id: 'favorite',
  title: 'Favorites',
  description: '',
  slug: 'favorites',
  icon: Favorites,
  image: '',
};

export const AllTypeOption: WorkoutType = {
  id: 'all',
  title: 'All',
  description: '',
  slug: 'all',
  icon: '',
  image: '',
};

export const filterCategoriesForIconsOnly = (data: WorkoutType[] = []): WorkoutType[] => {
  const res = data.filter((item) => item.icon);
  return [...res, FavoritesTypeOption, AllTypeOption];
};
