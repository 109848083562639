import React from 'react'
import { Box } from '../Box/Box'
import styles from './Input.module.scss'

interface Props {
  value: string
  name?: string
  onChange: React.ChangeEventHandler
  error?: string
  type?: 'text' | 'password'
  containerStyle?: string
  inputStyle?: string
  placeholder?: string
  onKeyDown?: React.KeyboardEventHandler
  disabled?: boolean
}

export const Input: React.FC<Props> = ({ value, name, onChange, error, type, containerStyle = '', inputStyle = '', placeholder, onKeyDown, disabled = false }) => {
  return (
    <Box className={`${styles.container} ${containerStyle}`}>
      <input
        className={`${styles.input} ${inputStyle}`}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {error && <p className="errorText">{error}</p>}
    </Box>
  )
}
