import React from 'react'
import styles from './ProfilePicture.module.scss'

type ProfilePictureProps = {
  firstName: string
  lastName: string
  imageUrl?: string
  onClick?: () => void
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ firstName, lastName, imageUrl, onClick }) => {
  return (
    <button className={`${styles.container} ${!imageUrl ? styles.noImage : ''}`} onClick={onClick}>
      {imageUrl ? <img src={imageUrl} /> : <p>{`${firstName.length > 0 && firstName[0]}${lastName.length > 0 && lastName[0]}`}</p>}
    </button>
  )
}
