import { AuthStateContext } from 'context'
import React, { useContext, useMemo } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import { Box, Button } from 'shared/components'
import { Workout } from 'types'
import styles from './WorkoutComplete.module.scss'
import { ReactComponent as WorkoutCompleteImg } from 'assets/images/workout-complete.svg'
// import { ReactComponent as Flame } from 'assets/icons/flame.svg';
import { LeanStreak } from 'features/LeanStreak/LeanStreak'
import { dayjs } from 'shared/functions'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { isFlexChallenge } from 'shared/functions/utils'

type WorkoutCompleteLocationState = {
  workout?: Workout
  score?: number
}

export const WorkoutComplete: React.FC = () => {
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState
  const history = useHistory()
  const { state: authState } = useContext(AuthStateContext)
  const { state: locationState = {} } = useLocation<WorkoutCompleteLocationState>()
  const { workout, score } = locationState
  const isInFlexChallenge = useMemo(() => {
    return !!activeChallenge && isFlexChallenge(activeChallenge.challenge)
  }, [activeChallenge])

  const onClickDone = () => {
    if (isInFlexChallenge) {
      history.push('/today')
    } else {
      history.push('/planner')
    }
  }

  if (!workout || score === undefined) {
    return <Redirect to="/" />
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <WorkoutCompleteImg height={234} width={234} />
        <p className={styles.headerTitle}>{`Great Workout ${authState.user?.firstName}!`}</p>
        <p className={styles.headerSubTitle}>{`${workout.title} • ${dayjs().format('llll')}`}</p>

        {/* <Box className={styles.leanScoreBox}>
          <Flame />
          <p className={styles.score}>{score}</p>
          <p className={styles.description}>Lean Score</p>
        </Box> */}
        <p className={styles.contentTitle}>LEAN STREAK</p>
        <LeanStreak size="lg" />
        <Button containerStyle={styles.doneBtn} label="Done" onClick={onClickDone} />
      </Box>
    </Box>
  )
}
