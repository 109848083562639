import { ActiveChallenge, Challenge, ChallengeCategory, ChallengeMap, Series, Workout } from 'types'
import { get, post, patch } from './helpers'
import {
  ActiveChallengeResponse,
  ActiveChallengeWorkoutResponse,
  ChallengeResponse,
  ChallengesResponse,
  CreateUserChallengeOptions,
  UpdateChallengeResponse,
  UpdateUserChallengeOptions,
} from './types'

export const getAllChallenges = async (): Promise<Challenge[]> => {
  //const response = await get<ChallengesResponse>(`/challenges?_categories=body weight,cardio,strength,flexibility`)
  const response = await get<ChallengesResponse>(`/challenges`)
  return response
}

export const getChallengeById = async (challengeId: string): Promise<Challenge> => {
  const response = await get<ChallengeResponse>(`/challenges/${challengeId}`)

  return response
}

export const getUserActiveChallenge = async (): Promise<ActiveChallenge> => {
  const response = await get<ActiveChallengeResponse>(`/user/challenges/active`)

  return response
}

export const startUserChallenge = async (data: CreateUserChallengeOptions): Promise<ActiveChallenge> => {
  const response = await post<ActiveChallengeResponse>(`/user/challenges`, data)

  return response
}
export const startFlexUserChallenge = async (data: CreateUserChallengeOptions): Promise<ActiveChallenge> => {
  console.log('data in startFlex', data)
  const response = await post<ActiveChallengeResponse>(`/user/challenges/flex`, data)

  return response
}

export const getAllUserChallengeWorkouts = async (start?: string, end?: string, userChallengeId?: string): Promise<Workout[]> => {
  const response = await get<ActiveChallengeWorkoutResponse>(
    `user/challenges/workouts?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}${userChallengeId ? `&user_challenge_id=${userChallengeId}` : ''}`,
  )

  return response
}

export const getChallengeWorkoutByChallengeWorkoutId = async (challengeWorkoutId?: string): Promise<Workout> => {
  const response = await get<Workout>(`user/challenges/workouts/${challengeWorkoutId}`)

  return response
}

export const getUserChallengeId = async (challengeWorkoutId?: string): Promise<any> => {
  const response = await get<Workout>(`user/challenges/workouts/${challengeWorkoutId}`)

  return response
}

// Get Active Challenge by userId
export const getActiveChallengeByUserId = async (userId: string): Promise<any> => {
  const response = await get<any>(`/admin/user/challenges/active?user_id=${userId}`)
  return response
}
export const getChallengeMap = async (): Promise<ChallengeMap> => {
  // Assuming Challenge type is defined elsewhere with all necessary properties
  const challengesResponse: Challenge[] = await getAllChallenges()
  const challengeMap: ChallengeMap = challengesResponse.reduce<ChallengeMap>((acc, challenge) => {
    acc[challenge.title] = challenge
    return acc
  }, {})
  return challengeMap
}

export const updateUserChallenge = async (userChallengeId: string, options: UpdateUserChallengeOptions): Promise<UpdateChallengeResponse> => {
  const response = await patch<UpdateChallengeResponse>(`/user/challenges/${userChallengeId}`, options)

  return response
}

export const getCategories = async (): Promise<ChallengeCategory[]> => {
  const response = await get<ChallengeCategory[]>(`/challengeCategories`)
  return response
}

export const getSeries = async (): Promise<Series[]> => {
  const response = await get<Series[]>(`/challenges/series`)
  return response
}
