import React from 'react';
import { Box } from 'shared/components';
import { Link } from 'react-router-dom';
import { ReactComponent as DownloadIOS } from 'assets/images/download-app-store.svg';

import styles from './WelcomeEmailConfirmation.module.scss';

export const WelcomeEmailConfirmation: React.FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.center}>
        <p>Thanks for Signing up! Check your email or click below to download LEANSQUAD for iOS</p>
        <DownloadIOS
          className={styles.downloadIOS}
          onClick={() => {
            const win = window.open('https://apps.apple.com/us/app/leansquad-fitness-app/id1555120074', '_blank');
            win?.focus();
          }}
        />
        <p className={styles.link}>
          <Link to="/get-started">or continue on Web</Link>
        </p>
      </Box>
    </Box>
  );
};
