import { ScheduledRecipe } from 'types'
import { del, get, post } from './helpers'
import { CreateScheduledRecipeOptions, DeleteResponse, GetScheduledRecipeResponse, SaveScheduledPlanResponse, SaveScheduledRecipePlanOptions } from './types'

export const getScheduledRecipesByDate = async (date?: string): Promise<GetScheduledRecipeResponse> => {
  console.log('FetchingSchedulued Recipes', date)
  const response = await get<GetScheduledRecipeResponse>(`/user/scheduled-recipes?${date ? `date=${date}` : ''}`)
  return response
}

export const createScheduledRecipe = async (body: CreateScheduledRecipeOptions): Promise<ScheduledRecipe> => {
  const response = await post<ScheduledRecipe>(`/user/scheduled-recipes`, body)
  return response
}

export const removeScheduledRecipe = async (id: string): Promise<DeleteResponse> => {
  const response = await del<DeleteResponse>(`/user/scheduled-recipes/${id}`)
  return response
}

export const saveScheduledPlan = async (body: SaveScheduledRecipePlanOptions): Promise<SaveScheduledPlanResponse> => {
  const response = await post<SaveScheduledPlanResponse>(`/user/scheduled-recipes/plan`, body)
  return response
}
