import React, { useContext, useEffect, useRef } from 'react'
import { set, useForm } from 'react-hook-form'
import * as AdminAPI from '../../../services/api/admin'
import { UserAchievementCriteria, UserAchievementCriteriaWithRelatedData, UserAchievementWithRelatedData } from 'types'
import { AchievementCriteria } from '../../../types/index'
import { toInputDateTimeValue } from '../../../shared/functions/utils'
import userAchievementCriteria from 'services/api/userAchievementCriteria'
import { NotyfContext } from 'context/NotyfContext'

type Props = {
  userAchievement: UserAchievementWithRelatedData
  index: number
  userId: string
}

const UserAchievementForm = ({ userAchievement, index, userId }: Props) => {
  const isInitialMount = useRef(true)
  const notyf = useContext(NotyfContext)

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: userAchievement,
  })

  useEffect(() => {
    reset(userAchievement)
  }, [userAchievement, reset])

  const onSubmit = (data: any) => {
    if (!userAchievement.id) return
    console.log('data :>> ', data)

    // if date fields are empty, remove them from the data object
    if (!data.completedDate) {
      delete data.completedDate
    }

    if (!data.startDate) {
      delete data.startDate
    }

    if (!data.endDate) {
      delete data.endDate
    }

    const formattedData = {
      ...data,
      starRating: parseInt(data.starRating, 10),

      // strip out the achievement
      achievement: undefined,
      userAchievementCriteria: data.userAchievementCriteria.map((criteria: any) => ({
        ...criteria,
        value: parseInt(criteria.value, 10), // Convert value to an integer

        //strip out the achievementCriteria object
        achievementCriteria: undefined,
      })),
    }

    AdminAPI.updateUserAchievement(userAchievement.id, formattedData).then((res) => {
      if (!res) {
        notyf.error('Error updating UA: ' + res)
        return
      } else {
        console.log('updated')
        notyf.success('UA updated updated!')
      }
    })
  }

  // Watch the entire form to get updates on nested fields
  const completed = watch('completed')
  const { achievement } = userAchievement

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      // This code runs when 'completed' changes after the component has initially mounted
      if (completed) {
        setValue('status', 'COMPLETED')
        setValue('completedDate', toInputDateTimeValue(new Date().toISOString()))
      } else {
        setValue('status', 'in-progress')
        setValue('completedDate', '')
      }
    }
  }, [completed, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" px-3 mt-14 mb-6 md:mb-0">
      <h3 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">User Achievement {index + 1}</h3>
      {/* Display-only Achievement fields */}
      <div className="flex w-3/5 justify-between">
        <img src={achievement.badgeImageUrl} alt={achievement.name} className="w-16 h-16" />
        <p className="text-gray-700 mt-2 font-bold">
          Achievement Name: <span className="font-normal">{achievement.name}</span>
        </p>
        <p className="text-gray-700 mt-2 font-bold">
          Achievement Type: <span className="font-normal">{achievement.type}</span>
        </p>
      </div>

      {/* Editable UserAchievement fields */}
      <div className="grid  grid-flow-col space-x-2 col-span-2">
        <div className="flex flex-row">
          <label htmlFor={`status-${index}`} className="mr-3">
            Status:
          </label>
          <input
            {...register(`status`)}
            id={`status-${index}`}
            placeholder="Status"
            className="block text-center w-full mt-1 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md"
          />
        </div>
        <div className="flex flex-row ">
          <label htmlFor="starRating" className="mr-3">
            Star Rating:
          </label>
          <input
            type="number"
            {...register(`starRating`)}
            id={`starRating-${index}`}
            placeholder="Star Rating"
            className="block text-right w-2/5 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md"
          />
        </div>
        <div className="flex flex-row ">
          <label htmlFor="completed" className="mr-3">
            Completed:
          </label>
          <input type="checkbox" {...register(`completed`)} id="completed" className="leading-tight" />
        </div>
      </div>
      <div className="grid  grid-flow-col space-x-2 col-span-2 mt-2">
        <div className="flex flex-row ">
          <label htmlFor="completedDate" className="mr-3">
            Completed Date:
          </label>
          <input
            type="text"
            {...register(`completedDate`)}
            id={`completedDate-${index}`}
            placeholder="Completed Date"
            className="block pl-3 w-3/5 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md"
          />
        </div>
        <div className="flex flex-row ">
          <label htmlFor="startDate" className="mr-3">
            Start Date:
          </label>
          <input
            type="text"
            {...register(`startDate`)}
            id={`startDate-${index}`}
            placeholder="Start Date"
            className="block  pl-3 w-3/5 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md"
          />
        </div>
        <div className="flex flex-row ">
          <label htmlFor="endDate" className="mr-3">
            End Date:
          </label>
          <input
            type="text"
            {...register(`endDate`)}
            id={`endDate-${index}`}
            placeholder="End Date"
            className="block  pl-3 w-3/5 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md"
          />
        </div>
        {/* Nested UserAchievementCriteria fields */}
      </div>
      <div className=" mt-5 grid  grid-cols-3">
        {userAchievement.userAchievementCriteria.map((criteria, criteriaIndex) => {
          const isDerived = criteria?.achievementCriteria?.derived ?? false
          const target = criteria?.achievementCriteria?.target ?? 0
          return (
            <div key={criteria.id} className="mt-2">
              {/* Display-only AchievementCriteria fields */}
              <p className="text-gray-700 ">{criteria?.achievementCriteria?.displayText ?? 'NA'}</p>
              {/* Editable UserAchievementCriteria fields */}
              <div className="flex flex-row ">
                <div className="flex flex-row ">
                  <label htmlFor={`value-${index}-${criteriaIndex}`} className={`mr-3 `}>
                    Value: <span className="text-gray-600 italic text-xs">target: {`${target}`}</span>
                  </label>
                  <input
                    type="number"
                    disabled={isDerived}
                    {...register(`userAchievementCriteria.${criteriaIndex}.value`)}
                    id={`value-${index}-${criteriaIndex}`}
                    placeholder="Value"
                    className="block text-right w-1/2 mt-1 border-2 border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 rounded-md disabled:cursor-not-allowed	 disabled:bg-slate-300 "
                  />
                </div>
                <div className="flex flex-row mt-3">
                  <label htmlFor={`completed-${index}-${criteriaIndex}`} className="w-1/2 mr-5">
                    Completed:
                  </label>
                  <input
                    type="checkbox"
                    {...register(`userAchievementCriteria.${criteriaIndex}.completed`)}
                    id={`completed-${index}-${criteriaIndex}`}
                    className="mr-2 leading-tight"
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <button type="submit" className="px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 mt-12">
        Update User&apos;s {achievement.name}
      </button>
    </form>
  )
}

export { UserAchievementForm }
