import React, { createContext, useMemo, useReducer } from 'react'
import { RecipePlanActionTypes, RecipePlanState } from 'types'

const initialState: RecipePlanState = {
  activeDate: undefined,
  activeRecipes: {},
  initialRecipes: {},
  activeMeal: undefined,
}

const defaultDispatch: React.Dispatch<RecipePlanActionTypes> = () => initialState

export const RecipePlanContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
})
RecipePlanContext.displayName = 'RecipePlanContext'

const reducer = (state: RecipePlanState = initialState, action: RecipePlanActionTypes) => {
  switch (action.type) {
    case 'UPDATE_ACTIVE_DATE':
      return {
        ...state,
        activeDate: action.activeDate,
      }
    case 'UPDATE_ACTIVE_RECIPES':
      return {
        ...state,
        activeRecipes: action.activeRecipes,
      }
    case 'SET_RECIPES':
      return {
        ...state,
        activeRecipes: action.activeRecipes,
        initialRecipes: action.activeRecipes,
      }
    case 'UPDATE_ACTIVE_MEAL':
      return {
        ...state,
        activeMeal: action.activeMeal,
      }
    case 'RESET_RECIPE_PLAN':
      return { ...initialState }

    default:
      return state
  }
}

export const RecipePlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  console.log('RecipePlanProvider Rendered:>> ', state)
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])
  // const value = { state, dispatch }
  return <RecipePlanContext.Provider value={value}>{children}</RecipePlanContext.Provider>
}

export const RecipePlanContextConsumer = RecipePlanContext.Consumer
