import { useWorkoutData } from 'features/_OnDemand/hooks/useWorkoutData'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { UserChallengeContext } from 'context/UserChallengeContext'

import { OnDemandView } from './OnDemandView'
import { WorkoutType } from 'types'

const removeAllOptionFromWorkTypes = (workoutTypes: WorkoutType[]) => {
  const newWorkoutTypes = workoutTypes.filter((workoutType: WorkoutType) => workoutType.id !== 'all')
  return newWorkoutTypes
}

export const OnDemand: React.FC = () => {
  const history = useHistory()
  const { typeOptions, workoutClassTypes: workoutClasses = [] } = useWorkoutData()
  const workoutTypes = removeAllOptionFromWorkTypes(typeOptions)
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState

  const onClickWorkoutType = (category: string) => {
    history.push(`/on-demand/workout-type/${category}`)
  }

  const onClickWorkoutClass = (category: string) => {
    history.push(`/on-demand/workout-class/${category}`)
  }

  const onClickWorkoutFavorites = () => {
    history.push(`/on-demand/workout-favorites`)
  }

  const onClickFindChallenge = () => {
    history.push('/challenges')
  }

  return (
    <OnDemandView
      workoutTypes={workoutTypes}
      workoutClasses={workoutClasses}
      activeChallenge={activeChallenge}
      onClickWorkoutType={onClickWorkoutType}
      onClickWorkoutClass={onClickWorkoutClass}
      onClickWorkoutFavorites={onClickWorkoutFavorites}
      onClickFindChallenge={onClickFindChallenge}
    />
  )
}
