import React, { useCallback } from 'react'
import { Recipe } from 'types'
import { Box } from '../Box/Box'
import styles from './RecipeItem.module.scss'

type RecipeItemProps = {
  item: Recipe
  onClick: (item: Recipe) => void
  children?: React.ReactNode
}

export const RecipeItem: React.FC<RecipeItemProps> = ({ children, item, onClick }) => {
  const { title, prepTime, cookTime, photos } = item

  const onClickItemCallback = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  const firstPhoto = photos.length > 0 ? photos[0] : null

  return (
    <div className="hover:cursor-pointer w-full" onClick={onClickItemCallback}>
      {firstPhoto ? (
        <img src={`${firstPhoto}?fm=jpg&w=263&h=225&q=95`} className={` shadow-lg  object-top object-cover rounded-md w-full h-[225px]`} />
      ) : (
        <Box className={styles.recipePreviewDefault} />
      )}
      <p className={styles.recipeTitle}>{title}</p>
      <p className={styles.recipeDuration}>{`${prepTime + cookTime} ${prepTime + cookTime === 1 ? 'Minute' : 'Minutes'}`}</p>
    </div>
  )
}
