import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { SideNavigation } from 'containers/SideNavigation/SideNavigation';
import { TopNavigation } from 'containers/TopNavigation/TopNavigation';
import { RecipePlanProvider, AuthStateContext } from 'context';
import { authenticatedRoutes } from 'router';
import { Box } from 'shared/components';
import styles from './DefaultLayout.module.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SimpleReactLightbox from 'simple-react-lightbox';
import useMediaQuery from 'hooks/useMediaQuery';
import { SubscriptionUtils } from 'shared/functions';
import { ScrollToTop } from 'router/ScrollToTop';

export const DefaultLayout: React.FC<{ children: React.ReactNode }> = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const { state: authState } = useContext(AuthStateContext);

  /**
   * Check User's Subscription Status
   */
  useEffect(() => {
    const { user } = authState;
    const { pathname } = location;
    if (user) {
      const { subscription } = user;
      if (!SubscriptionUtils.isValidSubscription(subscription) && pathname !== '/profile/subscription/update') {
        history.replace('/profile/subscription/update');
        return;
      }
    }
  }, [authState.user, location, history]);

  useEffect(() => {
    // Show/Hide Sidebar based on MediaQuery Screen width
    if (isDesktop) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [isDesktop]);

  const toggleSidebar = useCallback((forceHide: boolean) => {
    if (forceHide) {
      setShowSidebar(false);
      return;
    }

    setShowSidebar((prev) => !prev);
  }, []);

  return (
    <SimpleReactLightbox>
      <ScrollToTop />
      <Box className={`${styles.container} `}>
        <TopNavigation toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
        {showSidebar && <SideNavigation isMobile={!isDesktop} toggleSidebar={toggleSidebar} />}
        <Box id="layout-content" className={styles.content}>
          <RecipePlanProvider>
            <Switch>
              {authenticatedRoutes.map(({ path, name, component, exact }) => (
                <Route path={path} key={name} component={component} exact={exact} />
              ))}
              <Redirect to="/today" />
            </Switch>
          </RecipePlanProvider>
        </Box>
      </Box>
    </SimpleReactLightbox>
  );
};
