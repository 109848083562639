import { ChallengeItem } from 'shared/components'
import { SeriesItem } from 'components/SeriesItem'
import React, { useEffect } from 'react'
import { Challenge, Series } from '../../../types/index'
import { isSeries } from 'services/isSeries'
import { isChallenge } from 'services/isChallenge'
import { SeriesChallengeList } from '../../../components/SeriesChallengeList'
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid'
import { useHistory, useLocation, useParams } from 'react-router'

type ChallengeListProps = {
  challenges: (Challenge | Series)[]
  handleClickChallenge: (item: Challenge) => void
  handleClickSeries: (item: Series) => void
  completedChallenges: { [key: string]: number } | null
  showSeries: boolean
  setShowSeries: (show: boolean) => void
  selectedSeries: Series | null
  setSelectedSeries: (series: Series | null) => void
}

type ChallengeSeriesParams = {
  seriesId: string
}

const ChallengeList = ({
  challenges,
  handleClickChallenge,
  handleClickSeries,
  completedChallenges,
  showSeries,
  setShowSeries,
  selectedSeries,
  setSelectedSeries,
}: ChallengeListProps) => {
  const { seriesId = '' } = useParams<ChallengeSeriesParams>()
  const history = useHistory()

  const handleBackButton = React.useCallback(() => {
    setShowSeries(false)
    setSelectedSeries(null)
    history.replace('/challenges')
  }, [])

  useEffect(() => {
    if (seriesId) {
      //find series from context
      const seriesItem = challenges.find((item) => {
        // Check if the item is a Series and if its id matches the seriesId
        if (item.id === seriesId) {
          return item
        }
      })
      if (seriesItem) {
        if (!selectedSeries) setSelectedSeries(seriesItem as Series)
        if (!showSeries) setShowSeries(true)
      }
    }
  }, [seriesId, selectedSeries, challenges])

  if (!challenges) {
    return null
  }
  return (
    <div>
      {showSeries && selectedSeries && (
        <div>
          <div className="flex ">
            <button
              id="backButton"
              onClick={handleBackButton}
              className="flex justify-center text-gray-700 hover:text-slate-900
              border-slate-300 border-b-4  hover:border-teal p-2  font-medium rounded-full text-sm  w-28"
            >
              <ArrowUturnLeftIcon className="h-5 w-5" />
              <div className="ml-2">Back</div>
            </button>
            <span className="font-xl text-center w-full  mr-24 font font-extrabold ml-8 mt-2">Challenge Series: {selectedSeries.displayName}</span>
          </div>
          <SeriesChallengeList series={selectedSeries} challenges={selectedSeries.challenges} handleClick={handleClickChallenge} completedChallenges={completedChallenges} />
        </div>
      )}
      {(!showSeries || !selectedSeries) && (
        <div className={'flex flex-wrap justify-center mx-auto mt-4'}>
          {challenges.map((item) => {
            if (isChallenge(item)) {
              item = item as Challenge
              return (
                <ChallengeItem
                  key={item.title.concat(item.id)}
                  item={item}
                  handleClick={handleClickChallenge}
                  itemId={item.id}
                  releaseDate={item.releaseDate}
                  completedChallenges={completedChallenges}
                />
              )
            } else if (isSeries(item)) {
              item = item as Series

              return <SeriesItem key={item.displayName.concat(item.id)} item={item} handleClick={handleClickSeries} itemId={item.id} completedChallenges={completedChallenges} />
            }
          })}
        </div>
      )}
    </div>
  )
}

export { ChallengeList }
