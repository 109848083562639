import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './Measurements.module.scss'
import convert from 'convert-units'
import { Box, Button, SelectInput } from 'shared/components'
import { AuthStateContext } from 'context'
import {
  genderOptions,
  heightOptionsImperial,
  heightOptionsMetric,
  optionsByUnit,
  OptionType,
  unitOfMeasurementOptions,
  weightOptionsImperial,
  weightOptionsMetric,
} from '../options'
import usePrevious from 'hooks/usePrevious'
import { UserAPI } from 'services/api'
import { UpdateUserOptions } from 'services/api/types'
import { useMutation, useQueryClient } from 'react-query'
import { NotyfContext } from 'context/NotyfContext'
import { useHistory } from 'react-router'
import { dayjs } from 'shared/functions'
import { GenderOption } from 'types'

export const Measurements: React.FC = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState

  let initialPreferredUnit = null
  let initialHeight = null
  let initialWeight = null

  if (user) {
    const foundUnitOfMeasurement = unitOfMeasurementOptions.find((i) => i.value === user.unitPreference)
    if (foundUnitOfMeasurement) {
      initialPreferredUnit = foundUnitOfMeasurement
      if (initialPreferredUnit.value === 'imperial') {
        // convert and round
        const heightImp = Math.floor(convert(Number(user.heightCM)).from('cm').to('in'))
        const weightImp = Math.floor(convert(Number(user.weightKG)).from('kg').to('lb'))

        initialHeight = optionsByUnit.imperial.height.find((i) => i.value === String(heightImp))
        initialWeight = optionsByUnit.imperial.weight.find((i) => i.value === String(weightImp))
      } else {
        initialHeight = optionsByUnit.metric.height.find((i) => i.value === String(user.heightCM))
        initialWeight = optionsByUnit.metric.weight.find((i) => i.value === String(user.weightKG))
      }
    }
  }
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState<OptionType | null>(initialPreferredUnit)
  const prevUnitOfMeasure = usePrevious(initialPreferredUnit, initialPreferredUnit)
  // TODO: Adjust to useReducer for easier management
  const [selectedHeight, setSelectedHeight] = useState<OptionType | null>(initialHeight || null)
  const [selectedWeight, setSelectedWeight] = useState<OptionType | null>(initialWeight || null)
  const [selectedGender, setSelectedGender] = useState<OptionType | null>(user?.gender ? { label: user.gender, value: user.gender } : null)
  const [selectedBirthday, setSelectedBirthday] = useState(user?.birthday ? dayjs(user.birthday).toDate() : null)
  const notyf = useContext(NotyfContext)

  const { mutateAsync: updateUser, isLoading: updatingUser } = useMutation((options: UpdateUserOptions) => UserAPI.updateUser(options), {
    onSuccess: () => {
      notyf.success('Profile updated!')
      queryClient.refetchQueries('user')
      history.goBack()
    },
    onError: (err: Error) => {
      notyf.error(err.message)
    },
  })

  useEffect(() => {
    console.log('change in unit of measure', selectedUnitOfMeasure)

    // Convert height & weight to selected unit of measure type?
    // Clearing inputs for now
    if (selectedUnitOfMeasure !== prevUnitOfMeasure) {
      console.log('resetting height & weight :>> ')
      setSelectedHeight(null)
      setSelectedWeight(null)
    }
  }, [selectedUnitOfMeasure])

  const onChange = (target: { name: string; selected: OptionType }) => {
    const { name, selected } = target
    console.log(`name, selected :>> ${name}`, selected)
    if (name === 'unitOfMeasure') {
      setSelectedUnitOfMeasure(selected)
    }
    if (name === 'height') {
      setSelectedHeight(selected)
    }
    if (name === 'weight') {
      setSelectedWeight(selected)
    }
    if (name === 'gender') {
      setSelectedGender(selected)
    }
  }

  const onSelectDate = (value: Date) => {
    console.log(`value`, dayjs(value).format('YYYY-MM-DD'))
    setSelectedBirthday(value)
  }

  const onClickSave = () => {
    // convert to Metric if not already.
    // update user profile
    if (selectedUnitOfMeasure) {
      const updatedBody: UpdateUserOptions = {
        unit_preference: selectedUnitOfMeasure.value === 'metric' ? 'metric' : 'imperial',
        birthday: selectedBirthday ? dayjs(selectedBirthday).format('YYYY-MM-DD') : null,
        gender: selectedGender ? (selectedGender.value as GenderOption) : null,
      }
      if (selectedUnitOfMeasure?.value === 'imperial') {
        if (selectedHeight) {
          updatedBody.height_cm = Number(convert(Number(selectedHeight.value)).from('in').to('cm').toFixed(1))
        }
        if (selectedWeight) {
          updatedBody.weight_kg = Number(convert(Number(selectedWeight.value)).from('lb').to('kg').toFixed(1))
        }
      } else {
        updatedBody.height_cm = selectedHeight ? Number(selectedHeight.value) : undefined
        updatedBody.weight_kg = selectedWeight ? Number(selectedWeight.value) : undefined
      }

      updateUser(updatedBody)
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <p className={styles.headline}>MEASUREMENTS</p>

        <p className={styles.subHeading}>Birthday</p>
        <DatePicker
          onChange={(value) => {
            console.log('value :>> ', value)
          }}
          onSelect={onSelectDate}
          selected={selectedBirthday}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={new Date()}
        />

        <p className={styles.subHeading}>Gender</p>
        <SelectInput name="gender" onChange={onChange} options={genderOptions} value={selectedGender} />

        <p className={styles.subHeading}>Unit of Measurement</p>
        <SelectInput name="unitOfMeasure" onChange={onChange} options={unitOfMeasurementOptions} value={selectedUnitOfMeasure} />
        <p className={styles.subHeading}>{`Height (${selectedUnitOfMeasure?.value === 'metric' ? 'cm' : 'in'})`}</p>
        <SelectInput name="height" onChange={onChange} options={selectedUnitOfMeasure?.value === 'metric' ? heightOptionsMetric : heightOptionsImperial} value={selectedHeight} />
        <p className={styles.subHeading}>{`Weight (${selectedUnitOfMeasure?.value === 'metric' ? 'kg' : 'lb'})`}</p>
        <SelectInput name="weight" onChange={onChange} options={selectedUnitOfMeasure?.value === 'metric' ? weightOptionsMetric : weightOptionsImperial} value={selectedWeight} />

        <Button label="Save" onClick={onClickSave} containerStyle={styles.saveBtn} loading={updatingUser} />
      </Box>
    </Box>
  )
}
