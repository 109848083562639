import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { Box, Button, Calendar, LoadingIndicator, RecipeItem, SelectOption } from 'shared/components'
import { ReactComponent as Nutrition } from 'assets/images/nutrition.svg'
import { dayjs, DocumentUtils } from 'shared/functions'
import { Recipe, RecipeCategory } from 'types'
import styles from './Recipes.module.scss'
import { ScheduledRecipesAPI } from 'services/api'
import { GetScheduledRecipeResponse } from 'services/api/types'
import { useRecipeData } from './useRecipeData'
import { RecipePlanActions, RecipePlanContext } from 'context'
import useScrollPosition from 'hooks/useScrollPosition'

const today = dayjs().startOf('day')
const initialDate = today

export const Recipes: React.FC = () => {
  const history = useHistory()
  const { state: recipePlanState, dispatch: recipePlanDispatch } = useContext(RecipePlanContext)
  const { activeDate: planActiveDate } = recipePlanState
  const [selectedDate, setSelectedDate] = useState(planActiveDate ? dayjs(planActiveDate).startOf('day') : initialDate)
  const [activeDate, setActiveDate] = useState(planActiveDate ? dayjs(planActiveDate).startOf('day') : initialDate)

  const startOfDay = dayjs(selectedDate).startOf('day').utc().toISOString()
  const { data: scheduledRecipes, isLoading: scheduledRecipesLoading } = useQuery(
    ['scheduledRecipes', startOfDay],
    () => ScheduledRecipesAPI.getScheduledRecipesByDate(startOfDay),
    {
      staleTime: 15 * 60 * 1000, // 15 minutes
    },
  )

  const { recipeCategories, mealTypes, recipes, recipesLoading, filterTypeId } = useRecipeData()

  useEffect(() => {
    if (!recipes) return
    console.log('recipes:', recipes)
  }, [recipes])

  const { setScrollYStorage } = useScrollPosition(location.pathname, recipesLoading ? false : true)

  useEffect(() => {
    if (planActiveDate !== selectedDate.format('YYYY-MM-DD')) {
      recipePlanDispatch(RecipePlanActions.updateActiveDate(dayjs(selectedDate).format('YYYY-MM-DD')))
    }
  }, [selectedDate, planActiveDate, recipePlanDispatch])

  const onClickRecipeItem = (item: Recipe) => {
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    history.push(`/recipes/${item.id}/overview`, {
      recipe: item,
    })
  }

  const onClickCategory = (item: RecipeCategory) => {
    history.replace(`recipes?type=${item.title}`)
  }

  const onClickAddMeal = (mealType?: string) => {
    if (mealType) {
      recipePlanDispatch(RecipePlanActions.updateActiveMeal(mealType.toLowerCase()))
      recipePlanDispatch(RecipePlanActions.updateActiveRecipes({}))
    }
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    history.push(`/recipes/choose-meals?date=${selectedDate.format('YYYY-MM-DD')}`)
  }

  const onClickChangeMeals = () => {
    const activeRecipes: Record<string, Recipe> = {}
    if (scheduledRecipes && Object.keys(scheduledRecipes).length > 0) {
      Object.keys(scheduledRecipes).forEach((key) => {
        if (scheduledRecipes[key]) {
          activeRecipes[key] = scheduledRecipes[key]
        }
      })
      recipePlanDispatch(RecipePlanActions.updateActiveMeal(undefined))
      recipePlanDispatch(RecipePlanActions.setRecipes(activeRecipes))
    }
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    history.push(`/recipes/choose-meals?date=${selectedDate.format('YYYY-MM-DD')}`)
  }

  const renderMealOptions = () => {
    if (scheduledRecipesLoading) {
      return <LoadingIndicator />
    }
    if (scheduledRecipes && Object.keys(scheduledRecipes).length > 0) {
      return (
        <Box className={styles.mealOptionsContainer}>
          {Object.keys(scheduledRecipes).map((key) => {
            const recipe = scheduledRecipes[key]
            const { scheduled_recipe: scheduledRecipe } = recipe
            if (!recipe) {
              return null
            }
            let mealType = null
            if (mealTypes && scheduledRecipe) {
              mealType = mealTypes.find((item) => item.title.toLowerCase() === scheduledRecipe.meal_type.toLowerCase())
            }

            return (
              <Box key={key}>
                <p className={styles.scheduledKeyTitle}>{mealType ? mealType.title : key}</p>
                <Box onClick={() => onClickRecipeItem(recipe)} key={`${key}-${recipe.id}`} className={`${styles.scheduledContainer}`}>
                  <img src={recipe.photos[0]} className={styles.image} alt={`Recipe ${recipe.title}`} />
                  <Box className={styles.optionContent}>
                    <p>{recipe.title}</p>
                    <p>{`${recipe.prepTime + recipe.cookTime} ${recipe.prepTime + recipe.cookTime === 1 ? 'Minute' : 'Minutes'}`}</p>
                  </Box>
                </Box>
              </Box>
            )
          })}
          <Button label="Change" onClick={onClickChangeMeals} labelStyle={styles.changeBtnText} containerStyle={styles.changeBtn} />
        </Box>
      )
    }

    return (
      <Box className={styles.mealOptionsContainer}>
        {(mealTypes || []).map((option) => (
          <SelectOption key={option.title} title={option.title} icon={option.icon} description="Add Meal" onClick={() => onClickAddMeal(option.title)} />
        ))}
      </Box>
    )
  }

  let activeCategory: RecipeCategory | null = null
  if (recipeCategories) {
    const foundCategory = recipeCategories.find((i) => i.id === filterTypeId)
    if (foundCategory) activeCategory = foundCategory
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <p>Nutrition</p>
      </Box>
      <Box className={styles.contentContainer}>
        <Box className={styles.calendarSection}>
          <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} activeDate={activeDate} setActiveDate={setActiveDate} />
          {renderMealOptions()}
        </Box>
        <Box>
          <Box className={styles.categoryRow}>
            {recipeCategories &&
              recipeCategories.map((item, index) => {
                const isActive = filterTypeId === item.id
                return (
                  <button onClick={() => onClickCategory(item)} key={`${index}-${item.id}`} className={`${styles.categoryItem} ${isActive ? styles.activeCategoryItem : ''}`}>
                    <p>{item.title}</p>
                  </button>
                )
              })}
          </Box>
          {activeCategory && activeCategory.description && (
            <Box className={styles.descriptionBanner}>
              <Nutrition />
              <p>{activeCategory.description}</p>
            </Box>
          )}

          <Box className={styles.recipeItemsContainer}>
            {recipesLoading ? (
              <LoadingIndicator />
            ) : (
              <div className="grid lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 gap-4">
                {recipes?.map((item) => {
                  return <RecipeItem key={item.id} item={item} onClick={onClickRecipeItem} />
                })}
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
