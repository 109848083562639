import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import styles from './ChooseMeals.module.scss'
import { Box, Button, LoadingIndicator, RecipeItem } from 'shared/components'
import { ReactComponent as Nutrition } from 'assets/images/nutrition.svg'
import { ReactComponent as Check } from 'assets/icons/check.svg'
import { useRecipeData } from '../useRecipeData'
import { Recipe, RecipeCategory } from 'types'
import { RecipePlanActions, RecipePlanContext } from 'context'
import { dayjs, DocumentUtils } from 'shared/functions'
import { SaveScheduledRecipePlanOptions } from 'services/api/types'
import { ScheduledRecipesAPI } from 'services/api'
import { useMutation, useQueryClient } from 'react-query'
import { NotyfContext } from 'context/NotyfContext'
import useScrollPosition from 'hooks/useScrollPosition'

export const ChooseMeals: React.FC = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { search: locationSearch } = useLocation()
  const locationQuery = new URLSearchParams(locationSearch)
  const { state: recipePlanState, dispatch: recipePlanDispatch } = useContext(RecipePlanContext)
  const notyf = useContext(NotyfContext)
  const { mutateAsync: mutateSavePlan, isLoading: savePlanLoading } = useMutation((options: SaveScheduledRecipePlanOptions) => ScheduledRecipesAPI.saveScheduledPlan(options), {
    onSuccess: (res) => {
      console.log('Save Plan res :>> ', res)
      notyf.success('Plan saved!')
      queryClient.invalidateQueries(['scheduledRecipes', dayjs(activeDate).utc().toISOString()])
      if (dayjs(activeDate).isToday()) {
        queryClient.invalidateQueries(`todayContent`)
      }
      history.replace('/recipes')
    },
    onError: (err: Error) => {
      notyf.error(err.message)
    },
  })
  const { recipeCategories, mealTypes, recipes, recipesLoading, filterTypeId } = useRecipeData()

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, recipesLoading ? false : true)

  const { activeMeal, activeDate, activeRecipes, initialRecipes } = recipePlanState

  useEffect(() => {
    if (!activeDate && locationQuery) {
      recipePlanDispatch(RecipePlanActions.updateActiveDate(dayjs(locationQuery.get('date') as string).format('YYYY-MM-DD')))
    }
  }, [activeDate, locationQuery])

  const onClickRecipeItem = (item: Recipe) => {
    console.log('onClickRecipeItem :>> ', item)
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    history.push(`/recipes/${item.id}/overview?chooseMeals=true`, {
      recipe: item,
    })
  }

  const onClickAddMeal = (item: Recipe) => {
    console.log('onClickAddMeal :>> ', item)
    console.log('recipePlanState :>> ', recipePlanState)
    const activeRecipesCopy = { ...activeRecipes }
    if (activeMeal) {
      activeRecipesCopy[activeMeal] = item
      recipePlanDispatch(RecipePlanActions.updateActiveRecipes(activeRecipesCopy))
    }
  }

  const onClickRemoveMeal = () => {
    const activeRecipesCopy = { ...activeRecipes }
    if (activeMeal) {
      delete activeRecipesCopy[activeMeal]
      recipePlanDispatch(RecipePlanActions.updateActiveRecipes(activeRecipesCopy))
    }
  }

  const onClickCategory = (item: RecipeCategory) => {
    history.replace(`${location.pathname}?date=${locationQuery.get('date') as string}&type=${item.title}`)
  }

  const onClickMealType = (mealType: string) => {
    recipePlanDispatch(RecipePlanActions.updateActiveMeal(mealType.toLowerCase()))
  }

  const onClickSavePlan = async () => {
    console.log('onClickSavePlan', recipePlanState)
    if (!activeDate || !activeMeal) {
      return
    }
    const createOptions: SaveScheduledRecipePlanOptions = {
      scheduled_date: dayjs(activeDate).utc().toISOString(),
      recipes: Object.keys(activeRecipes).map((key) => {
        const recipe = activeRecipes[key]
        return {
          recipe_id: recipe.id,
          meal_type: key,
        }
      }),
    }
    mutateSavePlan(createOptions)
  }

  // string compare as only checking for any differences from initial load in
  // otherwise order of activeRecipes changes on add/remove, showing a difference
  // when there probably is not in recipes (add/remove same recipe)
  const hasInitialChanges = JSON.stringify(initialRecipes) !== JSON.stringify(activeRecipes)

  return (
    <Box className={styles.container}>
      <Button
        label={`Save Plan (${Object.keys(activeRecipes).length})`}
        onClick={onClickSavePlan}
        containerStyle={styles.actionBtn}
        loading={savePlanLoading}
        disabled={!hasInitialChanges}
      />
      <Box className={styles.contentContainer}>
        <Box className={styles.header}>
          <p>Choose your meals</p>
          <Box className={styles.banner}>
            <Nutrition />
            <p>Remember to pick a Lean Refuel meal if it’s the first thing you’ll eat after a meal</p>
          </Box>
        </Box>

        <Box className={styles.optionsContainer}>
          <div className={styles.emptyDiv} />
          {(mealTypes || []).map((mealType) => {
            const isActive = activeMeal && activeMeal.toLowerCase() === mealType.title.toLowerCase()
            const hasRecipe = activeRecipes && activeRecipes[mealType.title.toLowerCase()]
            return (
              <Box className={styles.mealTypeOption} key={mealType.id}>
                {hasRecipe ? (
                  <button
                    onClick={() => onClickMealType(mealType.title)}
                    className={`${styles.mealTypeIconContainer} ${isActive ? styles.activeContainer : ''} ${styles.hasRecipe}`}
                  >
                    <Check height={16} width={16} />
                  </button>
                ) : (
                  <button onClick={() => onClickMealType(mealType.title)} className={`${styles.mealTypeIconContainer} ${isActive ? styles.activeContainer : ''}`}>
                    <img src={mealType.icon} />
                  </button>
                )}

                <p>{mealType.title}</p>
              </Box>
            )
          })}
          <div className={styles.emptyDiv} />
        </Box>

        <Box className={styles.content}>
          <Box className={styles.categoryRow}>
            {recipeCategories &&
              recipeCategories.map((item) => {
                const isActive = filterTypeId && filterTypeId === item.id
                return (
                  <button onClick={() => onClickCategory(item)} key={item.id} className={`${styles.categoryItem} ${isActive ? styles.activeCategoryItem : ''}`}>
                    <p>{item.title}</p>
                  </button>
                )
              })}
          </Box>

          <Box className={styles.recipeItemsContainer}>
            {recipesLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                {recipes &&
                  recipes.map((item) => {
                    let mealAdded = false
                    if (activeMeal && activeRecipes[activeMeal]) {
                      if (activeRecipes[activeMeal].id === item.id) {
                        mealAdded = true
                      }
                    }

                    return (
                      <RecipeItem key={item.id} item={item} onClick={onClickRecipeItem}>
                        {activeMeal && (
                          <Button
                            containerStyle={`${styles.addMealBtn} ${mealAdded ? styles.active : ''}`}
                            label={mealAdded ? `Remove Meal` : `Add Meal`}
                            onClick={mealAdded ? () => onClickRemoveMeal() : () => onClickAddMeal(item)}
                            labelStyle={styles.addMealLabel}
                          />
                        )}
                      </RecipeItem>
                    )
                  })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
