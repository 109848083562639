import { get } from './helpers'
import { RecommendationsResponse, DailyContentReponse } from './types'

type RecommendationTypes = 'workout' | 'recipe' | undefined

export const getRecommendations = async (type?: RecommendationTypes, start?: string, end?: string): Promise<RecommendationsResponse> => {
  const response = await get<RecommendationsResponse>(`/user/recommendations${type ? `/${type}` : ''}?start=${start}&end=${end}`)

  return response
}

export const getTodaysContent = async (type?: RecommendationTypes, start?: string, end?: string, viewAsId?: string, timezone?: string): Promise<DailyContentReponse> => {
  const response = await get<DailyContentReponse>(
    `/user/today?${type ? `type=${type}&` : ''}${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}${viewAsId ? `&viewAsId=${viewAsId}` : ''}${
      timezone ? `&userTimeZone=${timezone}` : ''
    }`,
  )

  return response
}
