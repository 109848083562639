import { get, patch, post } from './helpers'
import { QuestionnaireAnswersCreateOptions, QuestionnaireAnswersResponse, QuestionnaireAnswersUpdateOptions, QuestionnaireResponse, QuestionResponse } from './types'

export const getQuestionnaireByTitle = async (title: string): Promise<QuestionnaireResponse> => {
  const response = await get<QuestionnaireResponse>(`/questionnaire/title/${title}`)

  return response
}

export const getQuestionByPreferenceType = async (preferenceType: string): Promise<QuestionResponse> => {
  const response = await get<QuestionResponse>(`/question/preferences/${preferenceType}`)

  return response
}

export const getUserAnswersByPreferenceType = async (preferenceType: string): Promise<QuestionnaireAnswersResponse> => {
  const response = await get<QuestionnaireAnswersResponse>(`/question/preferences/${preferenceType}`)

  return response
}

export const getQuestionnaireUserAnswers = async (title: string): Promise<QuestionnaireAnswersResponse[]> => {
  const response = await get<QuestionnaireAnswersResponse[]>(`/questionnaire/title/${title}/answers`)

  return response
}

export const createUserAnswer = async (options: QuestionnaireAnswersCreateOptions): Promise<QuestionnaireAnswersResponse> => {
  const response = await post<QuestionnaireAnswersResponse>(`/questionnaire/answers`, options)

  return response
}

export const updateUserAnswer = async (answerId: string, options: QuestionnaireAnswersUpdateOptions): Promise<QuestionnaireAnswersResponse> => {
  const response = await patch<QuestionnaireAnswersResponse>(`/questionnaire/answers/${answerId}`, options)

  return response
}
