import dayjs from 'dayjs'
import { Box } from '../../../shared/components'
import { UserSubscription } from '../../../types'
import styles from './../Subscription.module.scss'
import ExampleOnDemand from '../../../assets/images/example-on-demand.png'
import { ReactComponent as Together } from 'assets/icons/together.svg'
import React from 'react'

/*
 *  IOS MARKUP
 */

type IOSSubscription = {
  subscription: UserSubscription
}

const IOSSubscription: React.FC<IOSSubscription> = ({ subscription }) => {
  const renewsOnDate = dayjs(subscription.current_period_end).format('LL')

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <Box className={styles.header}>
          <div className="overlay" />
          <img src={ExampleOnDemand} />
          <p className={styles.headline}>Subscription</p>
          <p className={styles.subHeading}>Never Workout Alone Again, Zero Equipment, Daily Support, Only 30 Minutes A Day</p>
        </Box>
        <Box className={styles.banner}>
          <Together />
          <p>Join the Squadie Family and get LEAN!</p>
        </Box>
        <p className={styles.currentSubscription}>Current Subscription: iOS</p>
        <p className={styles.subscriptionStatus}>{`Your subscription renews on ${renewsOnDate}.`}</p>
        <p className={styles.iosMessage}>Please manage your subscription from the App Store.</p>
      </Box>
    </Box>
  )
}

export { IOSSubscription }
