import React, { useCallback } from 'react'
import { Button, Space, Drawer } from 'antd'
import { DrawerProps } from 'antd/es/drawer'
import { Difficulty, Duration, WorkoutClass, WorkoutType } from 'types'
import { Box } from 'shared/components'
import styles from './OnDemandFilter.module.scss'

export interface WorkoutFilterOptions {
  difficulty: Array<Difficulty>
  duration: Array<Duration>
  classType: Array<WorkoutClass>
  workoutType: Array<WorkoutType>
}

interface OnDemandFilterProps {
  placement: DrawerProps['placement']
  onClose: () => void
  visible: boolean
  filterOptions: WorkoutFilterOptions
  durationOptionsKeys: string[]
  filterWorkouts: (filterRequest: any) => void
}

export const OnDemandFilter: React.FC<OnDemandFilterProps> = ({ placement, onClose, visible, filterOptions, durationOptionsKeys, filterWorkouts }) => {
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState<any>({
    difficulty: null,
    duration: null,
  })

  const onClickDone = useCallback(() => {
    filterWorkouts(selectedFilterOptions)
    onClose()
  }, [selectedFilterOptions])

  const onChangeDuration = (key: string) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      duration: selectedFilterOptions.duration === key ? null : key,
    })
  }

  const onChangeDifficulty = (title: string) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      difficulty: selectedFilterOptions.difficulty === title ? null : title,
    })
  }

  if (!filterOptions) return null

  return (
    <Drawer
      title="Filter down your workouts"
      placement={placement}
      width={'100%'}
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <Button type="primary" onClick={onClickDone}>
            Done
          </Button>
        </Space>
      }
    >
      <Box className={styles.filterOptionsContainer}>
        {durationOptionsKeys && (
          <div className={styles.filterOption}>
            <h1 className={styles.filterTitle}>Length</h1>
            {durationOptionsKeys.map((key) => (
              <Button
                key={key}
                className={styles.option}
                type={selectedFilterOptions.duration === key ? 'primary' : undefined}
                value="duration"
                onClick={() => onChangeDuration(key)}
              >
                {key}
              </Button>
            ))}
          </div>
        )}
        {filterOptions.difficulty && (
          <div className={styles.filterOption}>
            <h1 className={styles.filterTitle}>Difficulty</h1>
            {filterOptions.difficulty.map((difficulty) => (
              <Button
                key={difficulty.id}
                className={styles.option}
                value="difficulty"
                type={selectedFilterOptions.difficulty === difficulty.title ? 'primary' : undefined}
                onClick={() => onChangeDifficulty(difficulty.title)}
              >
                {difficulty.title}
              </Button>
            ))}
          </div>
        )}
      </Box>
    </Drawer>
  )
}
