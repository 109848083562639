import React from 'react'

import styles from '../SignUp.module.scss'
import { ArrowLeftOutlined } from '@ant-design/icons'

const FormWrapper = ({ children, step, title, onPressPrev }: { children: React.ReactNode; step: number; title: string; onPressPrev?: () => void }) => (
  <div className={styles.selectPlan}>
    <div className={styles.accessFormSection}>
      <div className={styles.accessFormSection__header}>
        <p className={styles.accessFormSection__header_step}>Step {step} of 3.</p>
        {onPressPrev && (
          <button className={styles.accessFormSection__header_prev_button} onClick={onPressPrev}>
            <ArrowLeftOutlined style={{ fontSize: '16px', color: '#3DA3BA' }} />
          </button>
        )}
      </div>
      <h1 className={`${styles.accessFormSection__title} text  text-5xl`}>{title}</h1>
      {children}
    </div>
    {/* <p className="text-slate-900 font-semibold ml-3">No lock in contract. You will not be charged during your free trial. Cancel anytime, no commitment.</p> */}
  </div>
)

export default FormWrapper
