import React from 'react';
import { Checkbox, Form, Input, Button } from 'antd';
import styles from '../SignUp.module.scss';
import { PasswordValidations } from 'shared/components';
import { useSignUpContext } from '../SignUpContext';

export const RegistrationForm: React.FC = () => {
  const [password, setPassword] = React.useState<string>();
  const { onSubmitUserDetails } = useSignUpContext();

  return (
    <Form name="registration" style={{ marginTop: 28 }} onFinish={onSubmitUserDetails}>
      <div style={{ marginBottom: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'Please input your first name!' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'Please input your last name!' }]}
          style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
      </div>
      <Form.Item name="email" rules={[{ type: 'email', required: true, message: 'Please input your email!' }]}>
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please enter a valid password' }]}>
        <Input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>
      {password && <PasswordValidations password={password} />}
      <div className={styles.planFooter}>
        <Form.Item
          rules={[{ required: true, message: "Don't forget to agree to our terms & conditions" }]}
          name="terms"
          valuePropName="checked"
        >
          <Checkbox>
            I agree to your{' '}
            <span>
              <a href="#">Terms & Conditons</a>, <a href="#">Waiver</a> and <a href="#">Privacy Policy</a>
            </span>
          </Checkbox>
        </Form.Item>
      </div>
      <Button className={`${styles.continue} ${styles.continue__form}`} type="primary" htmlType="submit">
        Continue
      </Button>
    </Form>
  );
};
