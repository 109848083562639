import { dayjs } from 'shared/functions'
import { LightboxPhoto, TransformationPhoto, TransformationPhotosByDate } from 'types'
import { del, get, post } from './helpers'
import { CreateTransformationPhotoOptions, TransformationPhotosFormattedResponse, TransformationPhotosResponse } from './types'

const formatResponseByDate = (response: TransformationPhotosResponse) => {
  const todayFormatted = dayjs().format('LL')
  const allPhotos: LightboxPhoto[] = []
  const photosByDate: TransformationPhotosByDate = {
    [todayFormatted]: [],
  }

  response.forEach((item) => {
    const formattedDate = dayjs(item.created_at).local().format('LL')
    allPhotos.push({ src: item.photo_url, width: 'auto', height: 'auto' })
    if (photosByDate[formattedDate]) {
      photosByDate[formattedDate].push(item)
    } else [(photosByDate[formattedDate] = [item])]
  })

  return { photosByDate, allPhotos }
}

export const getUserTransformationPhotos = async (): Promise<TransformationPhotosFormattedResponse> => {
  const response = await get<TransformationPhotosResponse>(`/user/transformation-photos`)
  const formattedResponse = formatResponseByDate(response)
  return formattedResponse
}

export const createTransformationPhoto = async (body: CreateTransformationPhotoOptions): Promise<TransformationPhoto> => {
  const response = await post<TransformationPhoto>(`/user/transformation-photos`, body)
  return response
}

export const removeTransformationPhoto = async (id: string): Promise<Record<'id', string>> => {
  const response = await del<Record<'id', string>>(`/user/transformation-photos/${id}`)
  return response
}
