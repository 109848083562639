import React from 'react'
import styles from './SettingsOption.module.scss'
import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg'

type SettingsOptionProps = {
  title: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  onClick: () => void
}

export const SettingsOption: React.FC<SettingsOptionProps> = ({ title, onClick, icon: Icon }) => {
  return (
    <button onClick={onClick} className={`${styles.container} flex`}>
      {Icon && <Icon />}
      <p className={styles.optionTitle}>{title}</p>
      <Chevron className={styles.chevron} />
    </button>
  )
}
