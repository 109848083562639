import React, { useContext, useState, useEffect, useReducer } from 'react'
import { useHistory, useParams } from 'react-router'
import { PaymentMethod } from '@stripe/stripe-js'
import styles from './Subscription.module.scss'
import { Box, Button, LoadingIndicator } from 'shared/components'
import ExampleOnDemand from 'assets/images/example-on-demand.png'
import { AuthStateContext } from 'context'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CreateCustomerOptions, CreateSubscriptionOptions, UpdateCustomerOptions, UpdateSubscriptionOptions } from 'services/api/types'
import { SubscriptionsAPI } from 'services/api'
import { NotyfContext } from 'context/NotyfContext'
import { CancelModal, ConfirmUpdateModal } from './components/Modals'
import { buildSubscriptionFromPrice, buildSubscriptionsFromPrices } from './utils'
import { FirebaseAuth } from 'services/firebase'
import { dayjs } from 'shared/functions'
import { SubscriptionOptionType, CurrentViewOptions, ModalCurrentViewOptions } from 'types'
import { SettingsAPI } from 'services/api'
import { IOSSubscription } from './components/IOSSubscription'
import { UpdatePlan } from './components/UpdatePlan'
import { CurrentSubscription } from './components/CurrentSubscription'
import { PaymentMethodForm } from './components/PaymentMethodForm'
import { set } from 'react-hook-form'

type SubscriptionParams = {
  type?: 'update' | string
}

export const Subscription: React.FC = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { state: authState, dispatch: authDispatch } = useContext(AuthStateContext)
  const { type } = useParams<SubscriptionParams>()
  const [selectedOption, setSelectedOption] = useState<SubscriptionOptionType>()
  const [activeSubscription, setActiveSubscription] = useState<SubscriptionOptionType>()
  const [currentView, setCurrentView] = useState<CurrentViewOptions>(null)
  const [modalCurrentView, setModalCurrentView] = useState<ModalCurrentViewOptions>(null)
  const [showPromoCodeContactUs, setShowPromoCodeContactUs] = useState<boolean>(false)
  const [loadingUser, setLoadingUser] = useState<boolean>(true)
  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(true)
  const { user } = authState
  const [selectedPromoCode, setSelectedPromoCode] = useState<string>('')
  const [selectedCouponId, setSelectedCouponId] = useState<string>('')

  useEffect(() => {
    getShowPromoContactUs()
  }, [])

  const getShowPromoContactUs = async () => {
    const showPromoContactUs = await SettingsAPI.getShowPromoCodeContactUs()
    setShowPromoCodeContactUs(showPromoContactUs)
  }

  const notyf = useContext(NotyfContext)

  const { data: prices, isLoading: pricesLoading } = useQuery('prices', SubscriptionsAPI.getSubscriptionPrices, {
    staleTime: Infinity, // prices wont change frequently
  })

  const changeView = (view: CurrentViewOptions, reason: string) => {
    console.log('changing view to ', view)
    console.log('reason', reason)
    setCurrentView(view)
  }

  const { mutate: createSubscription, isLoading: creatingSubscription } = useMutation(
    (createOptions: CreateSubscriptionOptions) => SubscriptionsAPI.createCustomerSubscription(createOptions),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user')
        if (type && type === 'update') {
          history.goBack()
        } else {
          // Auth Context navigates to get-started based on auth state
        }
      },
      onError: (err: Error) => {
        notyf.error(err.message)
      },
    },
  )

  const { mutateAsync: createCustomer } = useMutation((createOptions: CreateCustomerOptions) => SubscriptionsAPI.createStripeCustomer(createOptions))

  const { mutate: updateCustomer, isLoading: updateCustomerLoading } = useMutation((updateOptions: UpdateCustomerOptions) => SubscriptionsAPI.updateStripeCustomer(updateOptions), {
    onSuccess: () => {
      notyf.success('Payment method updated!')
      history.goBack()
    },
    onError: (err: Error) => {
      notyf.error(err.message)
    },
  })

  const { mutate: updateSubscription, isLoading: updateSusbcriptionLoading } = useMutation(
    (updateOptions: UpdateSubscriptionOptions) => SubscriptionsAPI.updateStripeSubscription(updateOptions),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user')
        notyf.success('Subscription updated!')
        history.goBack()
      },
      onError: (err: Error) => {
        notyf.error(err.message)
      },
    },
  )

  const { mutate: cancelSubscription, isLoading: cancelSubscriptionLoading } = useMutation(
    (updateOptions: UpdateSubscriptionOptions) => SubscriptionsAPI.cancelStripeSubscription(updateOptions),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user')
        notyf.success('Subscription canceled, your credit card will no longer be billed.')
        changeView(null, 'sub canceled')
        setModalCurrentView(null)
      },
      onError: (err: Error) => {
        if (!err.message.includes('422')) {
          notyf.error(err.message)
        }
      },
    },
  )

  // Always have an up to date User on this screen.
  useEffect(() => {
    queryClient.refetchQueries('user')
  }, [])

  // TODO this is the best way to do this but for now let the FE only handle promo codes
  const subscriptionOptions = React.useMemo(() => buildSubscriptionsFromPrices(prices), [prices])

  useEffect(() => {
    if (!authState.user || !prices || !subscriptionOptions) return
    if (prices && subscriptionOptions) {
      const userSubscription = authState.user?.subscription
      setLoadingUser(false)
      setLoadingSubscription(false)
      if (userSubscription) {
        console.log({ userSubscription })
        if (userSubscription.status === 'past_due') {
          changeView('updatePayment', 'past due')
          notyf.error('Your credit card has expired. Please update your payment method.')
        }
        if (userSubscription.status && userSubscription.status !== 'canceled' && userSubscription.status !== 'unpaid' && userSubscription.stripe_price_id) {
          const foundSubscription = subscriptionOptions.find((i) => i.id === userSubscription.stripe_price_id)
          if (foundSubscription) {
            setSelectedOption(foundSubscription)
            setActiveSubscription(foundSubscription)
          } else {
            const foundInPrices = prices.find((item) => item.id === userSubscription.stripe_price_id)
            if (foundInPrices) {
              setActiveSubscription(buildSubscriptionFromPrice(foundInPrices))
            }
          }
        }
        if (userSubscription.status === 'canceled') {
          setActiveSubscription(undefined)
          setSelectedOption(undefined)
        }
      }
    }
  }, [authState.user, subscriptionOptions, prices])

  useEffect(() => {
    if (authState.isFetching || loadingUser || loadingSubscription) return
    console.log({ activeSubscription })
    if (!activeSubscription) changeView('updatePlan', 'no active sub')
  }, [activeSubscription, authState])

  const onClickAction = () => {
    if (!activeSubscription) {
      changeView('paymentForm', 'clicked')
      return // no active subscription
    }
    if (currentView === 'updatePlan') {
      setModalCurrentView('update')
      return
    }
  }

  const handleUpdatePlan = () => {
    if (!selectedOption) {
      return
    }

    if (authState.user?.subscription?.stripe_subscription_id) {
      const updateOptions = {
        subscriptionId: authState.user?.subscription?.stripe_subscription_id,
        priceId: selectedOption.id,
        promoCode: selectedCouponId,
      }

      updateSubscription(updateOptions)
    }
  }

  const handleCancelSubscription = () => {
    if (authState.user?.subscription?.stripe_subscription_id) {
      const updateOptions = {
        subscriptionId: authState.user?.subscription?.stripe_subscription_id,
      }

      cancelSubscription(updateOptions)
    }
  }

  const onPaymentSuccess = async (paymentMethod: PaymentMethod, name: string) => {
    if (!selectedOption) {
      changeView(null, 'payment success')
      return
    }

    let customerId = authState.user?.subscription?.stripe_customer_id

    if (currentView === 'updatePayment') {
      // Update Stripe Customer here
      if (customerId) {
        const updateOptions: UpdateCustomerOptions = {
          customerId,
          paymentMethodId: paymentMethod.id,
        }
        updateCustomer(updateOptions)
      }

      return
    }

    // Create Plan API call
    if (!customerId) {
      // Create Customer first
      const createCustomerResponse = await createCustomer({
        name: name,
        email: authState.user?.email || '',
        metadata: {
          ...(authState.user && {
            userId: authState.user.id || '',
            firstName: authState.user.firstName || '',
            lastName: authState.user.lastName || '',
          }),
        },
      })

      customerId = createCustomerResponse.customer_id
    }
    // mutate create subscription.
    // onSuccess:
    console.log(`🔥 Coupon Code: ${selectedCouponId}`)
    // This pathway wants an actual promo Code not a coupon id
    console.log(` 🔥 Promo Code: ${selectedPromoCode}`)
    createSubscription({
      customerId: customerId,
      paymentMethodId: paymentMethod.id,
      priceId: selectedOption.id || '',
      metadata: {},
      promoCode: selectedPromoCode,
    })
  }

  const onClickUpdatePlan = () => {
    changeView('updatePlan', 'clicked')
  }

  const onClickUpdatePaymentMethod = () => {
    changeView('updatePayment', 'clicked')
  }

  const onClickCancelSubscription = () => {
    setModalCurrentView('cancel')
  }

  const onClickLogout = async () => {
    await FirebaseAuth.signOut(authDispatch)
    queryClient.clear()
  }

  let renewsOnDate: string | undefined = ''
  if (user && user.subscription) {
    renewsOnDate = dayjs(user.subscription.current_period_end).format('LL')
  }

  if (authState.user && authState.user.subscription) {
    const { subscription } = authState.user
    const { type, status } = subscription

    const isIOSSubscription = type === 'ios' && status !== 'canceled'

    if (isIOSSubscription) {
      return <IOSSubscription subscription={subscription} />
    }
  }

  const onClickOption = (option: SubscriptionOptionType) => {
    setSelectedOption(option)
  }

  const updatePlanProps = {
    currentView,
    authState,
    activeSubscription,
    subscriptionOptions,
    selectedOption,
    onClickAction,
    onClickOption,
    showPromoCodeContactUs,
    changeView,
    selectedPromoCode,
    setSelectedPromoCode,
    selectedCouponId,
    setSelectedCouponId,
  }
  const currentSubscriptionProps = {
    activeSubscription,
    onClickUpdatePaymentMethod,
    onClickUpdatePlan,
    onClickCancelSubscription,
    renewsOnDate,
    cancelAtPeriodEnd: authState.user?.subscription?.cancelAtPeriodEnd ?? false,
  }
  const paymentMethodFormProps = {
    changeView,
    authState,
    activeSubscription,
    selectedOption,
    selectedCouponId,
    onPaymentSuccess,
    currentView,
    updateCustomerLoading,
    creatingSubscription,
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <Box className={styles.header}>
          <div className="overlay" />
          <img src={ExampleOnDemand} />
          <p className={styles.headline}>Transform Your Life!</p>
          <p className={styles.subHeading}>Never Workout Alone Again, Zero Equipment, Daily Support, Only 30 Minutes A Day</p>
        </Box>

        {pricesLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {currentView === null && activeSubscription === undefined && <UpdatePlan {...updatePlanProps} />}
            {currentView === null && activeSubscription !== undefined && <CurrentSubscription {...currentSubscriptionProps} />}
            {currentView === 'updatePayment' && <PaymentMethodForm {...paymentMethodFormProps} />}
            {currentView === 'updatePlan' && <UpdatePlan {...updatePlanProps} />}
            {currentView === 'paymentForm' && <PaymentMethodForm {...paymentMethodFormProps} />}
          </>
        )}

        {modalCurrentView === 'cancel' && <CancelModal onClose={() => setModalCurrentView(null)} onConfirm={handleCancelSubscription} isLoading={cancelSubscriptionLoading} />}
        {modalCurrentView === 'update' && <ConfirmUpdateModal onClose={() => setModalCurrentView(null)} onConfirm={handleUpdatePlan} isLoading={updateSusbcriptionLoading} />}
      </Box>
      <p className={styles.loggedInAs}>Logged in as: {`${authState.user?.email}`}</p>
      <Button label="Logout" onClick={onClickLogout} containerStyle={styles.logoutBtn} />
    </Box>
  )
}
