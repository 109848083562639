import { get, post, patch } from './helpers'
import { UserAchievementCriteria } from '../../types'
import { UserAchievementCriteriaWithRelatedData } from '../../types'

export const create = async (userAchievementCriteriaData: UserAchievementCriteria) => {
  try {
    const createdUserAchievementCriteria = await post<UserAchievementCriteria>('/userAchievementCriteria', userAchievementCriteriaData)
    return createdUserAchievementCriteria
  } catch (error) {
    console.error('Error creating user achievement criteria:', error)
    throw error
  }
}

// Get all UserAchievementCriteria
export const getAll = async () => {
  try {
    console.log('Fetching user achievement criteria')
    const userAchievementCriteria = await get<UserAchievementCriteria[]>('/userAchievementCriteria')
    return userAchievementCriteria
  } catch (error) {
    console.error('Error getting user achievement criteria:', error)
    throw error
  }
}

// Patch a UserAchievementCriteria
export const update = async (userAchievementCriteriaData: UserAchievementCriteria) => {
  console.log({ userAchievementCriteriaData })
  try {
    const patchedUserAchievementCriteria = await patch<UserAchievementCriteria>(`/userAchievementCriteria/${userAchievementCriteriaData.id}`, userAchievementCriteriaData)
    return patchedUserAchievementCriteria
  } catch (error) {
    console.error('Error patching user achievement criteria:', error)
    throw error
  }
}

export const increment = async (id: string, timestampIso: string, isYesterday: boolean, userTimeZone: string) => {
  console.log('Incrementing ', { id, timestampIso, isYesterday, userTimeZone })
  try {
    if (isYesterday) {
      // the timestamp should be the end of yesterday
      const yesterday = new Date(timestampIso)
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(23, 59, 59, 999)
      timestampIso = yesterday.toISOString()
    }

    const incrementedUserAchievementCriteria = await post<UserAchievementCriteria>(`/userAchievementCriteria/${id}/increment`, { timestampIso, isYesterday, userTimeZone })
    return incrementedUserAchievementCriteria
  } catch (error) {
    console.log({ error })
    console.error('Error incrementing user achievement criteria:', error)
    throw error
  }
}

export const fetchReportables = async (timestampIso: string, userTimeZone: string) => {
  try {
    console.log('Fetching reportable user achievement criteria')

    // Build the query string using URLSearchParams
    const queryParams = new URLSearchParams({ timestampIso, userTimeZone }).toString()

    const reportableUserAchievementCriteria = await get<{
      yesterday: UserAchievementCriteriaWithRelatedData[]
      today: UserAchievementCriteriaWithRelatedData[]
    }>(`/userAchievementCriteria/reportables?${queryParams}`)
    return reportableUserAchievementCriteria
  } catch (error) {
    console.error('Error fetching reportable user achievement criteria:', error)
    throw error
  }
}

export const noIncrement = async (id: string, timestampIso: string, isYesterday: boolean, userTimeZone: string) => {
  try {
    const updatedUserAchievementCriteria = await post<UserAchievementCriteria>(`/userAchievementCriteria/${id}/noIncrement`, { timestampIso, isYesterday, userTimeZone })
    return updatedUserAchievementCriteria
  } catch (error) {
    console.log({ error })
    console.error('Error incrementing user achievement criteria:', error)
    throw error
  }
}

export default {
  create,
  getAll,
  update,
  increment,
  noIncrement,
  fetchReportables,
}
