import React from 'react'
import Spinner from 'react-spinkit'
import { Box } from '../Box/Box'
import styles from './LoadingIndicator.module.scss'

type Props = {
  containerStyle?: string
  color?: string
}

export const LoadingIndicator: React.FC<Props> = ({ containerStyle = '', color = '#3da3ba' }) => {
  return (
    <Box className={`${styles.loadingContainer} ${containerStyle}`}>
      <Spinner fadeIn="none" className="loadingIndicator" name="three-bounce" color={color} />
    </Box>
  )
}
