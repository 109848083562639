import React from 'react'
import styles from './PasswordValidations.module.scss'
import { Validations } from 'shared/functions'
import { Box } from '../Box/Box'

type Props = {
  password: string
}

export const PasswordValidations: React.FC<Props> = ({ password = '' }) => {
  if (password.length < 1) {
    return <Box />
  }
  let passwordValidations: string[] | string | boolean = []
  if (password.length > 0) {
    passwordValidations = Validations.validPassword(password, true)
  }

  return (
    <Box className={styles.passwordValidationContainer}>
      <p className={passwordValidations.includes('min') ? styles.invalid : styles.valid}>
        <span>{passwordValidations.includes('min') ? <>❌</> : <>✅</>}</span>
        Must be at least 8 characters
      </p>
      <p className={passwordValidations.includes('lowercase') ? styles.invalid : styles.valid}>
        <span>{passwordValidations.includes('lowercase') ? <>❌</> : <>✅</>}</span>
        Must contain at least 1 lowercase letter
      </p>
      <p className={passwordValidations.includes('uppercase') ? styles.invalid : styles.valid}>
        <span>{passwordValidations.includes('uppercase') ? <>❌</> : <>✅</>}</span>
        Must contain at least 1 uppercase letter
      </p>
      <p className={passwordValidations.includes('digits') ? styles.invalid : styles.valid}>
        <span>{passwordValidations.includes('digits') ? <>❌</> : <>✅</>}</span>
        Must contain at least 1 number
      </p>
    </Box>
  )
}
