import AdminInputField from './AdminInputField'

import React, { useState, useContext } from 'react'
import { AchievementCriteriaAPI } from '../../../services/api'
import { NotyfContext } from '../../../context/NotyfContext'
import AdminButton from './AdminButton'
import { useAchievements } from 'hooks/useAchievements'

type Props = {
  achievementId: string
  setShowCreateAchievementCriteria: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateAchievementCriteria({ setShowCreateAchievementCriteria, achievementId }: Props) {
  const [target, setTarget] = useState<number>(0)
  const [displayText, setDisplayText] = useState('')
  const [promptText, setPromptText] = useState('')
  const [congratsText, setCongratsText] = useState('')
  const [derived, setDerived] = useState(false)
  const [ruleId, setRuleId] = useState('')
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const notyf = useContext(NotyfContext)
  const { refreshAchievements } = useAchievements()

  interface ValidationErrors {
    [key: string]: string
  }
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  const validate = (): boolean => {
    setErrorMsg(null)
    // required

    let validity = true

    // collect all the errors and set them at once
    const localValidationErrors: ValidationErrors = {}

    if (target === 0 || !target) {
      console.log('target', target)
      localValidationErrors['target'] = 'target is required'

      validity = false
    }

    if (displayText === '') {
      console.log('displayText', displayText)
      localValidationErrors['displayText'] = 'Display Text is required'
      validity = false
    }

    if (promptText === '') {
      console.log('promptText', promptText)
      localValidationErrors['promptText'] = 'Prompt Text is required'
      validity = false
    }

    if (congratsText === '') {
      console.log('congratsText', congratsText)
      localValidationErrors['congratsText'] = 'Congrats Text is required'
      validity = false
    }

    setValidationErrors(localValidationErrors)
    if (!validity) {
      setErrorMsg('Please fill out all required fields')
    }
    return validity
  }

  const handleSubmit = async (event: React.FormEvent) => {
    setErrorMsg(null)
    event.preventDefault()

    if (validate() === true) {
      // Prepare the data to be sent to the API
      const achievementCriteriaData = {
        achievementId,
        target: Number(target),
        displayText,
        promptText,
        congratsText,
        derived,
        ruleId,
      }
      console.log({ achievementCriteriaData })
      // Call the API service to create the new achievement
      try {
        const result = await AchievementCriteriaAPI.create(achievementCriteriaData)
        if (result) {
          notyf.success('Achievement Criteria created successfully')
          refreshAchievements()
          setShowCreateAchievementCriteria(false)
        }
      } catch (error: unknown) {
        // the unhelpful helper is not passing the error message... hence...
        setErrorMsg('Error creating Achievement Criteria')
      }
    }
  }

  const renderErrors = () => {
    const errors = Object.values(validationErrors)
    console.log('errors', { errors })
    return errors.map((error, index) => {
      return (
        <li key={index} className="mx-2">
          {error}
        </li>
      )
    })
  }

  return (
    <div className="">
      <h2 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900 flex  justify-center">Create an Achievement Criteria</h2>
      {/* Back Button */}
      <AdminButton
        type="button"
        label="Back"
        onClick={() => {
          setShowCreateAchievementCriteria(false)
        }}
      />
      <form onSubmit={handleSubmit} className="w-full max-w-3xl mt-3 mx-auto">
        <div className="flex flex-col lg:flex-row lg:space-x-4">
          <div className="lg:w-1/2">
            <AdminInputField label="Target" value={target} setter={setTarget} type="number" />
            <AdminInputField label="Display Text" value={displayText} setter={setDisplayText} type="text" />
            <AdminInputField label="Prompt Text" value={promptText} setter={setPromptText} type="text" />
            <AdminInputField label="Congrats Text" value={congratsText} setter={setCongratsText} type="text" />
          </div>

          <div className="lg:w-1/2">
            <div className="w-full px-3 mt-6 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-derived">
                Derived
              </label>
              <input className="mr-2 leading-tight" type="checkbox" id="grid-derived" checked={derived} onChange={(e) => setDerived(e.target.checked)} />
            </div>
            <AdminInputField label="Rule ID" value={ruleId} setter={setRuleId} type="text" />
          </div>
        </div>

        <div className="flex justify-end">
          <AdminButton type="submit" label="Submit" />
        </div>
        {errorMsg && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2  text-xs rounded relative" role="alert">
            <strong className="font-bold mx-2">Oh snap!</strong> <br></br>
            <span className="block sm:inline whitespace-pre-line">{errorMsg && errorMsg}</span>
            <div className="mx-2">
              <ul className="list-disc list-inside">
                {
                  // aggregate the errors and set them as bullets to the bottom of the form
                  renderErrors()
                }
              </ul>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default CreateAchievementCriteria
