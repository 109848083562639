import React, { FC } from 'react'

type Props = {
  children: React.ReactNode
  active: boolean
  className?: string
}

const RedPill: FC<Props> = ({ active, className, children }: Props) => {
  return (
    <div
      className={`${active ? 'bg-slate-50 text-red-500' : 'bg-red-500  text-slate-50'} py-1  rounded-lg 
            font-extrabold mb-2 px-2 uppercase shadow-md shadow-red-900 w-fit text-center ${className}`}
    >
      {children}
    </div>
  )
}

export default RedPill
