import React from 'react';
import { Box, Button } from 'shared/components';
import { ActiveChallenge, WorkoutClass, WorkoutType } from 'types';
import ExampleOnDemand from 'assets/images/example-on-demand.png';

import styles from './OnDemand.module.scss';

interface OnDemandViewProps {
  workoutTypes: WorkoutType[];
  workoutClasses: WorkoutClass[];
  activeChallenge?: ActiveChallenge | null;
  onClickWorkoutType: (category: string) => void;
  onClickWorkoutClass: (category: string) => void;
  onClickWorkoutFavorites: () => void;
  onClickFindChallenge: () => void;
}

export const OnDemandView: React.FC<OnDemandViewProps> = ({
  workoutTypes,
  workoutClasses,
  activeChallenge,
  onClickWorkoutType,
  onClickWorkoutClass,
  onClickWorkoutFavorites,
  onClickFindChallenge,
}) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        {!activeChallenge && (
          <Box className={styles.header}>
            <img src={ExampleOnDemand} className={styles.headerImage} />
            <p className={styles.headerTitle}>JOIN THE CHALLENGE TODAY</p>
            <p className={styles.headerDescription}>Discover a challenge that suits you best.</p>
            <Button containerStyle={styles.headerBtn} label="Find a Challenge" onClick={onClickFindChallenge} />
          </Box>
        )}
        <Box className={styles.categoryRow}>
          {workoutTypes.map((type) => {
            const Icon = type.icon && typeof type.icon !== 'string' ? (type.icon as React.FC<React.SVGProps<SVGSVGElement>>) : null;

            if (type.title === 'Favorites') {
              return (
                <button onClick={onClickWorkoutFavorites} key={type.id} className={styles.categoryContainer}>
                  <Box className={styles.categoryIconContainer}>{Icon ? <Icon /> : <img src={(type.icon as string) || ''} />}</Box>
                  <p>{type.title}</p>
                </button>
              );
            }

            return (
              <button onClick={() => onClickWorkoutType(type.id)} key={type.id} className={styles.categoryContainer}>
                <Box className={styles.categoryIconContainer}>{Icon ? <Icon /> : <img src={(type.icon as string) || ''} />}</Box>
                <p>{type.title}</p>
              </button>
            );
          })}
        </Box>
        <Box className={styles.classTypesContainer}>
          {workoutClasses.map((classType) => {
            return (
              <button onClick={() => onClickWorkoutClass(classType.id)} key={classType.id} className={styles.classTypeContainer}>
                <img src={`${classType.image}?w=700`} className={styles.classTypeImage} />
                <p className={styles.classTypeTitle}>{classType.title}</p>
              </button>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
