import React from 'react';

type Props = {
  children: React.ReactNode;
};

export default function Benefit({ children }: Props) {
  return (
    <div className=" flex space-x-3  justify-items-start">
      <div className="w-4 h-4  lg:w-6 lg:h-6 text-slate-50 lg:mt-1 justify-center items-center  bg-gradient-to-r from-[#FE4D47] to-[#E92A2A] rounded-full ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className=" w-4 h-4 lg:w-6 lg:h-6  "
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      </div>
      <div className="font-semibold text-slate-900 text-sm md:text-md xl:text-lg">{children}</div>
    </div>
  );
}
