import firebase from './config'
import { resetUserStateAction } from 'context/actions/authStateActions'
import { AuthActionTypes } from 'types'
const { auth } = firebase

interface UserAuthData {
  email: string
  password: string
}

interface CreateUserAuthData extends UserAuthData {
  firstName?: string
}

export const loginFirebaseUserWithEmail = async (data: UserAuthData): Promise<firebase.auth.UserCredential> => {
  try {
    console.log('loginFirebaseUserWithEmail: Logging in user with email:', data.email)
    const res = await auth().signInWithEmailAndPassword(data.email, data.password)
    console.log('loginFirebaseUserWithEmail: User logged in successfully:', res)
    return res
  } catch (error: any) {
    console.error('loginFirebaseUserWithEmail: Error logging in user:', error)
    throw new Error('Wrong password or invalid email')
  }
}

export const createFirebaseUserWithEmail = async (data: CreateUserAuthData): Promise<firebase.auth.UserCredential> => {
  try {
    console.log('createFirebaseUserWithEmail: Creating user with email:', data.email)
    const res = await auth().createUserWithEmailAndPassword(data.email, data.password)
    console.log('createFirebaseUserWithEmail: User created successfully:', res)
    return res
  } catch (error: any) {
    console.error('createFirebaseUserWithEmail: Error creating user:', error)
    let errorMessage = 'Something went wrong'

    if (error.message.includes('The email address is already in use')) {
      errorMessage = 'The email address is already in use by another account'
    } else if (error.message.includes('The given password is invalid.')) {
      errorMessage = 'The password requires at least 6 characters'
    }
    throw new Error(errorMessage)
  }
}

export const updateEmail = async (newEmail: string): Promise<void> => {
  const currentUser = auth().currentUser
  if (currentUser !== null) {
    console.log('updateEmail: Updating email to:', newEmail)
    return currentUser.updateEmail(newEmail)
  }
}

type FirebaseUpdateProfileOptions = { displayName?: string; photoUrl?: string }

export const updateProfile = async (options: FirebaseUpdateProfileOptions): Promise<void> => {
  const currentUser = auth().currentUser
  if (currentUser !== null) {
    console.log('updateProfile: Updating profile with options:', options)
    return currentUser.updateProfile(options)
  }
}

export const updatePassword = async (newPassword: string): Promise<void> => {
  const currentUser = auth().currentUser
  if (currentUser !== null) {
    console.log('updatePassword: Updating password')
    return currentUser.updatePassword(newPassword)
  }
}

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  console.log('sendPasswordResetEmail: Sending password reset email to:', email)
  return auth().sendPasswordResetEmail(email, {
    url: `${process.env.REACT_APP_APP_URL}/login`,
  })
}

/**
 * Completes the password reset process, given a confirmation code and new password.
 * @param {string} code
 * @param {string} password New Password entered
 */
export const confirmPasswordReset = (code: string, password: string): Promise<void> => {
  console.log('confirmPasswordReset: Confirming password reset with code:', code)
  return auth().confirmPasswordReset(code, password)
}

/**
 * Checks a password reset code sent to the user by email or other out-of-band mechanism.
 * @param {string} code
 */
export const verifyPasswordResetCode = (code: string): Promise<string> => {
  console.log('verifyPasswordResetCode: Verifying password reset code:', code)
  return auth().verifyPasswordResetCode(code)
}

export const signOut = async (dispatch: React.Dispatch<AuthActionTypes>): Promise<void> => {
  try {
    console.log('signOut: Signing out user')
    await auth().signOut()
    dispatch(resetUserStateAction())
  } catch (error: any) {
    console.error('signOut: Error signing out user:', error)
  }
}
