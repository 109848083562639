import { useQuery } from 'react-query'
import { Product } from 'types'
import { Price } from 'types'
import { ProductsAPI } from '../services/api'

const useProducts = () => useQuery('products', ProductsAPI.getProducts, { staleTime: Infinity })

const getPriceOfProduct = (productId: string, products: Product[], prices: Price[]) => {
  const product = products.find((product) => product.id === productId)
  let defaultPriceId: string | undefined

  if (product) {
    defaultPriceId = product.default_price
  }
  if (defaultPriceId) return prices.find((price) => price.id === defaultPriceId)

  return undefined
}

export { useProducts, getPriceOfProduct }
