import React, { useCallback } from 'react'
import { Workout } from 'types'
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites'
import { ReactComponent as FavoriteStar } from 'assets/icons/favorite-star.svg'
import { useHistory } from 'react-router'
import { Box } from 'shared/components'
import styles from 'shared/components/Workout/Workout.module.scss'
import { ReactComponent as Play } from 'assets/icons/play.svg'

import RedPill from '../features/SignUp/components/RedPill'

type Props = {
  workout: Workout
  locked: boolean
  optional?: boolean
  showCompletedIcon?: boolean
  classNames?: string
}
export const FlexWorkout: React.FC<Props> = ({ workout, locked, optional, classNames, showCompletedIcon = true }) => {
  if (!workout) return null
  const history = useHistory()
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites()
  const { previewImageUrl } = workout || {}
  const isFavorite = workoutFavorites?.find((wf) => workout.id === wf.workout_id)

  const handleFavoriteClick = async () => {
    console.log('handleFavoriteClick :>> ', workout, isFavorite)

    try {
      if (isFavorite) {
        await mutateRemoveFavorite({ workout_id: workout.id })
      } else {
        await mutateAddFavorite({ workout_id: workout.id })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Mutate:error :>> ', error)
    }
  }

  const handlePlayClick = useCallback(() => {
    console.log('Navigating to workout overview:', workout)
    if (workout.challenge_workout) {
      history.push(`/workouts/${workout.id}/overview?challengeWorkout=${workout.challenge_workout.id}`, {
        workout: workout,
      })
    } else {
      history.push(`/workouts/${workout.id}/overview`, {
        workout: workout,
      })
    }
  }, [history, workout])

  return (
    <Box className={`${styles.workoutItem} mt-1.5 mb-2 w-80 snap-center snap-always ${classNames}`} key={`${workout.id + 'flexWorkout'}`}>
      {showCompletedIcon
        ? workout?.completed_date && (
            <RedPill className={'z-10 absolute inset-2  h-7'} active={false}>
              {' '}
              Completed
            </RedPill>
          )
        : null}
      <button onClick={() => handleFavoriteClick()} className={`${styles.favoriteStar}`}>
        <FavoriteStar className={isFavorite ? styles.isFavorite : ''} />
      </button>
      <Box className="overlay" />
      {previewImageUrl && <img src={previewImageUrl} className={styles.workoutImagePreview} />}

      <Box className={styles.workoutInfo}>
        <p className={styles.workoutTitle}>{workout.title}</p>
        <p className={styles.workoutDescription}>
          {workout.duration?.title}
          {optional ? ' | Optional' : ''}
        </p>
      </Box>
      {locked && (
        <button className="flex h-8 w-8 z-10 rounded-full  justify-center bg-coral">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-6 h-6 stroke-slate-50 pt-1.5">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
        </button>
      )}
      {!locked && (
        <button
          onClick={() => {
            handlePlayClick()
          }}
          className={` ${styles.playBtn} flex bg-coral `}
        >
          <Play />
        </button>
      )}
    </Box>
  )
}
