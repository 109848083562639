import React, { memo, useMemo, useState } from 'react'
import styles from './WorkoutOverview.module.scss'
import { Box, Button, FinisherItem, LoadingIndicator, VideoPlayer } from 'shared/components'
// import { useVideoPlayerContext } from '../../shared/components/VideoPlayer/VideoPlayerContext'
import FlexFinisher from 'components/FlexFinisher'
import { Workout } from 'types'
import { useLocation } from 'react-router-dom'

interface WorkoutOverviewLocationState {
  workout?: Workout
}

const WorkoutOverview = memo(() => {
  const { state: locationState = {} } = useLocation<WorkoutOverviewLocationState>()
  const workout = locationState?.workout
  const [workOutIsFinished, setWorkOutIsFinished] = useState(false)

  let hasFinisher = false
  let finisher = null
  if (workout && workout.finisher) {
    hasFinisher = true
    finisher = workout.finisher.workout
  }

  if (!workout) {
    return null
  }

  const onkWorkoutComplete = () => {
    console.log('onkWorkoutComplete :>> ', workout)
    setWorkOutIsFinished(true)
  }

  const videoElement = useMemo(() => {
    return <VideoPlayer workout={workout} handleComplete={onkWorkoutComplete} />
  }, [workout])

  return (
    <Box className={styles.container}>
      {videoElement}
      <div className="vjs-playlist" />
      <Box className={styles.contentContainer}>
        <p className={styles.workoutTitle}>{workout?.title}</p>
        <p className={styles.workoutSubTitle}>{`${workout?.equipment ? 'Requires Equipment' : 'No Equipment'} • ${workout?.duration.title}`}</p>
        <Box className={styles.content}>
          {/* {showWorkoutComplete && (
            <Button
              label="End Workout"
              onClick={onClickWorkoutComplete}
              loading={createCompletedLoading}
              classNames={`mt-2 h-12 w-full justify-center rounded-2xl transition-all border border-transparent bg-coral px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-coral-dark focus:outline-none focus:ring-2 focus:ring-coral-dark focus:ring-offset-2 sm:text-sm`}
            />
          )} */}
          <p className={styles.headerTitle}>WORKOUT SUMMARY</p>
          <p className={styles.workoutSummary}>{workout?.summary}</p>

          {hasFinisher && finisher && (
            <Box className={styles.finisherSection}>
              <p className={styles.headerTitle}>RECOMMENDED FINISHER</p>

              <FlexFinisher key={`finisher-${finisher.id}}`} finisher={finisher} parentWorkout={workout} useContext={true} size="large" />
            </Box>
          )}
          {/* {isFinisher && <Button containerStyle={styles.backToWorkoutBtn} label="Back to Workout" onClick={onClickBackToWorkout} />} */}
        </Box>
      </Box>
    </Box>
  )
})

WorkoutOverview.displayName = 'WorkoutOverview'
export { WorkoutOverview }
