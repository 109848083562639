import React, { useCallback } from 'react'
import { Challenge } from 'types'
import { Box } from '../Box/Box'
import styles from './ChallengeItem.module.scss'
import { ChallengeUtils } from '../../../shared/functions'
import RedPill from 'features/SignUp/components/RedPill'

type ChallengeItemProps = {
  item: Challenge
  handleClick: (item: Challenge) => void
  itemId: string
  completedChallenges: { [key: string]: number } | null
  releaseDate: string
}

const renderPillIfCompleted = (completedChallenges: { [key: string]: number } | null, challengeId: string) => {
  if (completedChallenges && completedChallenges[challengeId] > 0) {
    console.log('found one that was completed')
    return (
      <div className={`absolute bg-green-500 text-slate-50 p-1 rounded-lg font-extrabold   uppercase shadow-md shadow-green-900 z-10  flex  text-lg  `}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="currentColor" className="w-8 h-8">
          <path d="M12 7.00002C16.4183 7.00002 20 10.5817 20 15C20 19.4183 16.4183 23 12 23C7.58172 23 4 19.4183 4 15C4 10.5817 7.58172 7.00002 12 7.00002ZM12 10.5L10.6775 13.1797L7.72025 13.6094L9.86012 15.6953L9.35497 18.6406L12 17.25L14.645 18.6406L14.1399 15.6953L16.2798 13.6094L13.3225 13.1797L12 10.5ZM13 1.99902L18 2.00002V5.00002L16.6366 6.13758C15.5305 5.55773 14.3025 5.17887 13.0011 5.04951L13 1.99902ZM11 1.99902L10.9997 5.04943C9.6984 5.17866 8.47046 5.55738 7.36441 6.13706L6 5.00002V2.00002L11 1.99902Z"></path>
        </svg>
        <div className="pt-2">{completedChallenges[challengeId]}</div>
      </div>
    )
  }
  return null
}

export const ChallengeItem: React.FC<ChallengeItemProps> = ({ item, handleClick, itemId, completedChallenges, releaseDate }) => {
  const onClickItemCallback = useCallback(() => {
    handleClick(item)
  }, [handleClick, item])

  // output release date in mmm dd format
  const date = new Date(item.releaseDate).toLocaleString('en-US', { month: 'short', day: 'numeric' })
  return (
    <button key={itemId} onClick={onClickItemCallback} className={`flex flex-col w-[334px] p-3 `}>
      <div className="h-40 w-full bg-gradient-to-b from-[rgba(25,158,181,0)] to-[rgba(2,158,183,0.38)] rounded-xl relative overflow-hidden">
        {!ChallengeUtils.isReleased(releaseDate) && (
          <RedPill className=" absolute w-100   ml-1 mt-1 z-10 text-slate-100 bg-coral" active={false}>
            Launches: {date}
          </RedPill>
        )}
        {renderPillIfCompleted(completedChallenges, item.id)}
        <img src={`${item.image}?w=310&h=160&fit=fill&q=90`} alt="Descriptive Text" className={` z-0 absolute top-0 left-0 w-full h-[160px] object-cover`} />
      </div>
    </button>
  )
}
