import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'
import { ChallengeContext } from 'context/ChallengeContext'
import { useContext } from 'react'
import { ChallengeCategory } from 'types'

const tabsIconPairing = [
  { id: 'strength', displayName: 'Strength', icon: 'Dumbbell' },
  { id: 'bodyweight', displayName: 'Bodyweight', icon: 'User' },
  { id: 'lowImpact', displayName: 'Low Impact', icon: 'Heart' },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  onClickItem: (item: ChallengeCategory | null) => void
  selectedItem: ChallengeCategory | null
}

const getIcon = (displayName: string) => {
  const icon = tabsIconPairing.find((tab) => tab.displayName === displayName)
  return icon?.icon
}

const ButtonBar = ({ onClickItem, selectedItem }: Props) => {
  const { state: challengesContext } = useContext(ChallengeContext)

  let tabs: ChallengeCategory[] = []

  if (challengesContext.categories) {
    tabs = challengesContext.categories.sort((a, b) => a.orderNumber - b.orderNumber)

    tabs = tabs.map((tab) => ({
      ...tab,
      icon: getIcon(tab.displayName),
    }))
  }
  // categories from context

  return (
    <div className="mt-4">
      <div className="md:hidden flex justify-center">
        <Menu as="div" className="relative inline-block text-center">
          <div className=" ">
            <Menu.Button className="inline-flex  justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {selectedItem?.displayName ?? 'Categories'}
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-50 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item key={`menu-item-tab-all`}>
                  {({ active }) => (
                    <a href="#" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')} onClick={() => onClickItem(null)}>
                      All
                    </a>
                  )}
                </Menu.Item>
                {tabs.map((tab) => (
                  <Menu.Item key={`menu-item-tab-${tab.displayName}`}>
                    {({ active }) => (
                      <a href="#" className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')} onClick={() => onClickItem(tab)}>
                        {tab.displayName}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="hidden md:block w-full">
        <div className="-mb-px justify-center flex space-x-8" aria-label="Tabs">
          <button
            className={`  ${
              selectedItem === null ? 'bg-teal text-slate-50 font-extrabold' : 'text-slate-500 hover:text-slate-700'
            } 'border-transparent border-b-2  hover:border-teal py-4 px-2  font-medium rounded-full text-sm  w-28`}
            onClick={() => onClickItem(null)}
          >
            <div>All</div>
          </button>

          {tabs.map((tab) => (
            <button
              key={tab.displayName}
              className={`  ${
                selectedItem?.displayName == tab.displayName ? 'bg-teal text-slate-50 font-extrabold' : 'text-gray-500 hover:text-slate-700'
              } 'border-transparent  border-b-2  hover:border-teal py-4 px-2  font-medium rounded-full text-sm  w-28`}
              onClick={() => onClickItem(tab)}
            >
              <span>{tab.displayName}</span>
            </button>
          ))}
        </div>
        <div className="mt-5 text-center text-slate-800 text-md">{selectedItem?.description ?? 'All Challenges and Series'}</div>
      </div>
    </div>
  )
}

export { ButtonBar }
