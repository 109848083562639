import { Challenge } from '../../types/index'

const isReleased = (date: string): boolean => {
  if (date) {
    const releaseDate = new Date(date)
    const now = new Date()
    return releaseDate < now
  }

  return false
}

// If a challenge's releaseDate is within 7 days of today, it's considered Queable
const isQueueable = (date: string): boolean => {
  if (date) {
    const releaseDate = new Date(date)
    const now = new Date()
    let sevenDaysFromNow = new Date()
    sevenDaysFromNow = new Date(sevenDaysFromNow.setDate(now.getDate() + 7))
    return releaseDate <= sevenDaysFromNow && releaseDate > now
  }
  return false
}

export { isReleased, isQueueable }
