import React from 'react'
import { ReactComponent as Shield } from 'assets/icons/shield.svg'
import { ReactComponent as Support } from 'assets/icons/support.svg'
import { ReactComponent as Logout } from 'assets/icons/logout.svg'

import { ReactComponent as Thunder } from 'assets/icons/thunder.svg'
import { ReactComponent as Carrot } from 'assets/icons/carrot.svg'
import { ReactComponent as Scale } from 'assets/icons/kitchen-scale.svg'
import { ReactComponent as Camera } from 'assets/icons/camera.svg'

export type SettingOption = {
  title: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
}

export const SettingsOptions: SettingOption[] = [
  {
    title: 'Support',
    icon: Support,
  },
  {
    title: 'Privacy Policy',
    icon: Shield,
  },
  {
    title: 'Logout',
    icon: Logout,
  },
]

export const ResourceOptions: SettingOption[] = [
  {
    title: 'How your Challenge Works',
    icon: Support,
  },
  {
    title: 'All About Lean Fuel',
    icon: Support,
  },
  {
    title: 'Intermittent Fasting',
    icon: Support,
  },
  {
    title: 'F.A.Q',
    icon: Support,
  },
]

export type UserOption = {
  id: string
  title: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
}

export const UserOptions: UserOption[] = [
  {
    id: 'challenge',
    title: 'Challenge',
    icon: Thunder,
  },
  {
    id: 'meal-preference',
    title: 'Meal Preference',
    icon: Carrot,
  },
  {
    id: 'measurements',
    title: 'Measurements',
    icon: Scale,
  },
  {
    id: 'transformation',
    title: 'Transformation Photos',
    icon: Camera,
  },
  {
    id: 'workout-history',
    title: 'Workout History',
  },
  {
    id: 'subscription',
    title: 'Subscription',
  },
]

export type OptionType = { label: string; value: string }

const generateOptionsfromRange = (start: number, end: number, type: string): OptionType[] => {
  const result: OptionType[] = []
  for (let index = start; index <= end; index++) {
    result.push({
      label: `${index} ${type}`,
      value: `${index}`,
    })
  }
  return result
}

export const heightOptionsMetric: OptionType[] = generateOptionsfromRange(92, 243, 'cm')
export const heightOptionsImperial: OptionType[] = generateOptionsfromRange(36, 96, 'in')

export const weightOptionsImperial: OptionType[] = generateOptionsfromRange(55, 500, 'lb')
export const weightOptionsMetric: OptionType[] = generateOptionsfromRange(25, 227, 'kg')

export const unitOfMeasurementOptions: OptionType[] = [
  {
    label: 'Metric',
    value: 'metric',
  },
  {
    label: 'Imperial',
    value: 'imperial',
  },
]

export const genderOptions = [
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Non-Binary',
    value: 'Non-Binary',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
]

export const optionsByUnit = {
  imperial: {
    height: heightOptionsImperial,
    weight: weightOptionsImperial,
  },
  metric: {
    height: heightOptionsMetric,
    weight: weightOptionsMetric,
  },
}
