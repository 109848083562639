import { StorageValue } from 'hooks/useLocalStorage'

/**
 * Access item directly, in cases where state is not updated in time.
 */
export const getItem = (key: string, initialValue: StorageValue): StorageValue => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // If error also return initialValue
    console.log(error)
    return initialValue
  }
}

/**
 * Remove Item from localStorage given key. Clears from state as well.
 */
export const removeItem = (key: string): void => {
  window.localStorage.removeItem(key)
}
