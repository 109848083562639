import React from 'react';
import styles from '../SignUp.module.scss';
import { PageHeader, Button } from 'antd';
import logo from '../assets/logo.png';

interface HeaderProps {
  onPressLogin: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onPressLogin }) => {
  return (
    <PageHeader
      avatar={{
        src: logo,
        alt: 'logo',
        className: styles.header__logo,
      }}
      style={{ position: 'fixed', zIndex: 1, width: '100%' }}
      className={styles.header}
      extra={[
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          key="2"
        >
          <p className={styles.header__text}>Already have an account?</p>
          <Button onClick={onPressLogin} className={styles.header__login} type="primary">
            Login
          </Button>
        </div>,
      ]}
    />
  );
};
