import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { FirebaseAuth } from 'services/firebase';
import { AuthContainer, Box, Button, Input } from 'shared/components';
import { Validations } from 'shared/functions';
import { FormValidationError } from 'types';
import styles from './Login.module.scss';

interface FormState {
  email?: string;
  password?: string;
  loading?: boolean;
  errors?: FormValidationError;
}

type FORM_ACTIONS = {
  type: 'update';
  payload: FormState;
};

const initialState: FormState = {
  email: '',
  password: '',
  loading: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const Login: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          ...state.errors,
          [name]: undefined,
        },
      },
    });
  };

  const onClickLogin = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
        errors: {},
      },
    });

    const required = { email, password };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      await FirebaseAuth.loginFirebaseUserWithEmail({ email, password });
      dispatch({
        type: 'update',
        payload: {
          loading: false,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Login Error :>> ', error);
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: error.message,
          },
        },
      });
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onClickLogin();
    }
  };

  const { email = '', password = '', loading, errors = {} } = state;

  return (
    <AuthContainer>
      <p className={styles.headline}>Welcome Back!</p>

      <Box className={styles.inputsContainer}>
        <Input containerStyle={styles.input} value={email} onChange={onChange} name="email" placeholder="Email" error={errors.email} />
        <Input
          value={password}
          onChange={onChange}
          name="password"
          placeholder="Password"
          type="password"
          containerStyle={styles.input}
          error={errors.password}
          onKeyDown={onKeyDown}
        />
        <Link to="/forgot-password" className={styles.forgotPasswordLink}>
          Forgot Password?
        </Link>
      </Box>
      <Box className={styles.footer}>
        {errors.general && <p className={`errorText ${styles.errorMessage}`}>{errors.general}</p>}
        <Button
          onClick={onClickLogin}
          loading={loading}
          containerStyle={styles.actionBtn}
          label="Login"
          disabled={email.length === 0 || password.length === 0}
        />

        <p className={styles.createAccountLink}>
          {`Don't have an account?`} <Link to="/sign-up">Sign Up</Link>
        </p>
      </Box>
    </AuthContainer>
  );
};
