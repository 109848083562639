import React from 'react';
import styles from './OnDemandCategory.module.scss';
import { useHistory } from 'react-router';
import { useWorkoutData } from './hooks/useWorkoutData';
import { Box, FilterOption, LoadingIndicator, WorkoutItem } from 'shared/components';
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites';
import { Difficulty, Duration, FiltersQuery, Workout, WorkoutClass, WorkoutFilterOptions } from 'types';
import { useQuery } from 'react-query';
import { getAllWorkoutFilterOptions } from 'services/api/workouts';
import useScrollPosition from 'hooks/useScrollPosition';
import { DocumentUtils } from 'shared/functions';

export const OnDemandCategory: React.FC = () => {
  const history = useHistory();
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites();
  const { selectedType, setFilters, filters, workouts, workoutsStatus, workoutsLoading } = useWorkoutData();
  const { data: filterOptions } = useQuery('workoutFilterOptions', getAllWorkoutFilterOptions, {
    staleTime: Infinity,
  });

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, workoutsLoading ? false : true);

  const handleFavoriteClick = async (item: Workout, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await mutateRemoveFavorite({ workout_id: item.id });
        // Check if type is on Favorites and Invalidate Queries 'workouts' to remove from list?
      } else {
        await mutateAddFavorite({ workout_id: item.id });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Mutate:error :>> ', error);
    }
  };

  const handleFilterClick = (type: keyof FiltersQuery, filter: Duration | Difficulty | WorkoutClass) => {
    setFilters((prev) => {
      let prevCopy = { ...prev };
      let newValue = undefined;
      switch (filter.filterByKey) {
        case 'length':
          if ('length' in filter) {
            const lengths = prevCopy[type] as number[];
            if (lengths.includes(filter.length)) {
              delete prevCopy[type];
              return prevCopy;
            }
            newValue = filter.length;
          }
          break;
        case 'title':
          if (prevCopy[type] === filter.title) {
            delete prevCopy[type];
            return prevCopy;
          }
          newValue = filter.title;
          break;
        case 'id':
          if (prevCopy[type] === filter.id) {
            delete prevCopy[type];
            return prevCopy;
          }
          newValue = filter.id;
          break;

        default:
          break;
      }

      prevCopy = {
        ...prevCopy,
        [type]: newValue,
      };

      return prevCopy;
    });
  };

  const handlePlayClick = (item: Workout) => {
    setScrollYStorage(DocumentUtils.getContentDivScroll());
    history.push(`/workouts/${item.id}/overview`, {
      workout: item,
    });
  };

  // console.log('filters :>> ', filters);
  // console.log('workouts :>> ', workouts);
  // console.log('workoutFavorites :>> ', workoutFavorites);

  const { title, icon } = selectedType || {};

  const Icon = icon && typeof icon !== 'string' ? (icon as React.FC<React.SVGProps<SVGSVGElement>>) : null;

  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <Box className={styles.header}>
          <Box className={styles.categoryIconContainer}>{Icon ? <Icon /> : <img src={(icon as string) || ''} />}</Box>
          <p className={styles.headerTitle}>{title}</p>
        </Box>
        {selectedType && selectedType.image && selectedType.description && (
          <Box className={styles.banner}>
            <img src={(selectedType?.image as string) || ''} />
            <p>{selectedType?.description}</p>
          </Box>
        )}
        <Box className={styles.filterOptionsContainer}>
          {filterOptions &&
            Object.keys(filterOptions).map((key) => {
              const filterTitle = key
                // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                });

              const filtersByKey: Array<Difficulty | Duration | WorkoutClass> = filterOptions[key as keyof WorkoutFilterOptions];
              return (
                <FilterOption
                  key={key}
                  type={key as keyof FiltersQuery}
                  title={filterTitle}
                  options={filtersByKey}
                  onClick={handleFilterClick}
                  activeFilters={filters || null}
                />
              );
            })}
        </Box>
        {workoutsStatus === 'loading' ? (
          <LoadingIndicator containerStyle={styles.loadingContainer} />
        ) : (
          <Box className={styles.workoutsContainer}>
            {!workouts || workouts.length === 0 ? (
              <Box>
                <h4>No results found</h4>
              </Box>
            ) : (
              <>
                {workouts.map((item) => {
                  const isFavorite = workoutFavorites?.find((wf) => item.id === wf.workout_id);
                  return (
                    <WorkoutItem
                      key={item.id}
                      item={item}
                      title={item.title}
                      description={item.duration ? item.duration.title : ''}
                      isFavorite={!!isFavorite}
                      onClickFavorite={handleFavoriteClick}
                      onClickPlay={handlePlayClick}
                      locked={false}
                    />
                  );
                })}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
