import React, { useContext } from 'react'
import * as AchievementAPI from 'services/api/achievements'
import { NotyfContext } from 'context/NotyfContext'
import AdminButton from './AdminButton'
import { useAchievements } from 'hooks/useAchievements'
import AchievementForm from './AchievementForm' // Import the common form
import { AchievementType } from 'types'
import type { AchievementFormState } from 'types'
type Props = {
  setShowCreateAchievement: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateAchievement({ setShowCreateAchievement }: Props) {
  const notyf = useContext(NotyfContext)

  // use achievements
  const { refreshAchievements } = useAchievements()

  const submitCallback = async (formState: AchievementFormState) => {
    console.log('submitCallback', { formState })
    const result = await AchievementAPI.create({
      ...formState,
      durationDays: Number(formState.durationDays),
      oneStarMinPercent: Number(formState.oneStarMinPercent),
      twoStarMinPercent: Number(formState.twoStarMinPercent),
      threeStarMinPercent: Number(formState.threeStarMinPercent),
      type: AchievementType[formState.type],
      completedCount: 0,
    })

    if (result) {
      notyf.success('Achievement updated successfully')
    } else {
      notyf.error('Error updating achievement')
    } //
    refreshAchievements()
  }

  return (
    <div className="">
      <h2 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900 flex  justify-center">Create an Achievement</h2>
      <AdminButton
        type="button"
        label="Back"
        onClick={() => {
          setShowCreateAchievement(false)
        }}
      />
      <AchievementForm submitCallback={submitCallback} />
    </div>
  )
}

export default CreateAchievement
