/* eslint-disable no-extra-boolean-cast */
import { useState } from 'react'
import { AchievementType } from 'types'
import type { AchievementFormState, ValidationErrors } from 'types'

// type AchievementType = 'EVENT' | 'DYNAMIC' | 'REGULAR' // Update this to match your actual enum or type

const defValues: AchievementFormState = {
  name: '',
  summaryMd: '',
  descriptionMd: '',
  imageUrl: '',
  type: AchievementType.DYNAMIC,
  startDate: '',
  endDate: '',
  durationDays: 0,
  requiresOptIn: false,
  badgeImageUrl: '',
  active: true,
  available: true,
  oneStarMinPercent: 50,
  twoStarMinPercent: 75,
  threeStarMinPercent: 95,
  publishDate: '',
  starCongratsText: 'Congratulations!',
  ruleSetId: '',
  validationErrors: {},
  errorMsg: null,
}

export const useAchievementForm = (initialState: AchievementFormState = defValues) => {
  const [formState, setFormState] = useState(initialState)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  // const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  console.log('Form Populating with useAchievementForm', { formState })
  const validate = (): boolean => {
    setErrorMsg(null)

    // collect all the errors and set them at once
    const localValidationErrors: ValidationErrors = {}
    let validity = true
    // console.log('name', formState.name)

    // Check fields required for all types
    if (!formState.name) {
      localValidationErrors['name'] = 'Name is required'
      validity = false
    }
    if (!formState.descriptionMd) {
      localValidationErrors['descriptionMd'] = 'descriptionMd is required'
      validity = false
    }
    if (!formState.publishDate) {
      localValidationErrors['publishDate'] = 'Publish Date is required'
      validity = false
    }

    if (!formState.startDate) {
      localValidationErrors['startDate'] = ' Start Dates is always required'
      validity = false
    }

    // if formState.type === 'EVENT' then both start and end date are required and duration should be null

    if (formState.type === AchievementType.EVENT) {
      if (!formState.startDate) {
        localValidationErrors['startDate'] = '  EVENT achievements require an end date'
        validity = false
      }
      if (!formState.endDate) {
        localValidationErrors['endDate'] = ' EVENT achievements require an end date'
        validity = false
      }
      if (formState.durationDays !== 0) {
        localValidationErrors['durationDays'] = 'Duration should be empty or 0'
        validity = false
      }
    }
    // if formState.type === 'DYNAMIC' then duration is required and start and end date should be null
    if (formState.type === AchievementType.DYNAMIC) {
      if (formState.endDate && formState.endDate !== '') {
        localValidationErrors['endDate'] = ' End Date should be empty'
        validity = false
      }

      if (formState.durationDays <= 0) {
        localValidationErrors['durationDays'] = 'Duration must not be 0 For Dynamic Achievements'
        validity = false
      }
    }
    if (!formState.badgeImageUrl) {
      localValidationErrors['badgeImageUrl'] = 'badgeImageUrl must be a valid URL or you will make Squadies cry'
      validity = false
    }

    // setValidationErrors(localValidationErrors)
    setFieldValue('validationErrors', localValidationErrors)
    if (!validity) {
      console.error('Validation errors: ', localValidationErrors)
      setErrorMsg('Please fill out all required fields')
    }
    return validity
  }

  const setFieldValue = (field: string, value: any) => {
    setFormState({
      ...formState,
      [field]: value,
    })
  }

  const handleSubmit = async (event: React.FormEvent, submitCallback: (formState: AchievementFormState) => void) => {
    event.preventDefault()
    if (!validate()) return
    submitCallback(formState)
  }

  return {
    formState,
    setFieldValue,
    handleSubmit,
  }
}
