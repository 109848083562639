import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseAuth } from 'services/firebase';
import { AuthContainer, Box, Button, Input } from 'shared/components';
import { Validations } from 'shared/functions';
import { FormValidationError } from 'types';
import styles from './ForgotPassword.module.scss';

interface FormState {
  email?: string;
  loading?: boolean;
  errors?: FormValidationError;
}

type FORM_ACTIONS = {
  type: 'update';
  payload: FormState;
};

const initialState: FormState = {
  email: '',
  loading: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const ForgotPassword: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          [name]: undefined,
        },
      },
    });
  };

  const onClickReset = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
      },
    });

    const required = { email };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      await FirebaseAuth.sendPasswordResetEmail(email);
      dispatch({
        type: 'update',
        payload: {
          loading: false,
        },
      });
      setShowSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: error.message,
          },
        },
      });
    }
  };

  const onClickGoBack = () => {
    history.goBack();
  };

  const { email = '', loading, errors = {} } = state;

  return (
    <AuthContainer>
      {showSuccess ? (
        <>
          <p className={styles.headline}>Reset Password</p>
          <p className={styles.subHeading}>Please check your email for a link to reset your password.</p>
          <Button onClick={onClickGoBack} containerStyle={styles.actionBtn} label="Go Back" />
        </>
      ) : (
        <>
          <p className={styles.headline}>Enter the email you used for LeanSquad</p>
          <p className={styles.subHeading}>
            If we have an account with your email entered, we will send you a link to reset your password.
          </p>

          <Box className={styles.inputsContainer}>
            <Input containerStyle={styles.input} value={email} onChange={onChange} name="email" placeholder="Email" error={errors.email} />
          </Box>
          <Box className={styles.footer}>
            {errors.general && <p className={`errorText ${styles.errorMessage}`}>{errors.general}</p>}
            <Button
              onClick={onClickReset}
              loading={loading}
              containerStyle={styles.actionBtn}
              label="Reset Password"
              disabled={email.length === 0}
            />
          </Box>
        </>
      )}
    </AuthContainer>
  );
};
