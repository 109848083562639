import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { AuthLayout, DefaultLayout } from 'containers';
import { AuthedRoute } from './AuthedRoute';
import { authRoutes } from './routes';
import { firebase } from 'services/firebase';

export const Router: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    const listener = history.listen((location) => {
      firebase.analytics().setCurrentScreen(`${location.pathname}${location.search ? location.search : ''}`);
    });
    return () => {
      listener();
    };
  }, []);
  return (
    <Switch>
      {authRoutes.map(({ path, name, exact }) => (
        <Route path={path} key={name} component={AuthLayout} exact={exact} />
      ))}

      <AuthedRoute path="/" name="Default" component={DefaultLayout} />
    </Switch>
  );
};
