import React, { useCallback } from 'react'
import { Difficulty, Duration, FiltersQuery, WorkoutClass } from 'types'
import { Box } from '../Box/Box'
import styles from './FilterOption.module.scss'

type Props = {
  type: keyof FiltersQuery
  title: string
  options: Array<Difficulty | Duration | WorkoutClass>
  onClick: (type: keyof FiltersQuery, option: Difficulty | Duration | WorkoutClass) => void
  activeFilters: FiltersQuery | null
}

export const FilterOption: React.FC<Props> = ({ type, title, options, onClick, activeFilters }) => {
  const onClickCallback = useCallback(
    (option: WorkoutClass | Duration | Difficulty) => {
      onClick(type, option)
    },
    [onClick, type],
  )

  return (
    <Box className={styles.filterOption}>
      <p className={styles.filterOptionsTitle}>{title}</p>
      <Box className={styles.filtersRow}>
        {options.map((option) => {
          let isActive = false
          if (activeFilters && activeFilters[type]) {
            switch (option.filterByKey) {
              case 'length':
                if ('length' in option) {
                  if (Number(activeFilters[type]) === option.length) {
                    isActive = true
                  }
                }
                break
              case 'title':
                if (activeFilters[type] === option.title) {
                  isActive = true
                }

                break
              case 'id':
                if (activeFilters[type] === option.id) {
                  isActive = true
                }
                break
              default:
                break
            }
          }
          return (
            <Box key={option.id} onClick={() => onClickCallback(option)} className={`${styles.filterItem} ${isActive ? styles.activeFilter : ''}`}>
              <p className={styles.filterTitle}>{option.title}</p>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
