import React, { createContext, useContext, useMemo } from 'react'
import { UserChallengeState } from 'types'
import { ChallengeAPI } from 'services/api'
import { useQuery } from 'react-query'
import { AuthStateContext } from './AuthStateContext'
import { dayjs } from 'shared/functions'
import { isValidSubscription } from 'shared/functions/subscriptions'
import * as UserChallengesAPI from 'services/api/userChallenges'

const initialState: UserChallengeState = {
  completedChallenges: undefined,
  completedChallengesLoading: true,
  activeChallenge: null,
  isFetching: true,
  error: undefined,
}

export const UserChallengeContext = createContext({
  state: initialState,
})
UserChallengeContext.displayName = 'UserChallengeContext'

export const UserChallengeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState

  const { data: completedChallenges, isLoading: completedChallengesLoading } = useQuery('completedChallenges', () => UserChallengesAPI.getAllCompletedUserChallengesCount(), {
    enabled: !!user && isValidSubscription(user?.subscription),
    staleTime: dayjs(dayjs().add(1, 'day').startOf('day')).diff(dayjs()),
    refetchOnWindowFocus: true,
    retry: false,
  })

  const { data: activeChallenge, isLoading: activeChallengeLoading } = useQuery('activeChallenge', () => ChallengeAPI.getUserActiveChallenge(), {
    enabled: !!user && isValidSubscription(user?.subscription),
    staleTime: dayjs(dayjs().add(1, 'day').startOf('day')).diff(dayjs()),
    refetchOnWindowFocus: true,
    retry: false,
  })

  const state: UserChallengeState = {
    completedChallenges: completedChallenges || null,
    completedChallengesLoading: completedChallengesLoading,
    activeChallenge: activeChallenge || null,
    isFetching: activeChallengeLoading,
    error: undefined,
  }

  console.log('UserChallengeProvider State:>> ', state)

  const value = useMemo(() => ({ state }), [state])

  // const value = { state }
  return <UserChallengeContext.Provider value={value}>{children}</UserChallengeContext.Provider>
}

export const UserChallengeContextConsumer = UserChallengeContext.Consumer
