import { get, post, patch, del } from './helpers'
import { Achievement } from '../../types'
import { DeleteResponse } from './types'

export const create = async (achievementData: Achievement) => {
  try {
    const createdAchievement = await post<Achievement>('/achievements', achievementData)
    return createdAchievement
  } catch (error) {
    console.error('Error creating achievement:', error)
    throw error
  }
}

// Get all Achievements
export const getAll = async () => {
  try {
    console.log('%cFetching achievements', 'color: lightgreen')
    const achievements = await get<Achievement[]>('/achievements')
    console.log(`%c${achievements.length} Achievements Loaded`, 'color: green')

    return achievements
  } catch (error) {
    console.error('Error getting achievements:', error)
    throw error
  }
}

// Patch an Achievement
export const update = async (achievementData: Achievement) => {
  console.log({ achievementData })
  try {
    const patchedAchievement = await patch<Achievement>(`/achievements/${achievementData.id}`, achievementData)
    return patchedAchievement
  } catch (error) {
    console.error('Error patching achievement:', error)
    throw error
  }
}

// Get associated achievements for a specific challenge
export const getAssociatedAchievementsForChallenge = async (challengeId: string, userId?: string) => {
  try {
    const queryParams = userId ? `?userId=${userId}` : ''
    const url = `challenges/${challengeId}/associatedLeanchievements${queryParams}`
    const associatedAchievements = await get<Achievement[]>(url)
    return associatedAchievements
  } catch (error) {
    console.error('Error getting associated achievements:', error)
    throw error
  }
}

export const remove = async (id: string): Promise<DeleteResponse> => {
  const response = await del<DeleteResponse>(`/achievements/${id}`)
  return response
}

export default {
  create,
  getAll,
  update,
  remove,
  getAssociatedAchievementsForChallenge,
}
