import React from 'react';
import styles from './LeanStreak.module.scss';
import { ReactComponent as Flame } from 'assets/icons/flame.svg';
import { ReactComponent as ArrowCircle } from 'assets/icons/arrow-circle.svg';
import { Box } from '../../shared/components';
import { useQuery } from 'react-query';
import { LeanStatsAPI } from 'services/api';
import { dayjs } from 'shared/functions';

type LeanStreakProps = {
  size?: 'sm' | 'lg';
};

export const LeanStreak: React.FC<LeanStreakProps> = ({ size }) => {
  const { data, isLoading } = useQuery('leanStreak', () => LeanStatsAPI.getUserStreaks(dayjs().endOf('day').toISOString()), {
    staleTime: 60 * 60 * 1000, // 60 minutes
    retry: false,
  });

  const { best_consecutive = 0, current_consecutive = 0, last_week_consecutive = 0 } = data || {};
  return (
    <Box className={styles.container}>
      <Box className={styles.currentContainer}>
        <Flame />
        <Box>
          <p className={styles.title}>{`${isLoading ? '-' : current_consecutive} ${current_consecutive === 1 ? 'day' : 'days'}`}</p>
          <p className={styles.description}>Current</p>
        </Box>
      </Box>
      {size && size === 'lg' && <div className={styles.divider} />}
      <Box className={styles.allTimeContainer}>
        <Flame />
        <Box>
          <p className={styles.title}>{`${isLoading ? '-' : best_consecutive} ${best_consecutive === 1 ? 'day' : 'days'}`}</p>
          <p className={styles.description}>All Time</p>
        </Box>
      </Box>
      {size && size === 'lg' && <div className={styles.divider} />}
      {size && size === 'lg' && (
        <Box className={styles.lastStreak}>
          <ArrowCircle className={`${last_week_consecutive < 0 ? styles.negativeCircle : ''}`} />
          <Box>
            <p className={styles.title}>
              {`${isLoading ? '-' : Math.abs(last_week_consecutive)} ${Math.abs(last_week_consecutive) === 1 ? 'day' : 'days'}`}
            </p>
            <p className={styles.description}>vs last week</p>
          </Box>
        </Box>
      )}
    </Box>
  );
};
