import React, { createContext, useReducer, useEffect, useRef, useContext, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { AuthState, AuthActionTypes, StorageObj, User, RequestError } from 'types'
import { firebase } from 'services/firebase'
import { fetchingUserFailAction, fetchingUserSuccessAction, resetUserStateAction } from './actions/authStateActions'
import { UserAPI } from 'services/api'
import { LocalStorage } from 'shared/functions'
import { useMutation, useQuery } from 'react-query'
import { NotyfContext } from './NotyfContext'
import { CreateUserOptions } from 'services/api/types'

const { auth } = firebase

const initialState: AuthState = {
  user: undefined,
  isFetching: true,
  error: undefined,
}

const reducer = (state: AuthState = initialState, action: AuthActionTypes) => {
  console.log('Reducer action:', action)
  switch (action.type) {
    case 'FETCHING_USER':
      return {
        ...state,
        user: undefined,
        isFetching: true,
        error: undefined,
      }
    case 'FETCHING_USER_SUCCESS':
      console.log('FETCHING_USER_SUCCESS: Setting user state:', action.user)
      return {
        ...state,
        user: action.user,
        isFetching: false,
        error: undefined,
      }
    case 'FETCHING_USER_FAIL':
      console.log('FETCHING_USER_FAIL: Error:', action.error)
      return {
        ...state,
        user: undefined,
        isFetching: false,
        error: action.error,
      }
    case 'RESET_STATE':
      return { ...initialState, isFetching: false }
    default:
      return state
  }
}

const defaultDispatch: React.Dispatch<AuthActionTypes> = () => initialState

export const AuthStateContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
})
AuthStateContext.displayName = 'AuthStateContext'

export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const unsubscriber = useRef<firebase.Unsubscribe | null>(null)
  const fetchingUser = useRef(false)
  const notyf = useContext(NotyfContext)

  const { refetch: refetchUser } = useQuery<User>('user', UserAPI.getUser, {
    enabled: false,
    retry: false,
    onSuccess: (res) => {
      console.log('refetchUser Success:', res)
      dispatch(fetchingUserSuccessAction(res))
      fetchingUser.current = false
    },
    onError: (error) => {
      console.error('refetchUser Error:', error)
      if (error instanceof Error) {
        dispatch(fetchingUserFailAction(error))
      } else {
        dispatch(fetchingUserFailAction(new Error('An unknown error occurred')))
      }
    },
  })

  const { mutate: mutateCreateUser } = useMutation((createOptions: CreateUserOptions) => UserAPI.createUser(createOptions), {
    onSuccess: (res) => {
      console.log('mutateCreateUser Success:', res)
      LocalStorage.removeItem('createAccount')
      dispatch(fetchingUserSuccessAction(res))
    },
    onError: (error: RequestError) => {
      console.error('mutateCreateUser Error:', error)
      notyf.error(error.message)
      dispatch(fetchingUserFailAction(error))
    },
  })

  const fetchUser = async (firebaseUser: firebase.User) => {
    try {
      console.log('fetchUser: Fetching User:', firebaseUser)
      const { data: user } = await refetchUser({ throwOnError: true })

      console.log('fetchUser: User fetched successfully from API:', user)
      if (user) {
        dispatch(fetchingUserSuccessAction(user))
      } else {
        throw new Error('User data is undefined')
      }
    } catch (error: any) {
      console.error('fetchUser: Error fetching user:', error)
      if (error.status && error.status === 404) {
        console.log('fetchUser: User not found, creating new user...')
        const { fullName } = (LocalStorage.getItem('createAccount', null) as StorageObj) || {}
        let firstName = ''
        let lastName = ''

        if (fullName) {
          const names = fullName.split(' ')
          firstName = names[0]
          lastName = names[names.length - 1]
        } else {
          console.log('fetchUser: No fullName found, getting from Firebase...')
          const authCurrentUser = auth().currentUser
          if (authCurrentUser && authCurrentUser.displayName) {
            const nameSplit = authCurrentUser.displayName.split(' ')
            firstName = nameSplit[0] || ''
            lastName = nameSplit[1] || ''
          }
        }

        const userEmail = auth().currentUser?.email || ''

        mutateCreateUser({
          email: userEmail,
          first_name: firstName || '',
          last_name: lastName || '',
        })
      } else {
        dispatch(fetchingUserFailAction(error))
      }
      fetchingUser.current = false
    }
  }

  useEffect(() => {
    console.log('Setting up Auth Listener')
    if (!unsubscriber.current) {
      unsubscriber.current = auth().onAuthStateChanged(
        (user) => {
          console.log('onAuthStateChanged: User detected:', user)
          if (user) {
            if (!fetchingUser.current) {
              fetchingUser.current = true
              console.log('User logged in:', user)
              fetchUser(user)
            }
          } else {
            console.log('No user logged in')
            dispatch(resetUserStateAction())
          }
        },
        (error) => {
          console.error('Auth Listener Error:', error)
          dispatch(resetUserStateAction())
        },
      )
    }

    return () => {
      console.log('Cleaning up Auth Listener')
      if (unsubscriber.current) {
        unsubscriber.current()
        unsubscriber.current = null
      }
    }
  }, []) // Run only on mount and unmount

  const value = useMemo(() => ({ state, dispatch }), [state])

  // const value = { state, dispatch }
  console.log('AuthStateProvider Rendered:', state)
  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

export const AuthStateConsumer = AuthStateContext.Consumer
