import { get } from './helpers'
import { LeanScoresResponse, LeanStreaksResponse } from './types'

export const getUserStreaks = async (start: string): Promise<LeanStreaksResponse> => {
  const response = await get<LeanStreaksResponse>(`/user/streaks?start=${start}`)
  return response
}

export const getUserScores = async (start: string): Promise<LeanScoresResponse> => {
  const response = await get<LeanScoresResponse>(`/user/scores?start=${start}`)
  return response
}
