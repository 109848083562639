import React, { useCallback, useMemo } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import styles from '../SignUp.module.scss'
import { useSignUpContext } from '../SignUpContext'
import countryList from 'react-select-country-list'
import Spinner from 'react-spinkit'
import { CardPaymentForm } from './CardPaymentForm'

const { Option } = Select

/**
 *  PaymentForm component wraps Card Payment Form
 * and handles the submission of the stripe createPaymentMethod
 * as well as loading/creating states
 * additional logic is provided via the on onPaymentSuccess (creating a payment method) callback
 */
export const PaymentForm: React.FC = () => {
  const { userDetails, onPaymentSuccess, creatingAccount, setCreatingAccount } = useSignUpContext()

  const stripe = useStripe()
  const elements = useElements()

  const onSubmitPaymentDetails = useCallback(
    async (postalCode: string, country: string) => {
      if (creatingAccount) {
        return
      }

      try {
        if (stripe && elements) {
          setCreatingAccount(true)
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              name: userDetails.fullName,
              email: userDetails.email,
              address: {
                postal_code: postalCode,
                country: country,
              },
            },
          })

          if (paymentMethod) {
            await onPaymentSuccess(paymentMethod, userDetails.fullName)
          }
        }
      } catch (e) {
        setCreatingAccount(false)
        console.log(e)
      }
    },
    [stripe, elements, userDetails],
  )
  return <CardPaymentForm onSubmitPaymentDetails={onSubmitPaymentDetails} creatingAccount={creatingAccount} />
}
