import { ShowPromoCodeSetting } from './ShowPromoCodeSetting'
import React, { useEffect, useState } from 'react'
import { SettingsAPI } from 'services/api'
import { AdminFeaturedChallengeInput } from './AdminFeaturedChallengeInput'
import { Challenge } from 'types'
import { AssociatedAchievementsTable } from './AssociatedAchievementsTable'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AdminSettings = () => {
  const [showPromoContactUs, setShowPromoContactUs] = useState<boolean | null>(null)
  const [featuredChallenges, setFeaturedChallenges] = useState<null | Challenge[]>(null)
  useEffect(() => {
    getShowPromoContactUs()
    getFeaturedChallenges()
  }, [])

  const getFeaturedChallenges = async () => {
    const featuredChallenges = await SettingsAPI.getFeaturedChallenges()
    setFeaturedChallenges(featuredChallenges)
  }

  const getShowPromoContactUs = async () => {
    const showPromoContactUs = await SettingsAPI.getShowPromoCodeContactUs()
    setShowPromoContactUs(showPromoContactUs)
  }
  const handleSetPromoContactUs = () => {
    SettingsAPI.setShowPromoCodeContactUs(!showPromoContactUs)
    setShowPromoContactUs(!showPromoContactUs)
  }
  const FEATURED_CHALLENGE1 = 1
  const FEATURED_CHALLENGE2 = 2

  if (showPromoContactUs === null) return <div>Loading...</div>

  console.log('rendering admin settings', showPromoContactUs)
  return (
    <div className="mt-12  mx-48">
      <h1 className="  text-lg font-bold text-center">Admin Settings</h1>

      <ShowPromoCodeSetting showPromoContactUs={showPromoContactUs} handleSetPromoContactUs={handleSetPromoContactUs} classNames={classNames} />
      {featuredChallenges && featuredChallenges.length > 0 && (
        <>
          <h1 className="  text-lg font-bold text-center mt-5">Featured Challenges</h1>

          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <AdminFeaturedChallengeInput featureNumber={FEATURED_CHALLENGE1} originalChallenge={featuredChallenges[0]} />
            </div>
            <div className="flex flex-col">
              <AdminFeaturedChallengeInput featureNumber={FEATURED_CHALLENGE2} originalChallenge={featuredChallenges[1]} />
            </div>
          </div>
          <AssociatedAchievementsTable />
        </>
      )}
    </div>
  )
}

export default AdminSettings
