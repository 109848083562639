import { createContext } from 'react'
import { Notyf } from 'notyf'

export const NotyfContext = createContext(
  new Notyf({
    // Set your global Notyf configuration here
    duration: 5000,
    position: {
      x: 'center',
      y: 'bottom',
    },
    ripple: false,
  }),
)
NotyfContext.displayName = 'NotyfContext'

// const notyf = useContext(NotyfContext);
// notyf.success(
//   'Success Message'
// );

export const NotyfConsumer = NotyfContext.Consumer
