import React, { useMemo } from 'react'
import styles from './components.module.scss'
import { Discount, SubscriptionOptionType, User } from 'types'
import * as StripeUtils from 'shared/functions/stripe'

import RedPill from 'features/SignUp/components/RedPill'

type SubscriptionOptionProps = {
  option: SubscriptionOptionType
  details: StripeUtils.StripePriceDetails
  onClick: (option: SubscriptionOptionType) => void
  isActive: boolean
  showPromo?: boolean
  isPromo?: boolean
  isDiscounted?: boolean
  discountToDisplay?: Discount
}

export const SubscriptionOption: React.FC<SubscriptionOptionProps> = ({ option, details, onClick, isActive, showPromo, isPromo = false, isDiscounted, discountToDisplay }) => {
  const discount = () => {
    console.log({ isPromo, isDiscounted, discountToDisplay })
    if (isPromo) {
      const salePrice = parseFloat(details.promoPrice!)
      const origPrice = parseFloat(details.price)
      return Math.round(((origPrice - salePrice) / origPrice) * 100)
    }
    console.log({ isDiscounted, discountToDisplay })

    if (isDiscounted && discountToDisplay) {
      return discountToDisplay.discountPercentage
    }
  }

  const displayPromo = (showPromo && isPromo) || isDiscounted
  const oldPrice = () => {
    if (isPromo) {
      return details.price
    }
    if (isDiscounted && discountToDisplay) {
      return discountToDisplay.oldPrice
    }
  }

  const price = () => {
    if (isPromo) {
      return details.promoPrice
    }
    if (isDiscounted && discountToDisplay) {
      return discountToDisplay.newPrice
    }
    return details.price
  }

  return (
    <>
      <button
        className="w-full "
        onClick={() => {
          onClick(option)
        }}
      >
        <div className={`flex flex-col relative`}>
          <div className=" absolute top-1 right-1">
            {displayPromo && (
              <RedPill active={false} className="">
                {discount()}% off!
              </RedPill>
            )}
          </div>
          <div className={` hover:bg-slate-100 rounded-lg p-1 shadow-md ${isActive ? 'bg-slate-200 ' : ''} border border-slate-700 w-min-full  ${displayPromo ? 'pt-8' : ''}`}>
            <div className={`${styles.horizontalContainer} `}>
              <div className="flex">
                <p className={`${isActive ? ' font-extrabold text-slate-700' : 'font-semibold text-slate-500'}    text-4xl `}>{option.period}</p>
                <p className={`${isActive ? ' font-extrabold text-slate-700' : 'font-semibold text-slate-500'}   pl-3 text-4xl`}>${price()}</p>
              </div>
            </div>
            <p className={`font-semibold text-slate-500 text-xxs ml-3 `}>{details.promoSpecifics} </p>
          </div>
        </div>
      </button>
    </>
  )
}
