import React, { Fragment, useEffect, useState } from 'react'

import { ListOnDemandView } from './ListOnDemandView'
import { useHistory, useParams } from 'react-router-dom'
import { OnDemandFilter } from '../OnDemandFilter'
import { get } from 'services/api/helpers'
import { WorkoutResponse } from 'services/api/types'
// import queryString from 'query-string'
import * as queryString from 'qs'
import { useWorkoutData } from 'features/_OnDemand/hooks/useWorkoutData'
import { Duration, Workout } from 'types'
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites'
import useScrollPosition from 'hooks/useScrollPosition'
import { DocumentUtils } from 'shared/functions'
import { LoadingIndicator } from 'shared/components'
import styles from './ListOnDemand.module.scss'
import { WorkoutFilterOptions } from '../OnDemandFilter/OnDemandFilter'

type ListOnDemandRouteParams = {
  category: string
}

interface FetchFilterRequestType {
  workoutTypeID?: string
  classTypeID?: string
  workoutDurations?: number[]
  difficultyTitle?: string
  isFavourite?: boolean
  equipment?: boolean
}

class FetchFilterRequest {
  params: { [key: string]: any } = {}

  constructor({
    workoutTypeID,
    classTypeID,
    workoutDurations,
    difficultyTitle,
    isFavourite = false,
  }: // equipment
  FetchFilterRequestType) {
    if (workoutTypeID) {
      this.params['type'] = workoutTypeID
    }

    if (classTypeID) {
      this.params['classType'] = classTypeID
    }

    if (workoutDurations?.length) {
      this.params['duration'] = `[${workoutDurations.join(',')}]`
    }

    if (difficultyTitle) {
      this.params['difficulty'] = difficultyTitle
    }

    if (isFavourite) {
      this.params['favorites'] = true
    }
  }

  toQueryString(): string {
    return `?${queryString.stringify(this.params)}`
  }
}

const Interactor = ({ workoutTypeID, classTypeID, isFavourite }: FetchFilterRequestType) => {
  const [filterOptions, setFilterOptions] = useState<WorkoutFilterOptions>()
  const [workouts, setWorkouts] = useState<Workout[]>([])
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    async function fetch() {
      const fetchFilterRequest = new FetchFilterRequest({
        workoutTypeID: workoutTypeID,
        classTypeID: classTypeID,
        isFavourite: isFavourite,
      })
      const response = await get<WorkoutResponse>(`/workouts/filters${fetchFilterRequest.toQueryString()}`)
      setWorkouts(response)
      setLoading(false)
    }

    setLoading(true)
    fetch()
  }, [])

  useEffect(() => {
    async function getAllWorkoutFilterOptions() {
      const response = await get<WorkoutFilterOptions>(`/workouts/filter-options`)
      setFilterOptions(response)
    }

    getAllWorkoutFilterOptions()
  }, [])

  return {
    loading,
    filterOptions,
    workouts,
    setWorkouts,
  }
}

enum LengthBatches {
  LessThan10 = '< 10 Minutes',
  Between10And20 = '10 - 20 Minutes',
  Between20And30 = '20 - 30 Minutes',
  MoreThan30 = '30+ Minutes',
}

const batchDuration = (durations: Duration[]) => {
  const batches: { [key: string]: Duration[] } = {}
  durations.forEach((duration) => {
    if (duration.length < 10) {
      batches[LengthBatches.LessThan10] = [...(batches[LengthBatches.LessThan10] || []), duration]
    }

    if (duration.length < 20 && duration.length >= 10) {
      batches[LengthBatches.Between10And20] = [...(batches[LengthBatches.Between10And20] || []), duration]
    }

    if (duration.length < 30 && duration.length >= 20) {
      batches[LengthBatches.Between20And30] = [...(batches[LengthBatches.Between20And30] || []), duration]
    }

    if (duration.length >= 30) {
      batches[LengthBatches.MoreThan30] = [...(batches[LengthBatches.MoreThan30] || []), duration]
    }
  })

  return batches
}

interface ListOnDemandProps {
  workouts: Workout[]
  filterOptions?: WorkoutFilterOptions
  loading?: boolean
  title: string
  filterWorkouts: (workoutDurations: number[], difficultyTitle?: string) => void
}

const ListOnDemand: React.FC<ListOnDemandProps> = ({ workouts, filterOptions, loading, title, filterWorkouts }) => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites()

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, loading ? false : true)

  if (loading || !filterOptions) {
    return <LoadingIndicator containerStyle={styles.loadingContainer} />
  }

  const durationOptions = batchDuration(filterOptions.duration)
  const durationOptionsKeys = Object.keys(durationOptions)

  return (
    <Fragment>
      <ListOnDemandView workoutList={workouts} title={title} goBack={() => history.goBack()} showDrawer={showDrawer} workoutFavorites={workoutFavorites || []} />
      <OnDemandFilter
        placement="right"
        onClose={onClose}
        visible={visible}
        filterOptions={filterOptions}
        durationOptionsKeys={durationOptionsKeys}
        filterWorkouts={(params: any) => {
          const durations = durationOptions[params.duration]?.map((duration) => duration.length) || []
          filterWorkouts(durations, params.difficulty)
        }}
      />
    </Fragment>
  )
}

const getCategory = (options: any[], id: string) => {
  return options.find((option) => option.id === id)
}

export const ListOnDemandWorkoutType: React.FC = () => {
  const { category } = useParams<ListOnDemandRouteParams>()
  const { filterOptions, workouts, loading, setWorkouts } = Interactor({ workoutTypeID: category })
  const { typeOptions } = useWorkoutData()

  const selectedType = getCategory(typeOptions, category)

  const filterWorkoutTypes = async (workoutDurations: number[], difficultyTitle?: string) => {
    const fetchFilterRequest = new FetchFilterRequest({
      workoutTypeID: category,
      workoutDurations,
      difficultyTitle,
    })

    const response = await get<WorkoutResponse>(`/workouts/filters${fetchFilterRequest.toQueryString()}`)
    setWorkouts(response)
  }

  if (!selectedType) {
    return <LoadingIndicator containerStyle={styles.loadingContainer} />
  }

  return <ListOnDemand workouts={workouts} filterOptions={filterOptions} loading={loading} title={selectedType.title} filterWorkouts={filterWorkoutTypes} />
}

export const ListOnDemandWorkoutClass: React.FC = () => {
  const { category } = useParams<ListOnDemandRouteParams>()
  const { filterOptions, workouts, loading, setWorkouts } = Interactor({ classTypeID: category })
  const { workoutClassTypes: workoutClasses = [] } = useWorkoutData()

  const selectedType = getCategory(workoutClasses, category)

  const filterWorkoutClasses = async (workoutDurations: number[], difficultyTitle?: string) => {
    console.log(workoutDurations, difficultyTitle)
    const fetchFilterRequest = new FetchFilterRequest({
      classTypeID: category,
      workoutDurations,
      difficultyTitle,
    })

    const response = await get<WorkoutResponse>(`/workouts/filters${fetchFilterRequest.toQueryString()}`)
    setWorkouts(response)
  }

  if (!selectedType) {
    return <LoadingIndicator containerStyle={styles.loadingContainer} />
  }

  return <ListOnDemand workouts={workouts} filterOptions={filterOptions} loading={loading} title={selectedType.title} filterWorkouts={filterWorkoutClasses} />
}

export const ListOnDemandWorkoutFavorite: React.FC = () => {
  const { filterOptions, workouts, loading, setWorkouts } = Interactor({ isFavourite: true })

  const filterWorkoutClasses = async (workoutDurations: number[], difficultyTitle?: string) => {
    const fetchFilterRequest = new FetchFilterRequest({
      isFavourite: true,
      workoutDurations,
      difficultyTitle,
    })

    const response = await get<WorkoutResponse>(`/workouts/filters${fetchFilterRequest.toQueryString()}`)
    setWorkouts(response)
  }

  return <ListOnDemand workouts={workouts} filterOptions={filterOptions} loading={loading} title="Favourite Workouts" filterWorkouts={filterWorkoutClasses} />
}
