import React from 'react'

type Props = {
  fieldKey?: string
  label: string
  value: string | number
  setFieldValue?: (field: string, value: any) => void
  setter?: React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>>
  type: string
  errorText?: string
  className?: string
}

const AdminInputField: React.FC<Props> = ({ fieldKey, label, value, type, setFieldValue, setter, className, errorText }: Props) => {
  const fieldName = label.toLowerCase().split(' ').join('-')

  // if the type is DateTime-local, then we need to convert the value to localtime
  if (value && value != '' && type === 'datetime-local') {
    const date = new Date(value)
    value = date.toISOString().slice(0, 16)
  }
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setFieldValue && fieldKey) {
      setFieldValue(fieldKey, e.target.value)
    } else if (setter) {
      if (type === 'number') {
        ;(setter as React.Dispatch<React.SetStateAction<number>>)(Number(e.target.value))
      } else {
        ;(setter as React.Dispatch<React.SetStateAction<string>>)(e.target.value)
      }
    }
  }
  return (
    <div className={`w-full px-3 mt-6 md:mb-0 ${className}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={`grid-${fieldName}-field`}>
        {label}
      </label>
      <input
        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
        id={`grid-${fieldName}-field`}
        type={type}
        value={value}
        step={type === 'number' ? 'any' : undefined}
        onChange={onChangeHandler}
      />
      <div className="text-xs pl-3 mt-0 font-thin text-red-600"> {errorText}</div>
    </div>
  )
}

export default AdminInputField
