import { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-query'
import { fetchReportables } from '../services/api/userAchievementCriteria'
import { UserAchievementCriteriaWithRelatedData, ReportableUacsResponse } from '../types'
import { AuthStateContext } from '../context/AuthStateContext'

export function useReportableUACs() {
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState
  const [reportableUACs, setReportableUACs] = useState<ReportableUacsResponse>({ today: [], yesterday: [] })
  const [isLoading, setLoading] = useState(true)
  const [doneReportingForYesterday, setDoneReportingForYesterday] = useState(false)

  const timestampIso = new Date().toISOString()
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const {
    data,
    refetch,
    isLoading: isQueryLoading,
  } = useQuery<{ yesterday: UserAchievementCriteriaWithRelatedData[]; today: UserAchievementCriteriaWithRelatedData[] }>(
    'reportableUACs',
    () => fetchReportables(timestampIso, userTimeZone),
    {
      staleTime: 0,
      enabled: !!user,
    },
  )

  useEffect(() => {
    if (data) {
      setReportableUACs(data)
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    setLoading(isQueryLoading)
  }, [isQueryLoading])

  const refetchReportableUACs = async () => {
    await refetch()
  }

  const isReportable = (uacId: string, today: boolean) => {
    if (isLoading) return false
    if (today) {
      return reportableUACs.today.some((uac) => uac.id === uacId)
    } else {
      return reportableUACs.yesterday.some((uac) => uac.id === uacId)
    }
  }

  return {
    reportableUACs,
    isLoading,
    refetchReportableUACs,
    isReportable,
    doneReportingForYesterday,
    setDoneReportingForYesterday,
  }
}
