import React, { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ChallengeAPI } from 'services/api'
import { AdminUserChallenge } from 'services/api/types'
import * as AdminApi from '../../../services/api/admin'
import ChallengeDataTable from './ChallengeDataTable'
import AdminChallengeStatBlock from './AdminChallengeStatBlock'

export default function AdminChallenge() {
  const { data: challenges, isFetching: isFetchingChallenges } = useQuery(
    'adminChallenges',
    async () => {
      const res = await ChallengeAPI.getAllChallenges()
      res.reverse()
      return res
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const [selectedChallenge, setSelectedChallenge] = React.useState('')
  const [selectedChallengeData, setSelectedChallengeData] = React.useState<AdminUserChallenge[]>()
  const [filterCancelled, setFilterCancelled] = React.useState(true)

  let genderMakeup = { males: 0, females: 0, didNotDisclose: 0 }

  useEffect(() => {
    if (!selectedChallenge) return
    console.log('selectedChallenge', selectedChallenge)
    AdminApi.fetchChallengeData(selectedChallenge).then((res) => {
      res.data = res.data.filter((userChallenge: AdminUserChallenge) => {
        console.log('filterCancelled', filterCancelled)
        if (filterCancelled) return !userChallenge.cancelled
        return true
      })

      setSelectedChallengeData(res.data)
    })
  }, [selectedChallenge, filterCancelled])

  useMemo(() => {
    if (!selectedChallengeData) return
    genderMakeup = { males: 0, females: 0, didNotDisclose: 0 }
    // count how many in a array of AdminUserChallenges are Male and how many are female
    const processedSquadies: string[] = []
    selectedChallengeData.forEach((userChallenge: AdminUserChallenge) => {
      console.log('userChallenge', userChallenge.gender)
      if (processedSquadies.includes(userChallenge.email)) return
      if (userChallenge.gender === 'Female') genderMakeup.females += 1
      if (userChallenge.gender === 'Male') genderMakeup.males = genderMakeup.males += 1
      if (userChallenge.gender === null) genderMakeup.didNotDisclose += 1
      processedSquadies.push(userChallenge.email)
    })
  }, [selectedChallengeData, genderMakeup])

  return (
    <div>
      <div>
        {isFetchingChallenges && <p> Loading...</p>}
        {!isFetchingChallenges && !challenges && <p> No challenges found</p>}
        {/* Add an autocomplete to select a challenge */}

        <select
          id="challenge"
          name="challenge"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue=""
          onChange={(e) => {
            const challengeId = e.target.value
            if (challengeId) {
              setSelectedChallenge(challengeId)
            }
          }}
        >
          <option value="">Select a challenge</option>
          {challenges &&
            challenges?.map((challenge) => (
              <option key={challenge.id} value={challenge.id}>
                {challenge.title}
              </option>
            ))}
        </select>
      </div>

      {selectedChallenge && selectedChallengeData && (
        <div>
          {/* Render a toggle for showCancelled */}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="showCancelled"
                name="showCancelled"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                defaultChecked={filterCancelled}
                onChange={(e) => {
                  setFilterCancelled(e.target.checked)
                }}
              />
              <label htmlFor="showCancelled" className="ml-2 block text-sm text-gray-900">
                Hide Cancelled
              </label>
            </div>
          </div>

          <div className="px-4 sm:px-6 lg:px-8">
            <AdminChallengeStatBlock challengeData={selectedChallengeData} genderMakeup={genderMakeup ?? { males: 0, females: 0, didNotDisclose: 0 }} />
          </div>
          <ChallengeDataTable challengeData={selectedChallengeData} />
        </div>
      )}
    </div>
  )
}
