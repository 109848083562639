import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'shared/functions/dayjs'
import timezone from 'dayjs/plugin/timezone'

type Props = {
  date: Date
  startDate: string
  tz: string
  weekNumber: number
  maxWeeks: number
}

type TimeLeft = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const calculateTimeLeft = (date: Date) => {
  const now = dayjs()
  const end = dayjs(date)
  const diff = end.diff(now)
  const timeLeft = dayjs.duration(diff)
  return {
    days: timeLeft.days(),
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds(),
  }
}

const Countdown = ({ startDate, tz, date, maxWeeks, weekNumber }: Props) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(date))
  let timer: NodeJS.Timeout
  const updateCount = () => {
    if (!timer) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(date))
      }, 5000)
    }
  }

  useEffect(() => {
    updateCount()

    if (timer) {
      return () => clearInterval(timer)
    }
  }, [timeLeft])

  if (maxWeeks === 0) return null
  if (weekNumber === 0) return null
  if (weekNumber > maxWeeks) return null

  const isLastWeek = weekNumber === maxWeeks
  const nextWeekNumber = weekNumber + 1

  //   let timeLeft = calculateTimeLeft;

  return (
    <div className="flex flex-col">
      <div className="">
        <div className="flex text-xxs  text-gray-500">You started on a {dayjs(startDate).tz(tz).format('dddd')}</div>
      </div>
      <div className="text-xs text-slate-800">{isLastWeek ? 'Till end of challenge' : `your next week & new workouts start on  ${dayjs(date).tz(tz).format('dddd, MMM DD')}`}</div>
      <div className="flex font-semi-bold "></div>
      <div className=""></div>
    </div>
  )
}
export default Countdown
