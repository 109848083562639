import PasswordValidator from 'password-validator'
import { FormValidationError } from 'types'

const schema = new PasswordValidator()
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  //   .has()
  //   .symbols() // must have a symbol
  .has()
  .not()
  .spaces() // Should not have spaces

export const validEmail = (email: string): string => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const valid = re.test(String(email).toLowerCase())
  if (!valid) {
    return 'Please enter a valid email'
  }
  return ''
}

export const validPassword = (password: string, showList?: boolean): string | string[] => {
  const valid = schema.validate(password)
  if (showList) {
    return schema.validate(password, { list: true }) as string | string[]
  }
  if (!valid) {
    return 'Please enter a valid password'
  }
  return ''
}

export const validateForm = (data: FormValidationError): FormValidationError => {
  const newErrors: FormValidationError = {}

  ;(Object.keys(data) as Array<keyof FormValidationError>).forEach((key: keyof FormValidationError): void => {
    const value = data[key]
    if (!value || value.length < 1) {
      newErrors[key] = 'Field is required.'
    } else {
      if (key.toLowerCase().includes('email')) {
        const emailCheck = validEmail(value)
        if (emailCheck.length > 0) {
          newErrors[key] = emailCheck
        }
      }
      if (key === 'password') {
        const passwordCheck = validPassword(value) as string
        if (passwordCheck.length > 0) {
          newErrors[key] = passwordCheck
        }
      }
    }
  })

  return newErrors
}
