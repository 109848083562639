import { AuthStateContext } from 'context';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LoadingIndicator } from 'shared/components';

interface Props {
  path: string;
  component: React.ElementType;
  name: string;
}

export const AuthedRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { state: authState } = useContext(AuthStateContext);
  const { isFetching, user } = authState;

  // Fetching User
  if (isFetching) {
    return <LoadingIndicator />;
  }

  if (!user) {
    return <Redirect to="/sign-up" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
