import React, { FC, ButtonHTMLAttributes, ReactNode } from 'react'

type Variant = 'primary' | 'secondary' | 'danger'

type ButtonProps = {
  variant?: Variant
  className?: string
  children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

const variantClasses: Record<Variant, string> = {
  primary: 'bg-teal hover:bg-teal-dark focus:ring-teal-darker',
  secondary: 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500',
  danger: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
}
const LeanButton: FC<ButtonProps> = ({ variant = 'primary', className = '', children, ...props }) => (
  <button
    className={`inline-flex items-center justify-center rounded-md border border-transparent px-5 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${variantClasses[variant]} ${className}`}
    {...props}
  >
    {children}
  </button>
)

export default LeanButton
