import React, { useContext } from 'react'
import styles from './ViewAllWorkouts.module.scss'
import { Box, LoadingIndicator, WorkoutItem } from 'shared/components'
import { Workout } from 'types'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { useQuery } from 'react-query'
import { ChallengeAPI } from 'services/api'
import { useHistory } from 'react-router'
import { dayjs, DocumentUtils } from 'shared/functions'
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites'
import useScrollPosition from 'hooks/useScrollPosition'

export const ViewAllWorkouts: React.FC = () => {
  const history = useHistory()
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge, isFetching } = userChallengeContextState
  const { data: workouts, isLoading: workoutsLoading } = useQuery(
    `challengeWorkouts-${activeChallenge?.start_date}-${activeChallenge?.end_date}`,
    () => ChallengeAPI.getAllUserChallengeWorkouts(activeChallenge?.start_date, activeChallenge?.end_date, activeChallenge?.id),
    {
      enabled: !!activeChallenge,
    },
  )
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites()

  const isFuture = (string?: string): boolean => {
    if (string) {
      const date = new Date(string)
      return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    }
    return false
  }

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, workoutsLoading ? false : true)

  const handleFavoriteClick = async (item: Workout, isFavorite: boolean) => {
    console.log('handleFavoriteClick :>> ', item, isFavorite)

    try {
      if (isFavorite) {
        await mutateRemoveFavorite({ workout_id: item.id })
      } else {
        await mutateAddFavorite({ workout_id: item.id })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Mutate:error :>> ', error)
    }
  }

  const handlePlayClick = (item: Workout) => {
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    if (item.challenge_workout) {
      history.push(`/workouts/${item.id}/overview?challengeWorkout=${item.challenge_workout.id}`, {
        workout: item,
      })
    } else {
      history.push(`/workouts/${item.id}/overview`, {
        workout: item,
      })
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Box className={styles.myProgramContainer}>
          <p className={styles.programHeader}>My Program</p>
          <p className={styles.programTitle}>{activeChallenge ? activeChallenge.challenge.title : `CHALLENGE`}</p>
        </Box>
      </Box>
      <Box className={styles.content}>
        {isFetching || workoutsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <p className={styles.headerTitle}>ALL WORKOUTS IN YOUR CHALLENGE</p>
            {workouts && workouts.length > 0 ? (
              <>
                {workouts.map((workout) => {
                  const isFavorite = workoutFavorites?.find((wf) => workout.id === wf.workout_id)

                  return (
                    <Box key={workout.id} className={styles.itemContainer}>
                      <p className={styles.itemTitle}>{dayjs(workout.challenge_workout?.scheduled_date).utc().format('MMM DD')}</p>
                      <WorkoutItem
                        title={workout.title}
                        description={workout.duration.title}
                        item={workout}
                        isFavorite={!!isFavorite}
                        onClickFavorite={handleFavoriteClick}
                        onClickPlay={handlePlayClick}
                        locked={isFuture(workout.challenge_workout?.scheduled_date)}
                      />
                    </Box>
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
