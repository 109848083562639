import React from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import styles from './WorkoutHistory.module.scss'
import { Box, LoadingIndicator, WorkoutItem } from 'shared/components'
import { Workout, WorkoutsByDate } from 'types'
import { CompletedWorkoutsAPI } from 'services/api'
import { dayjs, DocumentUtils } from 'shared/functions'
import { useWorkoutFavorites } from 'hooks/useWorkoutFavorites'
import { CompletedWorkoutsResponse } from 'services/api/types'
import useScrollPosition from 'hooks/useScrollPosition'

export const WorkoutHistory: React.FC = () => {
  const history = useHistory()
  const { data: workouts, isLoading: workoutsLoading } = useQuery(
    `completedWorkouts`,
    () => CompletedWorkoutsAPI.getCompletedWorkoutsByDate(dayjs().subtract(10, 'years').utc().toISOString(), dayjs().utc().toISOString()),
    {
      // 5 minutes
      staleTime: 5 * 60 * 1000,
    },
  )
  const { workoutFavorites, mutateAddFavorite, mutateRemoveFavorite } = useWorkoutFavorites()

  const { setScrollYStorage } = useScrollPosition(location.pathname, workoutsLoading ? false : true)

  const handleFavoriteClick = async (item: Workout, isFavorite: boolean) => {
    console.log('handleFavoriteClick :>> ', item, isFavorite)

    try {
      if (isFavorite) {
        await mutateRemoveFavorite({ workout_id: item.id })
      } else {
        await mutateAddFavorite({ workout_id: item.id })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Mutate:error :>> ', error)
    }
  }

  const handlePlayClick = (item: Workout) => {
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    if (item.challenge_workout) {
      history.push(`/workouts/${item.id}/overview?challengeWorkout=${item.challenge_workout.id}`, {
        workout: item,
      })
    } else {
      history.push(`/workouts/${item.id}/overview`, {
        workout: item,
      })
    }
  }

  const workoutsFormattedByDate = formatResponseByDate(workouts)

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <p>Workout History</p>
      </Box>
      <Box className={styles.content}>
        {workoutsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {Object.keys(workoutsFormattedByDate).length > 0 ? (
              <>
                <div className="grid grid-cols-2 gap-8 ">
                  {Object.keys(workoutsFormattedByDate).map((key) => {
                    const workoutsByDate = workoutsFormattedByDate[key]

                    return (
                      <Box key={key} className={styles.dayContainer + ' gap-y-3'}>
                        <p className={styles.dayTitle}>{key}</p>
                        {workoutsByDate.map((workout) => {
                          const isFavorite = workoutFavorites?.find((wf) => workout.id === wf.workout_id)
                          return (
                            <Box key={workout.id} className={styles.itemContainer}>
                              <WorkoutItem
                                title={workout.title}
                                description={workout.duration.title}
                                item={workout}
                                isFavorite={!!isFavorite}
                                onClickFavorite={handleFavoriteClick}
                                onClickPlay={handlePlayClick}
                                locked={false}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                    )
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

const formatResponseByDate = (response?: CompletedWorkoutsResponse) => {
  if (!response || response.length < 0) return {}
  const allWorkouts: Workout[] = []
  const workoutsByDate: WorkoutsByDate = {}

  response.forEach((item) => {
    if (!item) {
      return
    }
    if (!item.completed_date) {
      console.error('WorkoutHistory:formatResponseByDate: item.completed_date is null')
      console.error('item :>> ', { item })
      return
    }
    const formattedDate = dayjs(item.completed_date).local().format('LL')
    allWorkouts.push(item)
    if (workoutsByDate[formattedDate]) {
      workoutsByDate[formattedDate].push(item)
    } else [(workoutsByDate[formattedDate] = [item])]
  })

  return workoutsByDate
}
