import React from 'react'

import { Carousel } from 'antd'
import { useSignUpContext } from '../SignUpContext'
import PlanSelector from './PlanSelector'
import UserDetails from './UserDetailsForm'
import PaymentDetails from './PaymentDetailsForm'

export const AccessForm: React.FC<{ name: string }> = ({ name }) => {
  const { signUpFormCarouselRef } = useSignUpContext()

  return (
    <section id="accessForm">
      <Carousel
        ref={signUpFormCarouselRef}
        style={{
          width: '100%',
        }}
        dots={false}
        swipe={false}
      >
        <PlanSelector />
        <UserDetails name={name} />
        <PaymentDetails name={name} />
      </Carousel>
    </section>
  )
}
