/* eslint-disable @typescript-eslint/no-explicit-any */
// react-select styles are a pain to deal with - with typescript

import React, { useCallback } from 'react'
import Select, { createFilter, ValueType } from 'react-select'
import { Box } from '../Box/Box'
import styles from './SelectInput.module.scss'

type SelectInputProps = {
  options: OptionType[]
  value: ValueType<OptionType, boolean>
  name?: string
  containerStyle?: string
  placeholder?: string
  onChange?: (value: { name: string; selected: OptionType }) => void
}

type OptionType = { label: string; value: string }

const selectStyles = () => ({
  option: (provided: any, state: any) => {
    return {
      ...provided,
      ...(state.isFocused || state.isSelected
        ? {
            backgroundColor: '#3da3ba',
            color: '#FFF',
          }
        : {
            backgroundColor: '#ffffff',
            color: '#171922',
          }),
      ':hover': {
        backgroundColor: '#3da3ba',
      },
    }
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      ...(state.isFocused || state.isSelected
        ? {
            border: `1px solid #3da3ba`,
            boxShadow: 'none',
          }
        : {
            border: `1px solid #2d3748`,
          }),
      ':hover': {
        borderColor: '#3da3ba',
      },
    }
  },
})

export const SelectInput: React.FC<SelectInputProps> = ({ options, containerStyle, placeholder, onChange, name, value }) => {
  const onChangeCallback = useCallback(
    (selected: OptionType | null) => {
      if (name && onChange && selected) {
        onChange({ name, selected })
      }
    },
    [onChange, name],
  )

  return (
    <Box className={`${styles.container} ${containerStyle}`}>
      <Select
        id={name}
        className={styles.selectInput}
        classNamePrefix="select"
        name={name}
        // Component changes from uncontrolled to controlled when there is a value
        // Otherwise the placeholder doesn't work?
        value={value}
        placeholder={placeholder}
        onChange={onChangeCallback}
        styles={selectStyles()}
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
      />
    </Box>
  )
}
