import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useQuery } from 'react-query'
// import queryString from 'query-string';
import * as queryString from 'qs'
import { getWorkoutClassTypes, getWorkouts, getWorkoutTypes } from 'services/api/workouts'
import { WorkoutType, FiltersQuery, Workout, WorkoutClass } from 'types'
import { filterCategoriesForIconsOnly } from '../content'
import { useHistory, useLocation } from 'react-router'
import { NotyfContext } from 'context/NotyfContext'

const filtersObjectHash = (obj: FiltersQuery, index: number) => {
  return obj.duration || obj.difficulty || obj.type || obj.classType || obj.favorites || `$$index: + ${index}`
}
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jsonDiff = require('jsondiffpatch')

type WorkoutData = {
  typeOptions: WorkoutType[]
  workoutClassTypes?: WorkoutClass[]
  selectedType: WorkoutType | null
  setSelectedType: React.Dispatch<React.SetStateAction<WorkoutType | null>>
  filters: FiltersQuery | undefined
  setFilters: React.Dispatch<React.SetStateAction<FiltersQuery | undefined>>
  workouts?: Workout[]
  workoutsStatus: 'idle' | 'error' | 'loading' | 'success'
  workoutsLoading: boolean
}

type OnDemandFiltersState = {
  filters?: FiltersQuery
}

export const useWorkoutData = (): WorkoutData => {
  const notyf = useContext(NotyfContext)
  const { search: locationSearch } = useLocation<OnDemandFiltersState>()
  const history = useHistory()

  const locationQuery = queryString.parse(locationSearch, { ignoreQueryPrefix: true })
  const { type } = locationQuery

  const { data: workoutTypes } = useQuery('workoutTypes', getWorkoutTypes, {
    staleTime: Infinity,
  })
  const { data: workoutClassTypes } = useQuery('workoutClassTypes', getWorkoutClassTypes, {
    staleTime: Infinity,
  })
  const typeOptions = useMemo<WorkoutType[]>(() => filterCategoriesForIconsOnly(workoutTypes), [workoutTypes])

  const [selectedType, setSelectedType] = useState<WorkoutType | null>(null)
  const [filters, setFilters] = useState<FiltersQuery | undefined>(locationQuery)

  const workoutsQueryEnabled = !!selectedType && !!filters && !!typeOptions
  const workoutsQuery = useQuery(['workouts', filters], () => getWorkouts(filters, typeOptions), {
    enabled: workoutsQueryEnabled,
    // favorites can safely always be refetched each time to be sure they are up to date.
    staleTime: type && type === 'favorites' ? 0 : 5 * 60 * 1000, // 5 minutes
    retry: false,
    onError: (error: Error) => {
      console.log('error :>> ', error)
      notyf.error(error.message)
    },
  })

  // Sets Selected Type based on given type and options.
  // Should only run once on initial rendering or on workout type changes
  useEffect(() => {
    console.log(type, typeOptions, selectedType)
    if (type && typeOptions && !selectedType) {
      const foundType = typeOptions.find((item) => item.slug === type)
      console.log('foundType :>> ', foundType)
      if (foundType) {
        setSelectedType(foundType)
      }
    }
  }, [type, typeOptions, selectedType])

  /**
   * Compare Filters with Location Search Query and adjust URL to match all.
   */
  useEffect(() => {
    console.log('filterChanges:>> ', filters)
    // console.log('locationSearch:>> ', locationSearch);
    if (filters && Object.keys(filters).length > 0) {
      const locationQuery = queryString.parse(locationSearch)
      // console.log('locationQuery:>> ', locationQuery);
      const locationFiltersDiff = jsonDiff.create(filtersObjectHash).diff(filters, locationQuery)
      // console.log(`locationFiltersDiff`, locationFiltersDiff);
      if (locationFiltersDiff) {
        // console.log('------ Replace URL with ------');
        history.replace(`${location.pathname}?${queryString.stringify(filters)}`)
      } else {
        // console.log(' ------ URL matches filters ------');
      }
    }
  }, [filters, locationSearch])

  // console.log('typeOptions :>> ', typeOptions);
  // console.log('workoutsQuery :>> ', workoutsQuery);
  const { data: workouts, status: workoutsStatus, isLoading: workoutsLoading } = workoutsQuery

  return {
    workoutClassTypes,
    typeOptions,
    selectedType,
    setSelectedType,
    filters,
    setFilters,
    workouts,
    workoutsStatus,
    workoutsLoading,
  }
}
