import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request]`, config);
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error]`, error);
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] `, response);
  return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[response error] `, error.message);

  if (error && error.message.includes('401')) {
    if (!window.location.href.includes('/login')) {
      window.location.href = '/login'
    }
  }

  if (error && error.message.includes('403')) {
    if (!window.location.href.includes('/subscription')) {
      window.location.href = '/profile/subscription/update'
    }
  }
  return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
