import { AuthStateContext, RecipePlanActions, RecipePlanContext } from 'context'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router'
import ReactMarkdown from 'react-markdown'
import { getRecipeById } from 'services/api/recipes'
import { Box, Button, Carousel, LoadingIndicator } from 'shared/components'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { Recipe, RecipeVariant } from 'types'
import styles from './RecipeOverview.module.scss'
import { Link } from 'react-router-dom'

type RecipeOverviewParams = {
  recipeId: string
}

type RecipeOverviewLocationState = {
  recipe?: Recipe
}

type OverviewViewType = 'ingredients' | 'instructions'

export const RecipeOverview: React.FC = () => {
  const { state: locationState = {}, search: locationSearch } = useLocation<RecipeOverviewLocationState>()
  const locationQuery = new URLSearchParams(locationSearch)
  const isChoosingMeals = locationQuery.get('chooseMeals')

  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState
  const { state: recipePlanState, dispatch: recipePlanDispatch } = useContext(RecipePlanContext)
  const { activeMeal, activeDate, activeRecipes } = recipePlanState
  const { recipeId = '' } = useParams<RecipeOverviewParams>()
  const [recipe, setRecipe] = useState<Recipe | undefined>(locationState.recipe)
  const recipeQuery = useQuery(['recipeById', recipeId], () => getRecipeById(recipeId), {
    enabled: recipe === undefined,
  })
  const { data: recipeData, isLoading: recipeLoading } = recipeQuery
  const [selectedView, setSelectedView] = useState<OverviewViewType>('ingredients')

  useEffect(() => {
    if (!recipe && recipeData) {
      console.log('Setting Recipe from Query :>> ', recipeData)
      setRecipe(recipeData)
    }
  }, [recipe, recipeData])

  const onClickViewType = (type: OverviewViewType) => {
    setSelectedView(type)
  }

  const onClickAddMeal = (item: Recipe) => {
    console.log('onClickAddMeal :>> ', item)
    console.log('recipePlanState :>> ', recipePlanState)
    const activeRecipesCopy = { ...activeRecipes }
    if (activeMeal) {
      activeRecipesCopy[activeMeal] = item
      recipePlanDispatch(RecipePlanActions.updateActiveRecipes(activeRecipesCopy))
    }
  }

  const onClickRemoveMeal = () => {
    const activeRecipesCopy = { ...activeRecipes }
    if (activeMeal) {
      delete activeRecipesCopy[activeMeal]
      recipePlanDispatch(RecipePlanActions.updateActiveRecipes(activeRecipesCopy))
    }
  }

  let mealAdded = false
  if (activeMeal && activeRecipes[activeMeal] && recipe) {
    if (activeRecipes[activeMeal].id === recipe.id) {
      mealAdded = true
    }
  }

  console.log('recipe :>> ', recipe)

  if (!recipe || recipeLoading) {
    return <LoadingIndicator />
  }

  const { variants } = recipe
  let selectedVariant
  if (variants && variants.length > 0) {
    const { user } = authState
    if (user && user.bmrRange) {
      const variantByBmr = variants.find((v) => v.variant === user.bmrRange)
      if (variantByBmr) {
        selectedVariant = variantByBmr
      }
    }
    if (!selectedVariant) {
      selectedVariant = variants[0]
    }
  }
  let showMeasurementsBanner = false
  if (user && (!user.heightCM || !user.weightKG || !user.birthday || !user.gender)) {
    showMeasurementsBanner = true
  }

  return (
    <Box className={styles.container}>
      <button onClick={() => history.back()} className={styles.backBtn}>
        <ArrowLeft height={30} width={30} />
      </button>

      <Box className={styles.contentContainer}>
        <Box className={styles.photoContainer}>
          <Carousel>
            {recipe.photos.map((photo, index) => {
              return <img key={`${index}-${photo}`} src={photo} />
            })}
          </Carousel>
        </Box>
        <Box className={styles.header}>
          <p className={styles.category}>{recipe.category.title}</p>
          <p className={styles.title}>{recipe.title}</p>
          <p className={styles.metadata}>{getMetadata(recipe, selectedVariant)}</p>
        </Box>
        <Box className={styles.content}>
          <Box className={styles.viewOptionRow}>
            <button className={`${styles.viewOption} ${selectedView === 'ingredients' ? styles.active : ''}`} onClick={() => onClickViewType('ingredients')}>
              LEANGREDIENTS
            </button>
            <button className={`${styles.viewOption} ${selectedView === 'instructions' ? styles.active : ''}`} onClick={() => onClickViewType('instructions')}>
              LEANSTRUCTIONS
            </button>
          </Box>
          {showMeasurementsBanner && (
            <Box className={styles.goToMeasurementsBanner}>
              <p>Update your measurements to get Leangredients and Leanstructions catered to you!</p>

              <Link to="/profile/measurements">Update Measurements</Link>
            </Box>
          )}
          <Box className={styles.viewContent}>
            {selectedView === 'instructions' ? (
              <>
                <ReactMarkdown className={styles.instructions}>{selectedVariant ? selectedVariant.instructions : recipe.instructions}</ReactMarkdown>
              </>
            ) : (
              <>
                <ReactMarkdown className={styles.ingredients}>{selectedVariant ? selectedVariant.ingredients : recipe.ingredients}</ReactMarkdown>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {isChoosingMeals && activeMeal && activeDate && (
        <Button
          containerStyle={`${styles.addMealBtn} ${mealAdded ? styles.active : ''}`}
          label={mealAdded ? `Remove Meal` : `Add Meal`}
          onClick={mealAdded ? () => onClickRemoveMeal() : () => onClickAddMeal(recipe)}
          labelStyle={styles.addMealLabel}
        />
      )}
    </Box>
  )
}

const getMetadata = (recipe: Recipe, selectedVariant?: RecipeVariant): string => {
  let meta = ''
  let servingSize = recipe.servingSize

  if (selectedVariant) {
    servingSize = selectedVariant.servingSize
  }

  if (recipe.prepTime > 0) {
    meta += ` Prep - ${recipe.prepTime} ${recipe.prepTime > 1 ? 'mins' : 'min'}`
  }
  if (recipe.cookTime > 0) {
    meta += `${meta.length > 0 ? ' • ' : ''}Cook - ${recipe.cookTime} ${recipe.cookTime > 1 ? 'mins' : 'min'}`
  }

  meta += `${meta.length > 0 ? ' • ' : ''}${servingSize ? `${servingSize} ${servingSize > 1 ? 'servings' : 'serving'}` : ``}`

  return meta
}
