import React, { useContext, useState, useEffect } from 'react'
import { Challenge, ChallengeCategory, Series } from 'types'
import styles from './ViewChallenges.module.scss'
import { ChallengeList } from './ChallengeList'
import { useHistory } from 'react-router'
import { Box, ChallengeItem } from 'shared/components'
import useScrollPosition from 'hooks/useScrollPosition'
import { DocumentUtils } from 'shared/functions'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { ChallengeContext } from 'context/ChallengeContext'
import { ActiveChallenge } from '../../../types/index'
import { ButtonBar } from 'components/ButtonBar'
import { isSeries } from 'services/isSeries'
import { isChallenge } from 'services/isChallenge'
import Cookies from 'js-cookie'

const ViewChallenges: React.FC = () => {
  const history = useHistory()
  const [selectedCategory, setSelectedCategory] = useState<ChallengeCategory | null>(null)
  const [displayedChallenges, setDisplayedChallenges] = useState<(Challenge | Series)[]>([])
  const [showSeries, setShowSeries] = React.useState(false)
  const [selectedSeries, setSelectedSeries] = React.useState<Series | null>(null)

  // Read cookie on mount
  useEffect(() => {
    const category = Cookies.get('selectedCategory')
    if (category) {
      setSelectedCategory(JSON.parse(category))
    }
  }, [])

  // Set cookie when selectedCategory changes
  useEffect(() => {
    if (selectedCategory) {
      Cookies.set('selectedCategory', JSON.stringify(selectedCategory), { expires: 1 }) // expires in 1 day
    } else {
      Cookies.remove('selectedCategory')
    }
  }, [selectedCategory])

  const onClickChallenge = (challenge: Challenge) => {
    setScrollYStorage(DocumentUtils.getContentDivScroll())
    history.push(`/challenges/${challenge.id}/overview`, {
      challenge,
    })
  }

  const onClickCategory = (category: ChallengeCategory | null) => {
    setSelectedCategory(category)
  }

  const handleSeriesClick = React.useCallback(
    (series: Series) => {
      history.push(`/challenges/series/${series.id}`)
      setSelectedSeries(series)
      setShowSeries(true)
    },
    [history],
  )

  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { state: challengesContext } = useContext(ChallengeContext)
  const { setScrollYStorage } = useScrollPosition(history.location.pathname, challengesContext.isFetching === false)
  const { activeChallenge } = userChallengeContextState

  // useEffect to filter challenges by category when selectedCategory changes
  useEffect(() => {
    if (challengesContext.challengesAndSeriesCombined) {
      if (selectedCategory === null) {
        setDisplayedChallenges(challengesContext.challengesAndSeriesCombined)
      } else {
        const filteredChallenges = challengesContext.challengesAndSeriesCombined.filter((item: Challenge | Series) => {
          let categories: string[] = []
          if (isChallenge(item)) {
            item = item as Challenge
            categories = item?.categories?.map((category) => category.displayName) ?? []
            return categories.includes(selectedCategory.displayName)
          } else if (isSeries(item)) {
            // strip the series of any challenges that do not share the selected categories
            // if the series still has challenges after filtering, then the series should be displayed
            item = item as Series
            const seriesChallenges = item.challenges
            const seriesCategories = seriesChallenges.map((challenge) => challenge.categories?.map((category) => category.displayName)).flat()
            if (seriesCategories.includes(selectedCategory.displayName)) {
              return true
            }
          }
          return false
        })
        setDisplayedChallenges(filteredChallenges)
      }
    }
  }, [selectedCategory])

  useEffect(() => {
    if (history.location.state && (history.location.state as any).selectedCategory) {
      setSelectedCategory((history.location.state as any).selectedCategory)
    }
  }, [history.location.state])

  const renderActiveChallenge = (activeChallenge: ActiveChallenge) => {
    const { challenge } = activeChallenge
    if (!challenge) return null
    const isFlexChallenge = challenge.type?.title === 'Flex' ?? false

    const startDateString = new Date(activeChallenge.start_date).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    const endDateString = new Date(activeChallenge.end_date).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    return (
      <div className=" flex flex-col xl:flex-row  lg:gap-10   ">
        <div className=" center py-4 w-full  mb-2 lg:mb-12 font-SfProDisplayBold">
          {/* <div className="border-2 border-slate-500 shadow-2xl   border-opacity-50 rounded-xl  py-4 w-full  xl:w-3/5 mb-2 lg:mb-12 font-SfProDisplayBold"> */}
          {/* active challenge h2*/}
          <h2 className="text-center text-2xl font-bold">Current</h2>
          <div className=" flex flex-col md:flex-row justify-center  mt-2 ">
            <div className=" 	">
              <div className="flex justify-around">
                <ChallengeItem
                  key={'active-challenge'}
                  item={challenge}
                  handleClick={onClickChallenge}
                  itemId={challenge.id}
                  completedChallenges={userChallengeContextState.completedChallenges ?? null}
                  releaseDate={challenge.releaseDate}
                />
              </div>
            </div>
            <div className="flex   flex-row mt-4">
              <div className=" pl-12 ">
                {/* output the labels and details for the active challenge */}
                <div className="mb-2">
                  <label className="font-bold  text-slate-800">Start Date:</label>
                  <p className="mb-2 text-slate-600">{startDateString}</p>
                </div>
                <div className="mb-2">
                  <label className="font-bold text-slate-800">End Date:</label>
                  <p className="mb-2 text-slate-600">{endDateString}</p>
                </div>
              </div>

              {/* output the labels and details for the active challenge */}
              {isFlexChallenge && (
                <div className="pl-4">
                  <label className="font-bold  text-slate-800">Weekly Commitment:</label>
                  <p className="mb-2 text-slate-600">{activeChallenge.weekly_commitment ?? 0} workouts per week</p>

                  <label className="font-bold  text-slate-800">Music:</label>
                  <p className="mb-2 text-slate-600">{activeChallenge.with_music ? 'with music' : 'without music'}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // if challenge Context ready is not true show loading spinner

  if (challengesContext.ready !== true) {
    return (
      <Box className={styles.container}>
        <Box className={styles.header}>
          <p className={styles.headerTitle}>Challenges</p>
        </Box>
        <Box className={styles.contentContainer}>
          <div className=" flex justify-center mx-auto">
            <svg className="w-12 h-12 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-gray-900"
              ></path>
            </svg>
          </div>
        </Box>
      </Box>
    )
  }

  let showCategoryBar = true
  if (showSeries) {
    showCategoryBar = false
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <p className={styles.headerTitle}>Challenges</p>
      </Box>

      <Box className={styles.contentContainer}>
        {activeChallenge && renderActiveChallenge(activeChallenge)}
        {!userChallengeContextState.isFetching && !userChallengeContextState.completedChallengesLoading && (
          <>
            {/* <h2 className="text-center text-2xl font-bold mt-2 font-SfProDisplayBold">Challenges</h2> */}
            {showCategoryBar && <ButtonBar onClickItem={onClickCategory} selectedItem={selectedCategory}></ButtonBar>}

            {/* description of selected category */}
            <ChallengeList
              challenges={displayedChallenges}
              handleClickChallenge={onClickChallenge}
              handleClickSeries={handleSeriesClick}
              completedChallenges={userChallengeContextState.completedChallenges ?? null}
              showSeries={showSeries}
              setShowSeries={setShowSeries}
              selectedSeries={selectedSeries}
              setSelectedSeries={setSelectedSeries}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export { ViewChallenges }
