import React from 'react'

type AdminChallengeStatBlockProps = {
  challengeData: any
  genderMakeup: { males: number; females: number; didNotDisclose: number }
}

const AdminChallengeStatBlock = ({ challengeData, genderMakeup }: AdminChallengeStatBlockProps) => {
  const calculateAvgCommitment = (): number => {
    return challengeData.reduce((acc: any, curr: { weekly_commitment: any }) => acc + curr.weekly_commitment, 0) / challengeData.length
  }

  const total = genderMakeup.males + genderMakeup.females + genderMakeup.didNotDisclose

  return (
    <div className="bg-gray-50 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Challenge Stats</h2>
        </div>
      </div>
      <div className="mt-10 bg-white pb-12 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Squadies</dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">{challengeData.length}</dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Avg Commitment</dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">{calculateAvgCommitment().toFixed(2)}</dd>
                </div>
                <div className="grid-cols-3 flex justify-between  border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <div className="flex flex-col p-6 ">
                    <dt className="order-2 mt-2 text-md font-medium leading-6 text-gray-500">Men</dt>
                    <dd className="order-1 text-2xl font-bold tracking-tight text-indigo-600">
                      <div className="">{genderMakeup.males}</div>
                      <div className="">({((genderMakeup.males / total) * 100).toFixed(1)}%)</div>
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-md font-medium leading-6 text-gray-500">Women</dt>
                    <dd className="order-1 text-2xl font-bold tracking-tight text-indigo-600">
                      <div className="">{genderMakeup.females}</div>
                      <div className="">({((genderMakeup.females / total) * 100).toFixed(1)}%)</div>
                    </dd>
                  </div>
                  <div className="flex flex-col p-6">
                    <dt className="order-2 mt-2 text-md font-medium leading-6 text-gray-500">didNotDisclose</dt>
                    <dd className="order-1 text-2xl font-bold tracking-tight text-indigo-600">
                      {' '}
                      <div className="">{genderMakeup.didNotDisclose}</div>
                      <div className="">({((genderMakeup.didNotDisclose / total) * 100).toFixed(1)}%)</div>
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminChallengeStatBlock
