import React, { useContext, useRef } from 'react'
import { AuthStateContext, UserChallengeContext } from 'context'
import { NotyfContext } from 'context/NotyfContext'
import { LeanStreak } from 'features/LeanStreak/LeanStreak'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { UserAPI } from 'services/api'
import { UpdateUserOptions } from 'services/api/types'
import { FirebaseAuth, FirebaseStorage } from 'services/firebase'
import { Box, ProfilePicture } from 'shared/components'
import { SettingsOption } from './components/SettingsOption'
import { SettingsOptions, UserOption, UserOptions, ResourceOptions } from './options'
import styles from './Profile.module.scss'

export const Profile: React.FC = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { state: authState, dispatch: authDispatch } = useContext(AuthStateContext)
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState
  const fileInputRef = useRef<HTMLInputElement>(null)
  const notyf = useContext(NotyfContext)
  const { mutateAsync: updateUser } = useMutation((options: UpdateUserOptions) => UserAPI.updateUser(options), {
    onSuccess: () => {
      notyf.success('Profile updated!')
      queryClient.refetchQueries('user')
    },
    onError: (err: Error) => {
      notyf.error(err.message)
    },
  })

  const onClickLogout = async () => {
    await FirebaseAuth.signOut(authDispatch)
    queryClient.clear()
  }

  const onClickResource = (title: string) => {
    switch (title.toLowerCase()) {
      case 'how your challenge works':
        history.push(`/resources/23be81KJorHNiJykPOCvda/overview`)
        break
      case 'all about lean fuel':
        history.push(`/resources/7jvicoxoDCQYQQcTcgkRR3/overview`)
        break
      case 'intermittent fasting':
        history.push(`/resources/7mI7asPG86lJiVWhwVzD6X/overview`)
        break
      case 'f.a.q':
        history.push(`/resources/JDx4AI9fkFeOgHMOdoepb/overview`)
        break
    }
  }

  const onClickSetting = (title: string) => {
    switch (title.toLowerCase()) {
      case 'logout':
        onClickLogout()
        break
      case 'privacy policy':
        console.log('onClick', title)
        window.open('https://lean-squad.com/privacy-policy/', '_blank')
        break
      case 'support':
        window.open('https://lean-squad.com/community#faq', '_blank')
        console.log('onClick', title)
        break

      default:
        break
    }
  }

  const onClickUserPreference = (id: string) => {
    switch (id) {
      case 'challenge':
        if (activeChallenge) {
          history.push(`/challenges/${activeChallenge.challenge_id}/overview`)
        } else {
          history.push('/challenges')
        }
        break
      case 'meal-preference':
        history.push(`/profile/preferences/meal-type`)
        break
      case 'measurements':
        history.push(`/profile/measurements`)
        break
      case 'transformation':
        history.push(`/profile/transformation`)
        break
      case 'subscription':
        history.push(`/profile/subscription/update`)
        break
      case 'workout-history':
        history.push(`/profile/workout-history`)
        break
      default:
        break
    }
  }

  const getTitleFromOption = (option: UserOption) => {
    switch (option.id) {
      case 'challenge':
        if (activeChallenge) {
          return 'Manage My Challenge'
        } else {
          return 'Find a Challenge'
        }
      case 'meal-preference':
        if (authState.user?.preferences) {
          const mealTypePref = authState.user?.preferences.find((i) => i.preference_type === 'meal-type')
          if (mealTypePref) {
            return mealTypePref.record.answer
          }
        }
        break
      default:
        break
    }

    return option.title
  }

  const onClickProfilePicture = () => {
    console.log('update ProfilePicture :>> ', fileInputRef.current)
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('res :>> ', e.target.files)
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      try {
        const downloadUrl = await FirebaseStorage.uploadPicture(authState.user?.id || '', 'profile-picture', 'profilePicture', file)
        console.log('downloadUrl :>> ', downloadUrl)
        await updateUser({ profile_picture: downloadUrl })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('onFileSelected:error :>> ', error)
      }
    }
  }

  const { user } = authState
  return (
    <Box className={styles.container}>
      <Box className={styles.contentContainer}>
        <Box className={styles.profileHeader}>
          <input type="file" ref={fileInputRef} onChange={onFileSelected} hidden />
          <ProfilePicture onClick={onClickProfilePicture} firstName={user?.firstName || ''} lastName={user?.lastName || ''} imageUrl={user?.profilePicture} />
          <p className={styles.profileName}>{`${user?.firstName} ${user?.lastName}`}</p>
          <p className={`${styles.profileName} text-slate-500 text-sm`}>{`${user?.email} `}</p>
        </Box>
        <p className={styles.contentTitle}>LEAN STREAK</p>
        <LeanStreak size="lg" />

        <p className={styles.contentTitle}></p>
        <Box className={styles.settingsOptionsContainer}>
          {UserOptions.map((option) => {
            const title = getTitleFromOption(option)
            return <SettingsOption key={option.id} title={title} icon={option.icon} onClick={() => onClickUserPreference(option.id)} />
          })}
        </Box>

        <p className={styles.contentTitle}>RESOURCES</p>
        <Box className={styles.settingsOptionsContainer}>
          {ResourceOptions.map((option) => {
            return <SettingsOption key={option.title} title={option.title} icon={option.icon} onClick={() => onClickResource(option.title)} />
          })}
        </Box>

        <p className={styles.contentTitle}>SETTINGS</p>
        <Box className={styles.settingsOptionsContainer}>
          {SettingsOptions.map((option) => {
            return <SettingsOption key={option.title} title={option.title} icon={option.icon} onClick={() => onClickSetting(option.title)} />
          })}
        </Box>
      </Box>
    </Box>
  )
}
