import React from 'react'
import Spinner from 'react-spinkit'
import styles from './Button.module.scss'
interface Props {
  label?: string
  containerStyle?: string
  labelStyle?: string
  loading?: boolean
  loadingColor?: string
  onClick: React.MouseEventHandler
  disabled?: boolean
  classNames?: string
}

export const Button: React.FC<Props> = ({ label, containerStyle = '', labelStyle = '', loading = false, loadingColor = '#FFF', onClick, disabled = false, classNames = '' }) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`${styles.container}   ${containerStyle}  ${classNames ? classNames : ''} ${disabled ? styles.inactive : ''}`}
    >
      {loading ? <Spinner fadeIn="none" className="loadingIndicator" name="three-bounce" color={loadingColor} /> : <p className={`${styles.label} ${labelStyle}`}>{label}</p>}
    </button>
  )
}
