import { Product, PromoCode } from 'types'
import { getProducts } from '../../services/api/products'

const getProductFromPrice = (selectedPrice: string, products: Product[]) => {
  const productItem = products.find((item) => item.default_price === selectedPrice)
  if (productItem) {
    console.log('productItem', productItem)
    return productItem
  }
  return null
}

export { getProductFromPrice }
