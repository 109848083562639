import { get, post, del, patch } from './helpers'
import { DeleteResponse } from './types'
import { AchievementCriteria } from 'types'

export const create = async (achievementCriteriaData: AchievementCriteria) => {
  try {
    const createdAchievementCriteria = await post<AchievementCriteria>('/achievementCriteria', achievementCriteriaData)
    return createdAchievementCriteria
  } catch (error) {
    console.error('Error creating achievement:', error)
    throw error
  }
}

// Get all AchievementCriteria
export const getAll = async () => {
  try {
    console.log('%cFetching achievement criteria', 'color: lightgreen')
    const achievementCriteria = await get<AchievementCriteria[]>('/achievementCriteria')
    console.log(`%c${achievementCriteria.length} Achievement Criteria Loaded`, 'color: green')

    return achievementCriteria
  } catch (error) {
    console.error('Error getting achievement criteria:', error)
    throw error
  }
}

// Patch an Achievement
export const update = async (achievementCriteriaData: AchievementCriteria) => {
  try {
    const patchedAchievementCriteria = await patch<AchievementCriteria>(`/achievementCriteria/${achievementCriteriaData.id}`, achievementCriteriaData)
    return patchedAchievementCriteria
  } catch (error) {
    console.error('Error patching achievement:', error)
    throw error
  }
}

export const remove = async (id: string): Promise<DeleteResponse> => {
  const response = await del<DeleteResponse>(`/achievementCriteria/${id}`)
  return response
}

export default {
  create,
  getAll,
  update,
}
