import { useContext } from 'react'
import { UserAchievementCriteriaActionsContext, UserAchievementCriteriaStateContext } from '../context/UserAchievementCriteriaContext'

import type { IuserAchievementCriteriaState, IuserAchievementCriteriaActions } from '../context/UserAchievementCriteriaContext'
export function useUserAchievementCriteriaState(): IuserAchievementCriteriaState {
  const context = useContext(UserAchievementCriteriaStateContext)
  if (!context) {
    throw new Error('useUserAchievementCriteriaState must be used within a UserAchievementCriteriaProvider')
  }
  return { userAchievementCriteria: context.userAchievementCriteria, isLoading: context.isLoading }
}

export function useUserAchievementCriteriaActions(): IuserAchievementCriteriaActions {
  const context = useContext(UserAchievementCriteriaActionsContext)
  if (!context) {
    throw new Error('useUserAchievementCriteriaActions must be used within a UserAchievementCriteriaProvider')
  }
  return {
    findByAchievementCriteriaId: context.findByAchievementCriteriaId,
    updateProgress: context.updateProgress,
    incrementProgress: context.incrementProgress,
    noIncrementProgress: context.noIncrementProgress,
    refresh: context.refresh,
  }
}
