import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'
import UserAchievementCriteriaApi from '../services/api/userAchievementCriteria'
import { UserAchievementCriteria } from 'types'
import { AuthStateContext } from './AuthStateContext'

export interface IuserAchievementCriteriaState {
  userAchievementCriteria: Map<string, UserAchievementCriteria>
  isLoading: boolean
}

export const UserAchievementCriteriaStateContext = createContext<IuserAchievementCriteriaState | undefined>(undefined)
export interface IuserAchievementCriteriaActions {
  findByAchievementCriteriaId: (id: string) => UserAchievementCriteria | undefined
  updateProgress: (id: string, value: number) => Promise<number>
  incrementProgress: (id: string, timestampIso: string, isYesterday: boolean, userTimeZone?: string) => Promise<boolean>
  noIncrementProgress: (id: string, timestampIso: string, isYesterday: boolean, userTimeZone?: string) => Promise<boolean>
  refresh: () => Promise<boolean>
}

export const UserAchievementCriteriaActionsContext = createContext<IuserAchievementCriteriaActions | undefined>(undefined)

export const UserAchievementCriteriaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userAchievementCriteria, setUserAchievementCriteria] = useState<Map<string, UserAchievementCriteria>>(new Map())
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchUserAchievementCriteria = async () => {
      if (!user) return
      setIsLoading(true)
      try {
        const data = await UserAchievementCriteriaApi.getAll()
        const map = new Map()
        data.forEach((userAchievement) => {
          map.set(userAchievement.id, userAchievement)
        })
        setUserAchievementCriteria(map)
      } catch (error) {
        console.error('Error fetching user achievements: ', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchUserAchievementCriteria()
  }, [user])

  const findByAchievementCriteriaId = useCallback(
    (id: string) => {
      for (const criteria of userAchievementCriteria.values()) {
        if (criteria.achievementCriteriaId === id) {
          return criteria
        }
      }
      console.warn(`No UserAchievementCriteria found for achievementCriteriaId: ${id}`)
      return undefined
    },
    [userAchievementCriteria],
  )
  /**
   * Updates the progress value for a given UserAchievementCriteria.
   *
   * @param {string} id - The ID of the UserAchievementCriteria to update.
   * @param {number} value - The new progress value to set for the UserAchievementCriteria.
   *
   * @returns {number} The new progress value for the updated UserAchievementCriteria if successful, or -1 if an error occurred or if no UserAchievementCriteria was found with the provided ID.
   *
   * @example
   * // Suppose there is a UserAchievementCriteria with ID 'abc123' and value 10.
   * // The following call will update its value to 20.
   * updateProgress('abc123', 20);
   */
  const updateProgress = useCallback(
    async (id: string, value: number): Promise<number> => {
      const userAchievementCriteriaToUpdate = findByAchievementCriteriaId(id)
      if (!userAchievementCriteriaToUpdate) return -1
      userAchievementCriteriaToUpdate.value = value
      try {
        const updatedUserAchievementCriteria = await UserAchievementCriteriaApi.update(userAchievementCriteriaToUpdate)
        if (!updatedUserAchievementCriteria.id) throw new Error('Missing id in updated user achievement criteria')
        const updatedMap = new Map(userAchievementCriteria)
        updatedMap.set(updatedUserAchievementCriteria.id, updatedUserAchievementCriteria)
        setUserAchievementCriteria(updatedMap)
        return updatedUserAchievementCriteria.value
      } catch (error) {
        console.error('Error updating userAchievementCriteria: ', error)
        return -1
      }
    },
    [findByAchievementCriteriaId, userAchievementCriteria],
  )

  /**
   * Increment the progress of a UserAchievementCriteria.
   *
   * This function increments the value of a UserAchievementCriteria by calling the
   * backend API to perform the increment. It requires the ID of the UserAchievementCriteria,
   * the timestamp of the user's report, a flag indicating if the report is for yesterday,
   * and an optional user timezone.
   *
   * @param {string} id - The ID of the UserAchievementCriteria to increment.
   * @param {string} timestampIso - The timestamp of the user's report in ISO 8601 format (e.g., "2023-05-26T12:34:56.789Z").
   * @param {boolean} isYesterday - A flag indicating if the user is reporting yesterday's update.
   * @param {string} [userTimeZone] - Optional. The user's timezone in the IANA timezone format (e.g., "America/New_York").
   *                                  If not provided, the function will attempt to get the user's timezone from the browser.
   *
   * @returns {Promise<boolean>} A Promise that resolves to true if the increment was successful, and false otherwise.
   *
   * @example
   * // Increment the progress for UserAchievementCriteria with ID 'abc123' for today's report.
   * // The timestamp is '2023-05-26T12:34:56.789Z', and the user is in the 'America/New_York' timezone.
   * incrementProgress('abc123', '2023-05-26T12:34:56.789Z', false, 'America/New_York');
   *
   * @example
   * // Increment the progress for UserAchievementCriteria with ID 'def456' for yesterday's report.
   * // The timestamp is '2023-05-25T12:34:56.789Z', and the user is in the 'Europe/London' timezone.
   * incrementProgress('def456', '2023-05-25T12:34:56.789Z', true, 'Europe/London');
   */

  const incrementProgress = useCallback(
    async (id: string, timestampIso: string, isYesterday: boolean, userTimeZone?: string): Promise<boolean> => {
      try {
        if (!userTimeZone) {
          userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const incrementedUserAchievementCriteria = await UserAchievementCriteriaApi.increment(id, timestampIso, isYesterday, userTimeZone)
        if (!incrementedUserAchievementCriteria || !incrementedUserAchievementCriteria.id) return false
        const updatedMap = new Map(userAchievementCriteria)
        updatedMap.set(incrementedUserAchievementCriteria.id, incrementedUserAchievementCriteria)
        setUserAchievementCriteria(updatedMap)
        return true
      } catch (error) {
        console.error('Error incrementing userAchievementCriteria: ', error)
        return false
      }
    },
    [userAchievementCriteria],
  )

  const noIncrementProgress = useCallback(
    async (id: string, timestampIso: string, isYesterday: boolean, userTimeZone?: string): Promise<boolean> => {
      try {
        if (!userTimeZone) {
          userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const updatedUserAchievementCriteria = await UserAchievementCriteriaApi.noIncrement(id, timestampIso, isYesterday, userTimeZone)
        if (!updatedUserAchievementCriteria || !updatedUserAchievementCriteria.id) return false
        const updatedMap = new Map(userAchievementCriteria)
        updatedMap.set(updatedUserAchievementCriteria.id, updatedUserAchievementCriteria)
        setUserAchievementCriteria(updatedMap)
        return true
      } catch (error) {
        console.error('Error incrementing userAchievementCriteria: ', error)
        return false
      }
    },
    [userAchievementCriteria],
  )

  const refresh = useCallback(async (): Promise<boolean> => {
    setIsLoading(true)
    try {
      const data = await UserAchievementCriteriaApi.getAll()
      const map = new Map()
      data.forEach((userAchievement) => {
        map.set(userAchievement.id, userAchievement)
      })
      setUserAchievementCriteria(map)
      return true
    } catch (error) {
      console.error('Error fetching user achievements: ', error)
      return false
    } finally {
      setIsLoading(false)
    }
  }, [])

  const state = useMemo(
    () => ({
      userAchievementCriteria,
      isLoading,
    }),
    [userAchievementCriteria, isLoading],
  )

  // const state = { userAchievementCriteria, isLoading }

  const actions = useMemo(
    () => ({
      findByAchievementCriteriaId,
      updateProgress,
      incrementProgress,
      noIncrementProgress,
      refresh,
    }),
    [findByAchievementCriteriaId, updateProgress, incrementProgress, noIncrementProgress, refresh],
  )

  console.log('UserAchievementCriteriaProvider Rendered:>> ', state)

  return (
    <UserAchievementCriteriaStateContext.Provider value={state}>
      <UserAchievementCriteriaActionsContext.Provider value={actions}>{children}</UserAchievementCriteriaActionsContext.Provider>
    </UserAchievementCriteriaStateContext.Provider>
  )
}
