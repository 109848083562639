import React, { createContext, useEffect, useMemo, useState } from 'react'
import { ChallengeState, Series } from '../types'
import { ChallengeAPI } from '../services/api'
import { getChallengeMap } from '../services/api/challenges'

import { useQuery } from 'react-query'
import { dayjs } from '../shared/functions'
import { Challenge } from '../types/index'

const containsMusic = (challenge: Challenge) => {
  return challenge.title.toLowerCase().includes('music')
}

const initialState: ChallengeState = {
  challenges: null,
  categories: [],
  array: [],
  isFetching: true,
  error: undefined,
  series: [],
  ready: false,
  challengesAndSeriesCombined: [],
}

export const ChallengeContext = createContext({
  state: initialState,
})

ChallengeContext.displayName = 'ChallengeContext'

export const ChallengeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // useState challengesByCategory
  const [ready, setReady] = useState(false)
  const [prunedSeriesChallenges, setPrunedSeriesChallenges] = useState(false)
  const {
    data: challenges,
    isLoading: challengesLoading,
    error,
  } = useQuery('challenges', () => getChallengeMap(), {
    staleTime: dayjs(dayjs().add(1, 'day').startOf('day')).diff(dayjs()),
    refetchOnWindowFocus: true,
    retry: false,
  })
  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery('categories', () => ChallengeAPI.getCategories(), {
    staleTime: dayjs(dayjs().add(7, 'day').startOf('day')).diff(dayjs()),
    refetchOnWindowFocus: true,
    retry: false,
  })

  const {
    data: series,
    isLoading: seriesLoading,
    error: seriesError,
  } = useQuery('series', () => ChallengeAPI.getSeries(), {
    staleTime: dayjs(dayjs().add(7, 'day').startOf('day')).diff(dayjs()),
    refetchOnWindowFocus: true,
    retry: false,
  })

  const errors = useMemo(() => {
    const _errors = []
    if (error) {
      _errors.push(error)
    }
    if (categoriesError) {
      _errors.push(categoriesError)
    }
    if (seriesError) {
      _errors.push(seriesError)
    }
    return _errors
  }, [error, categoriesError, seriesError])

  const challengesAndSeriesCombined: (Series | Challenge)[] = useMemo(() => {
    // build an array of challenges from the challenges map
    // remove each challenge that is also a member of a series called standaloneChallenges
    // create a set of combined standaloneChallenges and series sorted by orderNumber
    // if either challenges or series is not loaded, return an empty array
    if (challengesLoading && seriesLoading) {
      return []
    }
    const _series = series ?? []
    // clone the challenges map
    const standaloneChallenges = { ...challenges }
    // strip out challenges that are music challenges
    for (const item in standaloneChallenges) {
      if (containsMusic(standaloneChallenges[item])) {
        delete standaloneChallenges[item]
      }
    }

    for (const series of _series) {
      for (const challenge of series.challenges) {
        // remove the challenge from the standaloneChallenges map
        // console.log(`removing ${challenge.title} from standaloneChallenges`)
        // does standaloneChallenges have a key for this challenge?
        if (standaloneChallenges[challenge.title]) {
          // console.log(`removing ${challenge.title} from standaloneChallenges`)
          delete standaloneChallenges[challenge.title]
        }
      }
    }
    // every challenge and series has an orderNumber which dictates how close to the top they appear sort a combined list by this value descending
    const combined = [...Object.values(standaloneChallenges), ..._series].sort((a, b) => (b.orderNumber ?? 0) - (a.orderNumber ?? 0))
    // console.log('combined :>> ', JSON.stringify(combined, null, 2))
    setPrunedSeriesChallenges(true)
    return combined
  }, [challenges, series, challengesLoading, seriesLoading])

  useEffect(() => {
    // guard
    if (!challenges || !prunedSeriesChallenges) {
      return
    }
    setReady(true)
  }, [challenges, prunedSeriesChallenges])

  const state: ChallengeState = {
    challenges: challenges ?? null,
    categories: categories ?? [],
    challengesAndSeriesCombined: challengesAndSeriesCombined,
    series: series ?? [],
    array: Object.values(challenges ?? {}) as Challenge[],
    isFetching: challengesLoading || categoriesLoading || seriesLoading,
    ready: ready,
    error: errors as Error[] | undefined,
  }
  console.log('ChallengeProvider Rendered:>> ', state)
  const value = useMemo(() => ({ state }), [state])

  // const value = { state }
  return <ChallengeContext.Provider value={value}>{children}</ChallengeContext.Provider>
}

export const ChallengeContextConsumer = ChallengeContext.Consumer
