import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { Achievement, Challenge, ChallengeMap } from '../../../types'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import AdminButton from './AdminButton'
import { useAchievements } from '../../../hooks/useAchievements'

type Props = {
  handleSubmit: (s1: string, s2: string) => void
  challenges: Challenge[]
}

const AssociatedAchievementEntry = ({ handleSubmit, challenges }: Props) => {
  const { data: achievements, isLoading } = useAchievements()
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(null)
  const [queryChallenge, setQueryChallenge] = useState('')
  const [selectedAchievement, setSelectedAchievement] = useState<Achievement | null>(null)
  const [queryAchievement, setQueryAchievement] = useState('')

  const handleSelectedChallenge = (challenge: Challenge) => {
    setSelectedChallenge(challenge)
  }
  const handleSelectedAchievement = (achievement: Achievement) => {
    setSelectedAchievement(achievement)
  }

  const onSubmitClickHandler = () => {
    // guard against submitting null values
    if (selectedChallenge?.id && selectedAchievement?.id) {
      handleSubmit(selectedChallenge?.id, selectedAchievement?.id)
      setSelectedChallenge(null)
      setSelectedAchievement(null)
    } else {
      alert('Please select a challenge and achievement')
    }
  }

  if (isLoading || !challenges || !achievements) return <div>Loading...</div>

  const filteredChallenges = queryChallenge === '' ? challenges : challenges.filter((challenge) => challenge.title.toLowerCase().includes(queryChallenge.toLowerCase()))

  const filteredAchievements =
    queryAchievement === '' ? achievements : achievements.filter((achievement) => achievement.name.toLowerCase().includes(queryAchievement.toLowerCase()))

  return (
    <>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">
        <Combobox as="div" value={selectedChallenge} onChange={handleSelectedChallenge}>
          <div className="relative mt-2">
            <Combobox.Input
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => setQueryChallenge(event.target.value)}
              displayValue={(challenge: Challenge) => (challenge ? challenge.title : '')}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>

            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredChallenges.map((challenge) => (
                <Combobox.Option className={`text-gray-900`} key={challenge.id} value={challenge}>
                  {challenge.title}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </Combobox>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">
        {' '}
        <Combobox as="div" value={selectedAchievement} onChange={handleSelectedAchievement}>
          <div className="relative mt-2">
            <Combobox.Input
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => setQueryAchievement(event.target.value)}
              displayValue={(achievement: Achievement) => (achievement ? achievement.name : '')}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>

            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredAchievements.map((achievement) => (
                <Combobox.Option className={`text-gray-900`} key={achievement.id} value={achievement}>
                  {achievement.name}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </Combobox>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  ">
        <AdminButton label={'submit'} onClick={onSubmitClickHandler} />
      </td>
    </>
  )
}

export { AssociatedAchievementEntry }
