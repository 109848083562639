import React, { useEffect, useMemo } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import styles from '../SignUp.module.scss'
import countryList from 'react-select-country-list'
import Spinner from 'react-spinkit'

const CardNumberElementOptions = {
  style: {
    base: {
      fontSize: '15px',
      color: '#171922',
      letterSpacing: '0.025em',
      fontFamily: `'Inter', sans-serif`,
      '::placeholder': {
        color: '#718096',
      },
    },
    invalid: {
      color: '#e9615f',
    },
  },
}

/**
 *  This component is used for CardPaymentForm
 *  @param {function} onSubmitPaymentDetails - function that is called when user clicks on the pay button
 *  @param {boolean} creatingAccount - boolean that is used to check if the user is creating an account
 *  @returns {JSX.Element} - JSX Element
 */
type CardPaymentFormProps = {
  onSubmitPaymentDetails: (postalCode: string, country: string) => void
  creatingAccount: boolean
  buttonLabel?: string
}

export const CardPaymentForm = ({ onSubmitPaymentDetails, creatingAccount, buttonLabel = 'Purchase' }: CardPaymentFormProps) => {
  const [disablePaymentButton, setDisablePaymentButton] = React.useState<boolean>(false)
  useEffect(() => {
    if (creatingAccount !== true) return
    // The current implementation allows the button to become active again while the subscription is being created. So we will just disable it after click.
    setDisablePaymentButton(true)
    //after 10 seconds we will enable the button again
    setTimeout(() => {
      setDisablePaymentButton(false)
    }, 10000)
  }, [creatingAccount])

  const inputRowStyle = { marginBottom: 0, display: 'flex', justifyContent: 'space-between' }

  const options = useMemo(() => countryList().getData(), [])

  function onChange(value: any) {
    console.log(`selected ${value}`)
  }

  const isPaymentUpdate = buttonLabel === 'Add Card'

  return (
    <Form name="payment" onFinish={(values: { postalCode: string; country: string }) => onSubmitPaymentDetails(values.postalCode, values.country)}>
      <Form.Item name="cardNumber" rules={[{ required: true }]}>
        <CardNumberElement options={CardNumberElementOptions} />
      </Form.Item>
      <div style={inputRowStyle}>
        <Form.Item name="date" rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}>
          <CardExpiryElement />
        </Form.Item>
        <Form.Item name="cvv" rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}>
          <CardCvcElement />
        </Form.Item>
      </div>
      <div style={inputRowStyle}>
        <Form.Item name="postalCode" rules={[{ required: true, message: 'Please enter your postal code' }]} style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}>
          <Input placeholder="Postal/Zip Code" />
        </Form.Item>
        <Form.Item name="country" rules={[{ required: true }]} style={{ display: 'inline-block', width: 'calc(50% - 4px)', justifyContent: 'center' }}>
          <Select
            showSearch={true}
            placeholder="Country"
            optionFilterProp="children"
            onChange={onChange}
            style={{ display: 'inline-block', width: '100%', height: '100%' }}
            bordered={false}
          >
            {options.map(({ value, label }) => {
              return (
                <Select.Option key={label} value={value}>
                  {label}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </div>
      <Button
        className={`${styles.continue} ${styles.continue__form} flex items-center justify-center`}
        type="primary"
        htmlType="submit"
        disabled={disablePaymentButton}
        loading={disablePaymentButton}
      >
        {!creatingAccount ? <span>{buttonLabel}</span> : <Spinner fadeIn="quarter" className="loadingIndicator" name="three-bounce" color="#FFF" />}
      </Button>
      {isPaymentUpdate && (
        <p className="text-xxs mt-2 text-center text-slate-500">
          Upon updating with a valid card, any outstanding invoices will be cleared, and you will gain instant access to the platform.
        </p>
      )}
    </Form>
  )
}
export default { CardPaymentForm }
