import React from 'react'
import { useQuery, useQueryClient } from 'react-query' // Import useQueryClient from react-query
import Spinner from 'react-spinkit'
import * as UCServices from '../services/api/userChallenges'
import LeanButton from './LeanButton'
import { ForwardIcon } from '@heroicons/react/24/solid'

const AdvanceWeek = () => {
  // Fetch the current user challenge
  const {
    data: currentUserChallenge,
    isLoading: currentUserChallengeLoading,
    error: currentUserChallengeError,
  } = useQuery('currentUserChallenge', UCServices.getCurrentUserChallenge, {
    staleTime: Infinity,
  })

  // Extract userChallengeId once it's available
  const userChallengeId = currentUserChallenge?.id

  // Determine if the subsequent queries should be enabled
  const isEnabled = Boolean(userChallengeId)

  // Check if the user can advance week
  const {
    data: canAdvanceWeekData,
    isLoading: canAdvanceWeekLoading,
    error: canAdvanceWeekError,
  } = useQuery(['canAdvanceWeek', userChallengeId], () => UCServices.canAdvanceWeek(userChallengeId!), {
    staleTime: Infinity,
    enabled: isEnabled,
  })

  // Combine loading states
  const loading = currentUserChallengeLoading || (isEnabled && canAdvanceWeekLoading)

  const queryClient = useQueryClient() // Initialize queryClient

  // Handle loading state
  if (loading) {
    return <Spinner fadeIn="quarter" className="loadingIndicator" name="three-bounce" color="#FFF" />
  }
  if (!userChallengeId || !canAdvanceWeekData) {
    return null
  }

  const handleAdvanceWeek = async () => {
    const response = await UCServices.advanceWeek(userChallengeId)
    console.log('handleAdvanceWeek response :>> ', response)
    // if status 200
    if (response) {
      // invalidate the queries
      queryClient.invalidateQueries('currentUserChallenge')
      queryClient.invalidateQueries('completedChallenges')
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries(['canAdvanceWeek', userChallengeId])
      queryClient.invalidateQueries(['currentWeek', userChallengeId])
    }
  }

  return (
    <div className=" w-full flex flex-col items-end justify-end">
      <p className=" text-lg font-medium text-gray-700"> Advance to the next week?</p>
      <LeanButton onClick={handleAdvanceWeek}>
        <ForwardIcon className="h-5 w-5 mr-2" />
        Let&apos;s Go!
      </LeanButton>
    </div>
  )
}

AdvanceWeek.displayName = 'AdvanceWeek'

export { AdvanceWeek }
