import React, { useContext, useEffect, useState } from 'react'
import * as AchievementAPI from 'services/api/achievements'
import { NotyfContext } from 'context/NotyfContext'
import AdminButton from './AdminButton'
import { useAchievements } from 'hooks/useAchievements'
import AchievementForm from './AchievementForm'
import type { Achievement, AchievementFormState } from 'types'
import { AchievementCriteriaTable } from './AchievementCriteriaTable'
import CreateAchievementCriteria from './CreateAchievementCriteria'

type Props = {
  achievementId: string
  setShowEditAchievement: React.Dispatch<React.SetStateAction<boolean>>
}

function EditAchievement({ setShowEditAchievement, achievementId }: Props) {
  const { findById, refreshAchievements, data: achievements } = useAchievements()
  const [selectedAchievementData, setSelectedAchievementData] = React.useState<Achievement | null>(null)
  const [showCreateCriteria, setShowCreateCriteria] = useState(false)
  useEffect(() => {
    if (!achievementId) return
    const achievementData = findById(achievementId)
    if (achievementData) setSelectedAchievementData(achievementData)
  }, [achievements])

  console.log('selectedAchievementData', { selectedAchievementData })
  const notyf = useContext(NotyfContext)

  const submitCallback = async (formState: AchievementFormState) => {
    // Perform your update logic here, e.g., calling an API to update the achievement

    const mergedData = { ...selectedAchievementData, ...formState } as any
    delete mergedData.validationErrors
    delete mergedData.achievementCriteria
    mergedData.durationDays = Number(mergedData.durationDays)
    mergedData.oneStarMinPercent = Number(mergedData.oneStarMinPercent)
    mergedData.twoStarMinPercent = Number(mergedData.twoStarMinPercent)
    mergedData.threeStarMinPercent = Number(mergedData.threeStarMinPercent)

    const result = await AchievementAPI.update(mergedData)
    if (result) {
      notyf.success('Achievement updated successfully')
    } else {
      notyf.error('Error updating achievement')
    } // refresh achievements
    refreshAchievements()
  }

  const handleBackFromAddCriteria = () => {
    setShowCreateCriteria(false)
  }

  const handleAddCriteria = () => {
    setShowCreateCriteria(true)
  }

  if (showCreateCriteria && selectedAchievementData && selectedAchievementData.id)
    return <CreateAchievementCriteria achievementId={selectedAchievementData.id} setShowCreateAchievementCriteria={handleBackFromAddCriteria} />

  return (
    <div className="">
      <h2 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900 flex justify-center">Edit an Achievement</h2>
      <div className="flex justify-between">
        <AdminButton
          type="button"
          label="Back"
          onClick={() => {
            setShowEditAchievement(false)
          }}
        />
        <AdminButton type="button" label="Add Criteria" onClick={handleAddCriteria} />
      </div>
      {selectedAchievementData && <AchievementForm submitCallback={submitCallback} initialState={selectedAchievementData as AchievementFormState} />}
      <div className="">
        <div className="flex justify-end">
          <AdminButton type="button" label="Extra Add Criteria Button For Oscar" onClick={handleAddCriteria} />
        </div>
        <h3 className="mt-3 text-2xl font-extrabold tracking-tight text-slate-900 flex justify-center">Criteria for this Achievement</h3>
        {selectedAchievementData && <AchievementCriteriaTable AchievementCriteria={selectedAchievementData.achievementCriteria ?? []} />}
      </div>
    </div>
  )
}

export default EditAchievement
