import React, { useCallback } from 'react'
import styles from './Workout.module.scss'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import { Workout } from 'types'
import { Box } from '../Box/Box'

type Props = {
  title: string
  description: string
  duration?: string
  item?: Workout | null
  onClickPlay: (item: Workout) => void
}

export const FinisherItem: React.FC<Props> = ({ title, description, duration, item, onClickPlay }) => {
  const { previewImageUrl } = item || {}

  const onClickPlayCallback = useCallback(() => {
    if (item) {
      console.log('onClickPlay finisher :>> ', item)
      onClickPlay(item)
    }
  }, [onClickPlay, item])

  return (
    <Box className={styles.finisherItem}>
      {previewImageUrl ? <img src={previewImageUrl} className={styles.finisherPreviewImg} /> : <Box className={styles.emptyPreviewImage} />}
      <Box className={styles.workoutInfo}>
        <p className={styles.workoutTitle}>{title}</p>
        <p className={styles.workoutDescription}>{description}</p>
        <p className={styles.workoutDuration}>{duration}</p>
      </Box>
      <button onClick={onClickPlayCallback} className={`${styles.playBtn} flex bg-coral justify-center`}>
        <Play />
      </button>
    </Box>
  )
}
