import { UserSubscription } from 'types'

export const isValidSubscription = (subscription?: UserSubscription): boolean => {
  if (!subscription) {
    return false
  }

  const { status } = subscription
  if (!status || status === 'canceled' || status === 'unpaid' || status === 'incomplete_expired') {
    return false
  }

  return true
}
