import { UpdateActiveDate, Recipe, UpdateActiveRecipes, UpdateActiveMeal, ResetRecipePlan, SetRecipes } from 'types';

export const updateActiveDate = (activeDate: string): UpdateActiveDate => {
  return {
    type: 'UPDATE_ACTIVE_DATE',
    activeDate,
  };
};

export const updateActiveRecipes = (recipes: Record<string, Recipe>): UpdateActiveRecipes => {
  return {
    type: 'UPDATE_ACTIVE_RECIPES',
    activeRecipes: recipes,
  };
};

export const setRecipes = (recipes: Record<string, Recipe>): SetRecipes => {
  return {
    type: 'SET_RECIPES',
    activeRecipes: recipes,
  };
};

export const updateActiveMeal = (activeMeal?: string): UpdateActiveMeal => {
  return {
    type: 'UPDATE_ACTIVE_MEAL',
    activeMeal,
  };
};

export const resetRecipePlan = (): ResetRecipePlan => {
  return {
    type: 'RESET_RECIPE_PLAN',
  };
};
