import { ChallengeType } from '../../constants'
import { Workout, Challenge } from '../../types/index'

const isFlexChallenge = (challenge: Challenge) => {
  if (!challenge || !challenge.type || !challenge.type.title) return false
  return challenge.type?.title === ChallengeType.Flex
}

// Workout completed if it has a completed_date date
const isWorkoutCompleted = (workout: Workout) => {
  if (!workout || !workout.completed_date) return false

  return !!workout.completed_date
}

// Count all  completed workouts in a array of workouts
const countCompletedWorkouts = (workouts: Workout[]) => {
  if (!workouts || !workouts.length) return 0
  return workouts.filter(isWorkoutCompleted).length
}

const limitFloatToTwoDecimals = (num: number) => {
  return parseFloat(num.toFixed(2))
}

const toInputDateValue = (isoDateString: string) => {
  return isoDateString ? isoDateString.split('T')[0] : ''
}

const toInputDateTimeValue = (isoDateString: string) => {
  const dateForInput = isoDateString.substring(0, isoDateString.length - 8)
  // Adjust the date string to match the required format "YYYY-MM-DDTHH:MM"
  const adjustedDateForInput = `${dateForInput.substring(0, 16)}:00`
  return dateForInput ? dateForInput : ''
}

export { isFlexChallenge, isWorkoutCompleted, countCompletedWorkouts, limitFloatToTwoDecimals, toInputDateValue, toInputDateTimeValue }
