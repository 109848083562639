import { useState } from 'react';
import { LocalStorage } from 'shared/functions';
import { StorageObj } from 'types';

export type StorageValue = StorageObj | string | number | null;
export type LocalStorageResponse = {
  storedValue: StorageValue;
  setValue: (value: StorageValue) => void;
};

export const useLocalStorage = (key: string, initialValue: StorageValue): LocalStorageResponse => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    return LocalStorage.getItem(key, initialValue);
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: StorageValue) => {
    try {
      // Allow value to be a function so we have same API as useState
      // Save state
      setStoredValue(value);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(value));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return { storedValue, setValue };
};
