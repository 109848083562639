import React from 'react'
import { Button } from 'shared/components'
import styles from '../Subscription.module.scss'
import { SubscriptionOption } from './SubscriptionOption'
import * as StripeUtils from 'shared/functions/stripe'

interface currentSubscriptionProps {
  activeSubscription: any
  onClickUpdatePaymentMethod: () => void
  onClickUpdatePlan: () => void
  onClickCancelSubscription: () => void
  renewsOnDate: string
  cancelAtPeriodEnd: boolean
}

/**
 *
 * Current Subscription Component
 */
const CurrentSubscription = ({
  activeSubscription,
  renewsOnDate,
  onClickCancelSubscription,
  onClickUpdatePaymentMethod,
  onClickUpdatePlan,
  cancelAtPeriodEnd,
}: currentSubscriptionProps) => {
  if (!activeSubscription)
    return (
      <>
        <Button onClick={onClickUpdatePlan} label="Update Plan" containerStyle={styles.updatePlanBtn} />
      </>
    )

  console.log('cancelAtPeriodEnd', cancelAtPeriodEnd)

  return (
    <>
      <p className={styles.currentSubscription}>Current Subscription</p>
      <SubscriptionOption
        key={'currentSubscription'}
        option={activeSubscription}
        details={StripeUtils.getDetails(activeSubscription.type)}
        isActive={true}
        onClick={() => {
          //
        }}
      />
      {cancelAtPeriodEnd && <p className={`${styles.subscriptionStatus} ml-2 mt-2`}>Your subscription will cancel at the end of the current billing period.</p>}
      <p className={`${styles.subscriptionStatus} ml-2 mt-2`}>{`Your Current Billing Period ends on: ${renewsOnDate}.`}</p>
      <Button onClick={onClickUpdatePaymentMethod} label="Update Payment Method" containerStyle={styles.updatePaymentMethodBtn} />
      <Button onClick={onClickUpdatePlan} label={cancelAtPeriodEnd ? 'Reactivate Account' : 'Update Plan'} containerStyle={styles.updatePlanBtn} />
      <Button
        onClick={onClickCancelSubscription}
        label={cancelAtPeriodEnd ? 'Cancelled' : 'Cancel Subscription'}
        containerStyle={styles.cancelSubscriptionBtn + ` mt-4 disabled:bg-slate-500 disabled:border-slate-900 disabled:cursor-not-allowed`}
        disabled={cancelAtPeriodEnd}
      />
    </>
  )
}

export { CurrentSubscription }
