import React from 'react'
import { useForm } from 'react-hook-form'
import { Challenge } from '../../../types/index'
type Props = {
  createNew: boolean
  userChallengeData: any
  challenges: Challenge[]
  onSubmitUserChallenge: (data: any) => void
  onSubmitUserChallengeError: (data: any) => void
}

const ChallengeForm = ({ createNew, userChallengeData, challenges, onSubmitUserChallenge, onSubmitUserChallengeError }: Props) => {
  const {
    register,
    reset,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  })
  const [fieldsToRender, setFieldsToRender] = React.useState<string[]>([])

  React.useEffect(() => {
    if (isSubmitSuccessful) reset({ id: '', challenge_id: '', start_date: '', with_music: false, weekly_commitment: 0 })
  }, [isSubmitSuccessful])

  const legacyChallengeFieldsToRender = ['user_id', 'challenge_id', 'start_date']
  const flexChallengeFieldsToRender = ['user_id', 'challenge_id', 'start_date', 'with_music', 'weekly_commitment']

  const challenge = challenges.find((challenge) => challenge.id === userChallengeData.challenge_id)
  // const isFlex = challenge && challenge.type && challenge.type.title === 'Flex'

  React.useEffect(() => {
    if (challenge && challenge.type && challenge.type.title === 'Flex') {
      setFieldsToRender(flexChallengeFieldsToRender)
    } else {
      setFieldsToRender(legacyChallengeFieldsToRender)
    }
  }, [challenge])

  // const fieldsToRender = isFlex || createNew ? flexChallengeFieldsToRender : legacyChallengeFieldsToRender

  console.log(userChallengeData)
  return (
    <div className="mt-4">
      <h1 className=" text-xl font-large"> User Challenge</h1>
      <p className="  text-sm text-slate-300 italic "> endDates will be auto calculated by the server</p>
      <div className="flex flex-wrap space-y-8 items-center text-sm text-gray-500">
        <form key={2} className=" flex flex-wrap" onSubmit={handleSubmit(onSubmitUserChallenge)}>
          {userChallengeData &&
            Object.keys(userChallengeData).map((key, index) => {
              if (fieldsToRender.includes(key)) {
                // if the key is challenge_id render a pick list of challenges with the current ID as the selected value
                if (key === 'challenge_id' && challenges) {
                  return (
                    <div key={key} className=" ml-8 text-sm text-gray-500  flex-col">
                      <span className="text-sm font-medium ">{key}</span>
                      <select
                        {...register(key)}
                        className="block w-full rounded-md border p-1  m-2 border-gray-700 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-xs text-gray-900"
                        defaultValue={userChallengeData[key]}
                        // onChange check if selected challenge is a flex challenge if so change setFieldsToRender to flexChallengeFieldsToRender else set to legacyChallengeFieldsToRender
                        onChange={(e) => {
                          const challenge = challenges.find((challenge) => challenge.id === e.target.value)
                          if (challenge && challenge.type && challenge.type.title === 'Flex') {
                            setFieldsToRender(flexChallengeFieldsToRender)
                          } else {
                            setFieldsToRender(legacyChallengeFieldsToRender)
                          }
                        }}
                      >
                        {challenges.map((challenge) => {
                          return (
                            <option key={challenge.id} value={challenge.id}>
                              {challenge.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )
                }

                if (key === 'with_music') {
                  // return an input with a checkbox
                  return (
                    <div key={key} className=" ml-8 text-sm text-gray-500  flex-col">
                      <span className="text-sm font-medium ">{key}</span>{' '}
                      <input
                        {...register(key)}
                        type="checkbox"
                        className="block w-full   h-6 m-2 border-gray-700  focus:border-slate-500 focus:ring-slate-500 text-xs text-gray-900"
                        defaultChecked={userChallengeData[key]}
                      />
                    </div>
                  )
                }
                if (key === 'id') {
                  // return just the id
                  return (
                    <div key={key} className=" ml-8 text-sm text-gray-500  flex-col">
                      <span className="text-sm font-medium ">{key}</span> <p> {userChallengeData[key]}</p>
                    </div>
                  )
                }
                if (key === 'challenge_id' && challenges) {
                  const challenge = challenges.find((challenge) => challenge.id === userChallengeData[key])
                  return (
                    <div key={key} className=" ml-8 text-sm text-gray-500  flex-col">
                      <span className="text-sm font-medium ">{key}</span> <div className="mt-1 text-xs text-gray-900">{challenge?.title}</div>
                    </div>
                  )
                }
                return (
                  <div key={key} className=" ml-8 text-sm text-gray-500  flex-col">
                    <span className="text-xs font-medium ">{key}</span>{' '}
                    {
                      <input
                        {...register(key)}
                        className="block w-full rounded-md border p-1  m-2 border-gray-700 shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-xs text-gray-900"
                        defaultValue={userChallengeData[key]}
                        data-lpignore={'true'}
                      />
                    }
                  </div>
                )
              }
            })}
          <button
            type="submit"
            className="  inline-flex  ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 min-w-min"
          >
            <span>Update</span>
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChallengeForm
