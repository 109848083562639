import React, { useCallback, useContext } from 'react'
import { match, NavLink, useRouteMatch } from 'react-router-dom'
import styles from './SideNavigation.module.scss'
import { sideNavRoutesObj, RouteType } from 'router/routes'
import Logo from 'assets/images/leansquad-logo-black.png'
import { Box } from 'shared/components'
import { LeanStreak } from 'features'
import { UserChallengeContext } from 'context'
import { ReactComponent as DownloadIOS } from 'assets/images/download-app-store.svg'
import { AuthStateContext } from 'context'
import { SideNavLink } from './SideNavLink'

type SideNavigationProps = {
  toggleSidebar: (forceHide: boolean) => void
  isMobile: boolean
}
export const SideNavigation: React.FC<SideNavigationProps> = ({ isMobile, toggleSidebar }) => {
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState
  const { state: authState } = useContext(AuthStateContext)

  const onClickLink = useCallback(() => {
    if (isMobile) {
      // hideSidebar onClick since it takes up full width
      toggleSidebar(true)
    }
  }, [isMobile, toggleSidebar])

  const isAdmin = authState.user && authState.user.email && authState.user.email.includes('@lean-squad.com')

  return (
    <Box className={`${styles.container} `}>
      <NavLink to="/">
        <img src={Logo} className={styles.logo} />
      </NavLink>
      <Box className={`${styles.linksContainer} + mt-16 lg:mt-2`}>
        <SideNavLink route={sideNavRoutesObj.today} onClick={onClickLink} />
        <SideNavLink route={sideNavRoutesObj.onDemand} onClick={onClickLink} />
        <SideNavLink route={sideNavRoutesObj.viewAllChallenges} onClick={onClickLink} />
        <SideNavLink route={sideNavRoutesObj.planner} onClick={onClickLink} />
        <SideNavLink route={sideNavRoutesObj.recipes} onClick={onClickLink} />
        <SideNavLink route={sideNavRoutesObj.squads} onClick={onClickLink} />
        {/* <div className="relative border border-red-400 p-0.5 pb-2.5 rounded-md"> */}
        {/* <span className="absolute top-[-0.5rem] right-[-0.5rem] text-red-400 text-xs  bg-[rgb(247,250,252)]">New</span> */}
        <SideNavLink route={sideNavRoutesObj.achievement} onClick={onClickLink} />
        {/* </div> */}
        <SideNavLink route={sideNavRoutesObj.profile} onClick={onClickLink} />
        {isAdmin && <SideNavLink route={sideNavRoutesObj.admin} onClick={onClickLink} />}
      </Box>
      <Box className={styles.leanStreakContainer}>
        <DownloadIOS
          className={styles.downloadIOS}
          onClick={() => {
            const win = window.open('https://apps.apple.com/us/app/leansquad-fitness-app/id1555120074', '_blank')
            win?.focus()
          }}
        />
        <p className={`${styles.contentTitle} mt-3`}>LEAN STREAK</p>
        <LeanStreak size="sm" />
      </Box>
    </Box>
  )
}
