import React from 'react'
import { AchievementCriteria } from '../../../types/index'
import { AchievementCriteriaAPI } from '../../../services/api'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useAchievements } from '../../../hooks/useAchievements'

import EditAchievementCriteria from './EditAchievementCriteria'

type Props = {
  AchievementCriteria: AchievementCriteria[]
}

const AchievementCriteriaTable = ({ AchievementCriteria }: Props) => {
  const [showEditAchievementCriteria, setShowEditAchievementCriteria] = React.useState(false)
  const [selectedAchievementCriteria, setSelectedAchievementCriteria] = React.useState<AchievementCriteria | null>(null)
  const { refreshAchievements } = useAchievements()
  // if empty, show "No achievement criteria"
  if (AchievementCriteria.length === 0) {
    return (
      <div>
        <h3>No achievement criteria</h3>
      </div>
    )
  }

  const editHandler = (someAchievementCriteria: AchievementCriteria) => {
    setSelectedAchievementCriteria(someAchievementCriteria)

    setShowEditAchievementCriteria(true)
  }
  const deleteHandler = async (someAchievementCriteria: AchievementCriteria) => {
    console.log(someAchievementCriteria)
    // remove via api
    if (someAchievementCriteria.id) {
      await AchievementCriteriaAPI.remove(someAchievementCriteria.id)
      refreshAchievements()
      setShowEditAchievementCriteria(false)
    }
  }

  const tableCellClasses = 'px-3 py-4 text-sm text-gray-500 whitespace-nowrap'

  if (showEditAchievementCriteria && selectedAchievementCriteria) {
    return <EditAchievementCriteria setEditAchievementCriteria={setShowEditAchievementCriteria} achievementCriteria={selectedAchievementCriteria} />
  }

  return (
    <div className="mt-6 mx-auto max-w-4xl flow-root ">
      <h2 className=" mt-3 text-3xl font-extrabold tracking-tight text-slate-900 flex  justify-center "> Criteria </h2>
      <div className="-mx-4 -my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Target</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Display Text</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Prompt Text</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Congrats Text</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Derived</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Rule Id</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Edit</th>
                <th className={`${tableCellClasses} font-medium text-gray-900`}>Delete</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {AchievementCriteria.map((achievementCriteria) => (
                <tr key={achievementCriteria.id}>
                  <td className={tableCellClasses}>{achievementCriteria.target}</td>
                  <td className={tableCellClasses}>{achievementCriteria.displayText}</td>
                  <td className={tableCellClasses}>{achievementCriteria.promptText}</td>
                  <td className={tableCellClasses}>{achievementCriteria.congratsText}</td>
                  <td className={tableCellClasses}>{achievementCriteria.derived.toString()}</td>
                  <td className={tableCellClasses}>{achievementCriteria.ruleId}</td>
                  <td className={tableCellClasses}>
                    <button className="text-indigo-600 hover:text-indigo-800  opacity-50 hover:opacity-100 " onClick={() => editHandler(achievementCriteria)}>
                      Edit
                    </button>
                  </td>
                  {/* delete icon from heroicons button */}
                  <td className={tableCellClasses}>
                    <button
                      className="font-bold py-2 px-4 rounded inline-flex items-center hover:text-red-600"
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this item?')) {
                          deleteHandler(achievementCriteria)
                        }
                      }}
                    >
                      <TrashIcon className="w-6 h-6  text-gray-500  opacity-50 hover:opacity-100 " />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { AchievementCriteriaTable }
