import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { DocumentUtils } from 'shared/functions';

export const ScrollToTop: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      //   console.log('in listener', location, action);

      // Only scroll to top on overview pages
      if (location && location.pathname.includes('overview')) {
        DocumentUtils.scrollToTopContent();
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <div />;
};
